@charset "utf-8";

/*======= publicUI.css =======*/

/* @import url('fonts.css'); */
@import url('fonts/fonts.css');

/*** SITE TEMPLATE ***/



	#plate {
		border: 1px solid #919191;
		border: 1px solid rgba(145,145,145,0.7);
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
		box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.35);
	}


.holdout {
	/*
	background-attachment: fixed;
	background-repeat: repeat-x;
	background-color: rgb(188, 204, 204);
	background-position: center top;
	*/
	/* background-color: rgb(195, 204, 204); */
	/* background-image: url("bgGradient.png"); */
	background-color: var(--hf-background-color);
	position: fixed;
	left: 0px;
	right: 0px;
	z-index: 4;
}

	.holdout.top {
		height: 32px;
		top: 0px;
	}

	.holdout.bottom {
		height: 30px;
		bottom: 0px;
		margin: auto;
		border-top: 1px solid rgba(0, 0, 0, 0.35);
		box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.35) inset;

		background-image: none;
	}



/* remove footer */
@media screen and (max-width: 480px),
(max-height: 567px) {

	.holdout.bottom { display: none; }
	.plate{ bottom: 0; }
	#pageContent { margin-bottom: 0 !important; }

}

#pageContent {
	padding: 170px 30px 0;
	margin-right: 0;
	transition: var(--hf-standard-transition);
}

	@media screen and (max-width: 632px) {
		#pageContent {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.search #pageContent { 
		padding-left: 0;
		padding-right: 0;
	}

	/* .headerContainer.fixed + #pageContent { padding-top: 90px; } */

	.search #pageContent.hasActiveFilters { padding-top: 285px; }

	/* .search .headerContainer.fixed + #pageContent.hasActiveFilters { padding-top: 185px; } */

	.headerContainer {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 14;

		padding-bottom: 20px;
		margin: 0 auto;
		pointer-events: none;
		transition: var(--hf-standard-transition), background-color 0s;
	}

		.headerContainer > * { z-index: 12; }

		#headerTop {
			position: relative;
			/* padding: 10px 0; */
			/* margin-bottom: 10px; */
			padding: 10px 0 0;
			display: grid;
			grid-template-columns: 1fr 258px 1fr;
			z-index: 13;
			pointer-events: none;
			transition: var(--hf-standard-transition);
		}

			#headerTop > * { pointer-events: all; }

			#headerTop #contactAResearcher {
				/* position: absolute; */
				/* top: 50%; */
				/* transform: translateY(-50%); */
				grid-column: 1;
				grid-row: 1;
				align-self: center;
				justify-self: start;
				margin-left: 30px;
				margin-right: 60px;
			}

			#headerTop #contactAResearcher,
			button.contactAResearcher,
			#footer a.button.signup {
				padding: 5px 15px 5px 40px;
			}

				#headerTop #contactAResearcher::before,
				button.contactAResearcher::before,
				#footer a.button.signup::before {
					position: absolute;
					font-family: var(--hf-icon-font);
					font-size: 20px;
					left: 10px;
					font-weight: 900;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					content: "\3f";
					min-width: 1em;
				}
				#footer a.button.signup::before { content:'\f25a'; }

			#hfLogo {
				text-align: center;
				grid-column: 2;
        		position: relative;
        		text-align: center;
				min-height: 86.85px;
				min-height: 90.5px;
			}

				.headerContainer.fixed #hfLogo { min-height: 67.62px; }

			      @keyframes rotation {
			        from {
			          transform: rotate(0deg);
			        }
			        to {
			          transform: rotate(360deg);
			        }
			      }

			      @keyframes flickeryRotation {
			        0% {
			          transform: rotate(0deg);
			          opacity: 1;
			        }
			        5% {
			          opacity: 0.65;
			        }
			        10% {
			          opacity: 1;
			        }
			        15% {
			          opacity: 0.65;
			        }
			        20% {
			          opacity: 1;
			        }
			        25% {
			          opacity: 0.65;
			        }
			        30% {
			          opacity: 1;
			        }
			        35% {
			          opacity: 0.65;
			        }
			        40% {
			          opacity: 1;
			        }
			        45% {
			          opacity: 0.65;
			        }
			        50% {
			          opacity: 1;
			        }
			        55% {
			          opacity: 0.65;
			        }
			        60% {
			          opacity: 1;
			        }
			        65% {
			          opacity: 0.65;
			        }
			        70% {
			          opacity: 1;
			        }
			        75% {
			          opacity: 0.65;
			        }
			        80% {
			          opacity: 1;
			        }
			        85% {
			          opacity: 0.65;
			        }
			        90% {
			          opacity: 1;
			        }
			        95% {
			          opacity: 0.65;
			        }
			        100% {
			          transform: rotate(360deg);
			          opacity: 1;
			        }
			      }

				  @keyframes flicker {
			        0% {
			          opacity: 1;
			        }
			        5% {
			          opacity: 0.65;
			        }
			        10% {
			          opacity: 1;
			        }
			        15% {
			          opacity: 0.65;
			        }
			        20% {
			          opacity: 1;
			        }
			        25% {
			          opacity: 0.65;
			        }
			        30% {
			          opacity: 1;
			        }
			        35% {
			          opacity: 0.65;
			        }
			        40% {
			          opacity: 1;
			        }
			        45% {
			          opacity: 0.65;
			        }
			        50% {
			          opacity: 1;
			        }
			        55% {
			          opacity: 0.65;
			        }
			        60% {
			          opacity: 1;
			        }
			        65% {
			          opacity: 0.65;
			        }
			        70% {
			          opacity: 1;
			        }
			        75% {
			          opacity: 0.65;
			        }
			        80% {
			          opacity: 1;
			        }
			        85% {
			          opacity: 0.65;
			        }
			        90% {
			          opacity: 1;
			        }
			        95% {
			          opacity: 0.65;
			        }
			        100% {
			          opacity: 1;
			        }
			      }		

				#hfLogo a { 
					display: block;
					transition: none;
				}
					#hfLogo a:hover .reel { animation: flickeryRotation 2.5s infinite linear; }

				#hfLogo img { 
				}
 
				#hfLogo .reelWrapper {
					-webkit-mask-image: linear-gradient(to bottom, #fff 15%, #fff0 65%);
					mask-image: linear-gradient(to bottom, #fff 15%, #fff0 65%);
				}

				#hfLogo .reel {
					animation: rotation 16s infinite linear;
					transform-origin: center;
					transform-box: fill-box;
					transition: animation 1s;
					width: 27.27%; 
				}

				#hfLogo .logoSansReel {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					pointer-events: none;
					width: 258px; 
				}  

				.home #pageHeader #headerTop #hfLogo a { pointer-events: none; }
				.home #pageHeader #headerTop #hfLogo .logoSansReel { width: 300px; }
				.home #pageHeader #headerTop #hfLogo .reel { 
					animation: flickeryRotation 2.5s infinite linear;
				}

		#headerTop .searchBar {
			margin: 10px var(--hf-standard-margin) 0;
			grid-row: 2;
			grid-column: 1 / 4;
			transition: right 0.2s ease-in-out, width 0.2s ease-in-out, left 0.2s ease-in-out;
			transition: right 0.2s ease-in-out, width 0.2s ease-in-out, left 0.2s ease-in-out, margin-right 0.2s ease-in-out;
			right: 0;
		}

			body.search #layoutContainer > .headerContainer:not(.fixed) #headerTop .searchBar { 
				left: var(--hf-filter-sidebar-width);
				width: calc(100% - var(--hf-filter-sidebar-width) - var(--hf-standard-margin) - var(--hf-standard-margin));
			}

			body.search.filtersClosed #layoutContainer > .headerContainer:not(.fixed) #headerTop .searchBar { 
				left: var(--hf-filter-tab-width);
				width: calc(100% - var(--hf-filter-tab-width) - var(--hf-standard-margin) - var(--hf-standard-margin));
			}

			.videoPlayerInner .headerContainer:not(.fixed) #headerTop > .searchBar,
			.videoPlayerInner .headerContainer:not(.fixed) #headerTop .homeSearchBar {
				/* margin-right: 372px; */
				width: calc(100% - var(--hf-next-prev-width));
			}

				.videoPlayerInner .headerContainer:not(.fixed) #headerTop .homeSearchBar .searchBar { width: 100%; }

			#headerTop .searchBar.music {
				margin-bottom: 40px;
			}

		#headerTop .myLinks {
			grid-row: 1;
			grid-column: 3;
			align-self: center;
			justify-self: end;
			display: flex;
			margin-left: 50px;
		}			


body:not(.home) #layoutContainer > .headerContainer.fixed { 
	padding-bottom: 10px; 
	box-shadow: var(--hf-overlay-edge-shadow);
	background-color: var(--hf-background-color);
}

	body:not(.home) .headerContainer.fixed #hfLogo,
	#videoWindow .headerContainer.fixed #hfLogo {
		grid-column: 1;
		grid-row: 1;
	}

	body:not(.home) .headerContainer.fixed #headerTop,
	#videoWindow .headerContainer.fixed #headerTop {
		grid-template-columns: 220px auto minmax(min-content, auto) auto;
	}

	body:not(.home) .headerContainer.fixed #headerTop #contactAResearcher,
	#videoWindow .headerContainer.fixed #headerTop #contactAResearcher {
		grid-column: 3;
	}

	.headerContainer.fixed #headerTop .myLinks,
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		margin-left: 0;
	}

	body:not(.home) .headerContainer.fixed #headerTop .myLinks,
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		grid-column: 4;
		white-space: nowrap;
	}

	body:not(.home) .headerContainer.fixed #headerTop .searchBar,
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		margin: 0 30px;
		grid-row: 1;
		grid-column: 2;
		align-self: center;
		transition: none;
		transition: margin-right 0.2s ease-in-out;
	}
	.home #videoWindow .headerContainer.fixed #headerTop .homeSearchBar {
		grid-row: 1;
		grid-column: 2;
		align-self: center;
	}

	.headerContainer.fixed #headerTop .searchBar .smallPrint,
	#videoWindow .headerContainer.fixed #headerTop .searchBar .smallPrint { grid-column: 1 / 4; }

	.headerContainer.fixed #hfLogo .logoSansReel,
	.home #pageHeader.fixed #headerTop #hfLogo .logoSansReel,
	#videoWindow .headerContainer.fixed #hfLogo .logoSansReel {
		width: 190px;
		margin-left: 15px;
	}

	body:not(.home) .headerContainer.fixed #hfLogo a,
	#videoWindow .headerContainer.fixed #hfLogo a {
		padding-left: 30px;
		min-height: 63.95px;
	}
	body.home #pageHeader.fixed #hfLogo .reelWrapper {
		padding-left: 30px;
	}

	.headerContainer.fixed .myLinks .menuGroup a.showMenu,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu,
	.headerContainer.fixed .myLinks .menuGroup a.signInLink,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.signInLink {
		padding: 12px 15px;
	}

	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins {
		padding-right: 30px;
	}

	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after { bottom: -11px; }


		#headerTop a.button,
		#mainArea a.button,
		#videoWindow a.button,
		#contactDialog a.button,
		#footer a.button,
		#headerTop a.button,
		button {
			align-items: center;
			background-color: var(--hf-background-color);
			border-radius: var(--hf-border-radius);
			border: var(--hf-button-border);
			box-shadow: var(--hf-button-shadow);
			box-sizing: border-box;
			color: var(--hf-button-text-color);
			cursor: pointer;
			display: inline-flex;
			font-size: var(--hf-button-font-size);
			font-weight: 500; /*?*/
			font-family: var(--hf-body-font);
			line-height: 1.125;
			min-height: var(--hf-button-min-height);
			padding: 5px 15px;
			position: relative;		
			text-transform: uppercase;
			transition: var(--hf-standard-transition);

			margin: 0 15px 0 0;
			vertical-align: top;
			text-decoration: none;
		}

				#headerTop a.button:not(.disabled):hover,
				#mainArea a.button:not(.disabled):hover,
				#videoWindow a.button:not(.disabled):hover,
				#contactDialog a.button:not(.disabled):hover,
				#footer a.button:not(.disabled):hover,
				#headerTop a.button:not(.disabled):hover,
				button:not(.disabled):not(.exampleButton):hover {
					color: var(--hf-button-text-hover-color); 
					box-shadow: var(--hf-button-hover-shadow);

					text-decoration: none;
				}

				#headerTop a.button:not(.disabled):active,
				#mainArea a.button:not(.disabled):active,
				#videoWindow a.button:not(.disabled):active,
				#contactDialog a.button:not(.disabled):active,
				#footer a.button:not(.disabled):active,
				#headerTop a.button:not(.disabled):active,
				button:not(.disabled):not(.exampleButton):active {
					box-shadow: var(--hf-button-active-shadow) !important;
					left: 1px;
					top: 1px;
				}

				#headerTop a.button:not(.disabled):focus-visible,
				#mainArea a.button:not(.disabled):focus-visible,
				#videoWindow a.button:not(.disabled):focus-visible,
				#contactDialog a.button:not(.disabled):focus-visible,
				#footer a.button:not(.disabled):focus-visible,
				#headerTop a.button:not(.disabled):focus-visible,
				button:not(.disabled):not(.exampleButton):focus-visible {
					box-shadow: var(--hf-button-focus-shadow);
					outline: none;
				}

		#homeLink {
			/*
			position: absolute;
			top: 33px;
			left: 157px;
			text-align: left;
			*/
			padding: 10px;
			text-decoration: none;
			color: #535352;
		}
			#homeLink:hover {
				color: black;
			}

		#headerTop h1 { position: relative; }

			body.home #headerTop h1 {
				font-size: 20px;
				margin: 0 55px;
			}

		#headerTop .menu:not(.binMenu) {
			min-width: 120px;
			position: absolute;
			z-index: 100;
			box-shadow: var(--hf-panel-shadow);
			overflow: hidden;
			background-color: #dde3e3;
			text-align: left;
			top: 0;
			max-width: 480px;
			right: 0;
			transition: var(--hf-standard-transition);
			border-radius: var(--hf-border-radius);
			opacity: 0;
			pointer-events: none;
			white-space: nowrap;
		}

			#headerTop .menu.overlay:not(.binMenu) { 
				opacity: 1;
				pointer-events: all;
			}


			@media screen and (max-width: 532px) {
				#headerTop .accountMenu.menu.overlay {
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0 !important;
					margin: 0 !important;
					width: auto !important;
					max-width: unset;
					border-radius: 0;
				}
					#headerTop .accountMenu.menu.overlay .menuContents {
						position: relative;
						max-height: none !important;
						height: calc(100% - 62px);
						top: 0;
					}
			}

			#headerTop .menu .header {
				display: block;

				position: relative;
				cursor: pointer;
				z-index: 2;

				box-sizing: border-box;
				width: 100%;

				text-align: right;

				color: var(--hf-text-color);
				padding: 20px 60px 20px var(--hf-standard-margin);
				font-size: 16px;
				font-weight: 500;
				background-color: var(--hf-accent-color);
				color: white;
				transition: var(--hf-standard-transition);
			}

				#headerTop .menu .header:hover { color: black; }

				#headerTop .menu .header .close {
					position: absolute;
					top: 50%;
					display: inline-block;
					right: 0.375em;
					transform: translateY(-50%);
					color: var(--hf-button-text-hover-color);
				}

					#headerTop .menu .header .close:focus-visible { 
						box-shadow: var(--hf-button-focus-shadow); 
						outline: none;
					}
					#headerTop .menu .header .close:hover { 
						color: var(--hf-button-text-hover-color);
						box-shadow: none;
					}
						#headerTop .menu .header .close:focus-visible::after,
						#headerTop .menu .header .close:hover::after {
							color: var(--hf-button-text-hover-color);
						}

					#headerTop .menu .header .close:active { 
						left: unset;
						right: calc(0.375em - 1px);
						top: calc(50% + 1px);
						box-shadow: none !important;
					}

				#headerTop .menu .header > span {
					display: table-cell;
					white-space: nowrap;
					text-align: right;
				}

				#headerTop .menu .header .name,
				#headerTop .menu .header .userName {
					width: 100%;
					/* padding-right: 0.25em; */
				}
				#headerTop .menu .header .count { padding-left: 0.25em; }
				#headerTop .menu .header .count:empty { padding-left: 0; }

			#headerTop .menu.accountMenu .menuItem {
				padding: calc(var(--hf-standard-margin) / 2) var(--hf-standard-margin);
				border-bottom: 1px solid var(--hf-panel-divider-color);
			}

				#headerTop .menu.accountMenu .menuItem .controlElement.showOrders, 
				#headerTop .menu.accountMenu .menuItem .accountLink {
					padding: calc(var(--hf-standard-margin) / 2) var(--hf-standard-margin);
					margin: calc(var(--hf-standard-margin) / -2) calc(var(--hf-standard-margin) * -1);
					display: block;
				}

					#headerTop .menu.accountMenu .menuItem .controlElement.showOrders:hover,
					#headerTop .menu.accountMenu .menuItem .accountLink:hover {
						color: var(--hf-strong-color) !important;
						background: ;
						background-color: var(--hf-hover-color);
					}

				#headerTop .menu.accountMenu .menuItem h3 { margin: 0 0 1em; }

				#headerTop .menu .menuContents {
					max-height: 100% !important;
					overflow-y: auto !important;
					background-color: var(--hf-inner-panel-bg-color);
					color: var(--hf-strong-color);
					font-size: 14px;
					position: relative;
					white-space: normal;
				}
				#headerTop .menu .menuContents.binList { padding-top: 5px; }

				#headerTop .menu .menuContents p {
					line-height: 1.5em;
				}

				#headerTop .menu .menuItem h3,
				#headerTop .menu .binHeader,
				#headerTop .menu .noClips {
					display: block;	
					font-size: 16px;
					margin: 0;
					font-weight: 500;
					line-height: 1.25em;
				}
					#headerTop .menu .binHeader.dropHover {
						background-color: var(--hf-darker-background-color);
					}

					#headerTop .menu .menuItem h3:last-child {
						margin-bottom: 0;
					}

					#headerTop .menu .menuItem h3.pro::after {
						content: '\e235';
						float: right;
						font-family: var(--hf-icon-font);
						font-weight: 900;
						color: var(--hf-highlight-color-darker);
						background-color: var(--hf-notice-background);
						border-radius: var(--hf-border-radius);
						padding: 0.25em;
						margin-top: -0.25em;
					}


				#headerTop .menu .menuContents a:not(.button) { color: var(--hf-strong-color); }
				#headerTop .menu .menuContents a:not(.button):hover { color: var(--hf-text-color); }

				#headerTop .menu .menuContents .notice {
					display: block;
					margin: 0.5em;
				}

				#headerTop .menu .button {
					background-color: transparent;
				}

				.button.buyMinutes { 
					padding: 5px 15px 5px 40px; 
					display: flex;
					margin: 1.5em auto 0.5em;
				}

				#headerTop .menu #logOut #btnLogout {
					padding: 5px 15px 5px 40px; 
					margin: 0.25em auto;
				}

					#headerTop .menu #logOut #btnLogout::before,
					.button.buyMinutes::before,
					#headerTop .menu .binSubMenu.binBtns .button::before {
						position: absolute;
						font-family: "Font Awesome 6 Pro";
						font-size: 20px;
						left: 12px;
						font-weight: 900;
						top: 0;
						bottom: 0;
						display: flex;
						align-items: center;
					}

					.button.buyMinutes::before { content: '\f251'; }
					#headerTop .menu #logOut #btnLogout::before { content: '\f007'; }
					#headerTop .menu .binSubMenu.binBtns .button.openBin::before { content: '\f07c'; }
					#headerTop .menu .binSubMenu.binBtns .button.orderBin::before { content: '\f06a'; }


				#headerTop .menu .binContainer {
					background-color: var(--hf-inner-panel-bg-color);
				}

				#headerTop .menu .binHeader {
					cursor: pointer;
					font-size: 20px;
					padding: 1em 1em 1em calc(var(--hf-standard-margin) * 1.5);
					border-bottom: 1px solid var(--hf-input-divider-color);
					position: relative;
					background-color: var(--hf-panel-bg-color);
					color: var(--hf-secondary-text-color);
					transition: var(--hf-standard-transition);
					display: grid;
					grid-template-columns: auto min-content;
				}

					#headerTop .menu .binHeader::before {
						content: '\f107';
						position: absolute;
						left: 0.7em;
						font-family: var(--hf-icon-font);
						font-weight: 900;
						line-height: 1.25em;
						top: 1em;
					}				

					#headerTop .menu .binHeader .count {
						font-size: 16px;
						font-style: normal;
						white-space: nowrap;
						grid-row: 1 / 3;
						grid-column: 2;
						text-align: center;
						line-height: normal;
					}

					#headerTop .menu .binHeader .note {
						font-style: italic;
						font-weight: 400;
						font-size: 16px;
					}


					#headerTop .menu .binHeader .error {
						color: #c00 !important;
						font-size: 16px;
					}

					#headerTop .menu .menuItem a img {
						vertical-align: middle;
						height: 24px;
						margin-right: 0.25em;
					}

					#headerTop .menu .menuItem #logOut { 
						display: block; 
						text-align: center;
					}

					#headerTop .menu .menuItem a.close {
						padding: 0;
						display: inline-block;
					}


				#ordersDialog .notices { 
					margin-bottom: 1em;
				}

				#ordersDialog .notices .notice { margin-left: 0.5em; }
				#ordersDialog .notices .notice.unpaid { font-weight: bold; }

				#headerTop .menu .menuItem .showOrders .notice .num,
				#ordersDialog .notices .notice .num {
					border-radius: 4px;
					color: black;
					box-shadow: 0.1875em 0.1875em 0 #00000040;
					margin-right: 0.25em;
					background-color: var(--hf-highlight-color);
					padding: 2px 3px;
					font-weight: 500;
					font-size: 12px;
				}

					#ordersDialog .notices .notice .num {
						font-size: 1rem;
						display: inline-block;
					}				

			#headerTop .menu.binMenu { 
				right: calc(var(--hf-bin-sidebar-width) * -1);
				transition: var(--hf-standard-transition); 
			}

			#headerTop .menu .binList > .menuItem:nth-child(1) .binHeader { cursor: default; }

			#headerTop .menu .binList .binHeader .ui-accordion-header-icon { display: none; }

			#headerTop .menu .binList .binHeader a {
				/*
				text-align: right;
				padding-right: 38px;
				*/
			}

			#headerTop .menu .binContent {
				background-color: var(--hf-inner-panel-bg-color);
				overflow: hidden;
				transition: var(--hf-standard-transition);
			}

				#headerTop .menu .binContent.dropHover {
					background-color: var(--hf-darker-background-color);
				}			

				#headerTop .menu .noClips {
					font-style: italic;
					text-align: center;
					color: var(--hf-secondary-text-color);
					font-weight: 400;
					margin: 1em;
				}

				#headerTop .menu .moreClips {
					font-style: italic;
					text-align: center;
					color: var(--hf-faint-text-color);
					font-weight: 400;
					padding: 1em 0.5em;
				}

				#headerTop .menu .moreClips a:not(.button) { color: var(--hf-text-color); }
				#headerTop .menu .moreClips a:not(.button):hover { color: var(--hf-strong-color); }


				#headerTop .menu li.listBox {
					list-style: none;
					padding: 0;
					margin: 0;
				}

					#headerTop .menu li.menuItem {
						list-style: none;
						padding: 0;
						margin: 0;
					}

					#headerTop .menu li.clip {
						position: relative;
						/* padding: 12px 46px 4px 66px; */
						/*
						padding: 12px 46px 4px 90px;
						height: 42px;
						*/
						cursor: zoom-in;
						border-bottom: 1px solid rgba(0,0,0,0.5);
						display: grid;
						grid-template-columns: min-content 92px auto min-content;						
					}

						#headerTop .menu li.clip:not(.delPrompt):hover {
							background-color: var(--hf-panel-bg-color);
							color: var(--hf-strong-color);
						}

						#headerTop .menu li.clip.binSortHelper { cursor: ns-resize !important; }

						#headerTop .menu li.clip .handle {
							position: relative;
							height: 100%;
							cursor: ns-resize;
							z-index: 2;
							width: 44px;
							display: flex;
							justify-content: center;
							align-items: center;
							color: var(--hf-faint-text-color);
							grid-row: 1 / 3;							
						}

						#headerTop .menu li.clipDragPlaceholder { 
							outline: 2px dashed var(--hf-text-color); 
							height: 100px;
							background-color: var(--hf-panel-bg-color);
						}						

						#headerTop .menu li.clip .thumbnail {
							position: relative;
							margin: 12px 12px 12px 0;
							grid-row: 1 / 3;
						}

							#headerTop .menu li.clip .thumbnail .positionBar {
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								border: none;
								height: 4px;
							}

							#headerTop .menu li.clip .thumbnail .smallThumb {
								width: 80px;
								height: auto;
							}

						#headerTop .menu li.clip .title,
						#headerTop .menu li.clip .timecodes {
							display: block;
							white-space: nowrap;
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						#headerTop .menu li.clip .title {
							font-weight: bold;
							font-size: 14px;
							grid-column: 3;
							grid-row: 1;
							align-self: end;
						}

						#headerTop .menu li.clip .timecodes {
							grid-column: 3;
							grid-row: 2;
						}

						#headerTop .menu li.clip .confirmDelete {
							position: absolute;
							left: 0;
							top: 0;
							height: 100%;
							width: 100%;
							font-weight: 500;
							text-align: right;
							text-transform: uppercase;
							cursor: default;
							background: linear-gradient(to right, #3a3a3a53 0%,#3a3a3a 100%);
							display: flex;
							align-items: center;
							justify-content: end;
							z-index: 2;
							color: black;
						}

							#headerTop .menu li.clip .confirmDelete span {
								background: var(--hf-highlight-color);
								padding: 0.5em;
								border-radius: var(--hf-border-radius);
								margin-right: 1em;
							}

							#headerTop .menu li.clip .confirmDelete .button { 
								margin: 0 calc(var(--hf-standard-margin) / 2); 
								background-color: var(--hf-background-color);
							}
							#headerTop .menu li.clip .confirmDelete .button + .button { margin-left: 0; }

				#headerTop .menu .binSubMenu { padding: 1em; }

					#headerTop .menu .binSubMenu.binBtns {
						display: flex;
						justify-content: space-evenly;
						gap: calc(var(--hf-standard-margin) / 2);
						border-bottom: 1px solid rgba(0,0,0,0.5);
					}
						#headerTop .menu .binSubMenu.binBtns .button {
							margin: 0;
							padding: 5px 15px 5px 40px;
						}



		/*
		.myLinks {
			position: absolute;
			top: 28px;
			right: 0;
		}

			.myLinks.left {
				left: 157px;
				right: auto;
			}

			.myLinks.right {
				right: 0;
				text-align: right;


			}
			*/

/*			#headerTop .myLinks.left .menu {
				/* margin-right: -0.875em; */
/*				margin-right: -1.75em;
			}

			@media screen and (min-width: 800px) and (max-width: 829px),
			(max-width: 669px) {
				.myLinks.left .showAbout { border-left-width: 0; }
			}

			@media screen and (max-width: 799px),
			(max-height: 567px) {
				.myLinks.left { left: 80px; }
			}

			/*
			@media screen and (min-width: 640px) and (max-width: 799px) {
				#headerTop .myLinks.left .menu { margin-right: -1.875em; }
				#headerTop .myLinks.left .menu .header { padding-right: 2.75em; }
				#headerTop .myLinks.left .menu .header:before,
				#headerTop .myLinks.left .menu .header:after { right: 2.75em; }
			}
			*/

			.myLinks, .myLinks a { color: var(--hf-button-text-color); }

			.myLinks a {
				text-decoration: none;
			}


			.myLinks a:hover {
				color: white;
			}

			#headerTop .myLinks > div.ui-widget-overlay {
				z-index: 99;
			}

			.myLinks .signInLink {
				text-transform: uppercase;
			}

			.myLinks .menuGroup {
				display: inline-block;
				position: relative;
			}

				.myLinks .menuGroup.binMenuGroup {
					margin-left: 30px;
				}

					.myLinks .menuGroup.binMenuGroup .waitingBox { min-height: unset; }
					.myLinks .menuGroup.binMenuGroup .waitingBox > img {
						width: 30px;
						height: 30px;
						top: 16px;
					}
					html.fixedHeader .myLinks .menuGroup.binMenuGroup .waitingBox > img {
						top: 9px;
					}

				.myLinks .menuGroup a.showMenu,
				.myLinks .menuGroup a.signInLink {
					white-space: nowrap;
					padding: 20px 15px;
					display: inline-block;
					color: var(--hf-text-color);
					border-radius: var(--hf-border-radius);
					transition: var(--hf-standard-transition), padding 0s; /* transition removed from padding for toggling to/from condensed mode */
					font-weight: 500;
				}

					.myLinks .menuGroup a.showMenu:hover,
					.myLinks .menuGroup a.signInLink:hover,
					#headerTop .menu .header:hover {
						background-color: var(--hf-accent-color);
						color: white;
					}

					.myLinks .menuGroup a.showMenu:active,
					.myLinks .menuGroup a.showMenu:focus-visible,
					.myLinks .menuGroup a.signInLink:active,
					.myLinks .menuGroup a.signInLink:focus-visible {
						background-color: var(--hf-highlight-color);
						color: black;
					}

					.myLinks .menuGroup a.showMenu.showBins { 
						padding-right: 30px;
						border-radius: var(--hf-border-radius) 0 0 var(--hf-border-radius);
						background-color: var(--hf-accent-color);
						color: white;
						box-sizing: border-box;
					    /* min-width: 100px; */
					    max-width: 550px; 
						z-index: 2;
						position: relative;
						height: 100%;
						display: flex;
						align-items: center;
						transition: none;
					}

						.myLinks .menuGroup a.showMenu.showBins > a { color: var(--hf-strong-color); }

					.myLinks a.showMenu::before,
					.myLinks a.showMenu.showBins .name::before,
					.myLinks a.signInLink::before,
					 #headerTop .menu .header .userName::before {
						font-family: var(--hf-icon-font);
						font-weight: 900;
						font-size: 18px;
						margin-right: 0.5em;
						display: inline-block;
					}

						.myLinks a.showMenu.showAccount::before,
						.myLinks a.signInLink::before,
						 #headerTop .menu .header .userName::before { content: "\f007\00a0"; }
						.myLinks a.showMenu.showBins::before { 
							content: "\f0d9"; 
							opacity: 0.5;
						}
						.myLinks a.showMenu.showBins .name::before { content: "\00a0\f07a\00a0"; }


						.myLinks a.showMenu.showBins:hover::before { animation: pullOutHover 0.35s infinite alternate; }

						html.binSidebarOpen .myLinks a.showMenu.showBins::before { content: '\f0da'; }	

						body.home #layoutContainer .myLinks a.showMenu.showBins::before { display: none; }					


					.myLinks a.showAccount::after {
						font-family: var(--hf-icon-font);
						font-size: 20px;
						font-weight: 900;						
						content: "\f0d7";
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						opacity: 0.5;
					}

				.myLinks a.showBins .note,
				.binMenu > .header .note {
					display:none !important;
				}

				.myLinks a.showBins .name,
				.binMenu > .header .name,
				.myLinks a.showAccount .userName,
				.accountMenu > .header .userName {
					display: inline-block;
					max-width: 150px;
					overflow: hidden;
					text-overflow: ellipsis;
					vertical-align: bottom;
					white-space: nowrap;
					transition: var(--hf-standard-transition);
				}

					.myLinks a.showMenu.showBins .name { transition: none; }

					.accountMenu > .header .userName { max-width: 100%; } /* open menu */


				.myLinks a.showBins .count:not(:empty)::before { content: '('; }
				.myLinks a.showBins .count:not(:empty)::after { content: ')'; }

				.myLinks .accountLinks .showMenu {
					display: inline;
					position: relative;
				}

					.myLinks .accountLinks .showMenu .notice {
						position: absolute;
						/* right: -12px; */
						right: 10px;
						margin-right: 5px;
						background-color: var(--hf-highlight-color);
						border-radius: 4px;
						padding: 1px 3px;
						color: black;
						box-shadow: 0.1875em 0.1875em 0 #00000040;
						font-weight: bold;
						top: -8px;
						font-size: 12px;
						transition: var(--hf-standard-transition);
						opacity: 1;
					}

						html.accountMenuOpen .myLinks .accountLinks .showMenu .notice { opacity: 0; }



				/*
				.myLinks .accountMenu {
					margin-right: -1em;
				}
				*/

			@media screen and (min-width: 640px) and (min-height: 568px) {

				.myLinks .accountLinks {
					white-space: nowrap;
				}

					.myLinks .accountLinks .menu { white-space: normal;	}

						.myLinks .accountLinks .showMenu:after { border-top-color: #b9cbcd !important;}
						.myLinks .accountLinks .showMenu:hover:after { border-top-color: white !important;}

				#headerTop .myLinks .accountMenu {
					/* top: -48px; */
					/* margin-right: -1.25em !important; */
				}

			}

				.myLinks .accountMenu .balance {
					white-space: nowrap;
				}

					.myLinks .accountMenu .proMinutesCount,
					.myLinks .accountMenu .proMinutesDate {
						font-weight: bold;
						color: var(--hf-strong-color);
					}

				/*
				.myLinks .accountMenu .buyPro {
					background-color: #ddd;
					font-weight: normal;
					line-height: 1em;
					text-shadow: none;
					white-space: nowrap;
				}

					.myLinks .accountMenu .buyPro:hover {
						background-color: #eee;
					}

					.myLinks .accountMenu .buyPro:active {
					}
				*/


				#signInForm {
					z-index: 100;
					display: none;
					position: fixed;
					width: 470px;
					top: 50%;
					left: 50%;
					box-shadow: var(--hf-modal-shadow);
					border: var(--hf-modal-border);
					transform: translate(-50%, -50%);
					background-color: var(--hf-background-color);
					border-radius: var(--hf-border-radius);
					padding: 1em 1.25em 0.625em;
					text-align: center;
				}

					#signInForm .tab {
						position: absolute;
						height: 25px;
						padding: 2px 4px;
						top: -28px;
						/*right: 93px; */
						left: 156px;
						background-color: #dde3e3;
						text-decoration: underline;
						cursor: default;
						z-index: 1;
					}

					#signInForm h2 {
						text-align: center;
						margin-bottom: 2.5em;
					}

					#signInForm .formLine,
					#signInForm .formLineAlt {
						margin: 2em 0 1em;
						text-align: left;
					}

						#signInForm .formLine label {
							display: block;
							margin: 1em 0;
						}

						#signInForm .formLine input[type="email"],
						#signInForm .formLine input[type="password"],
						.userForm input[type="email"],
						.userForm input[type="password"] {
							width: calc(100% - 43px);
							box-sizing: border-box;
						}

					#signInForm .formLineAlt {
						white-space: normal;
						padding: 0 3px;
						text-align: right;
					}

						#signInForm .remember {
							padding-left: 2em;
							margin-bottom: 1.5em;
							text-align: left;
						}

							#signInForm .remember input {
								margin-left: -2em;
							}

							#signInForm .remember label {
								cursor: pointer;
								transition: var(--hf-standard-transition);
							}

							#signInForm .remember label:hover,
							#signInForm .remember input:hover + label {
								color: white;
							}

						#signInForm .formLineAlt > :last-child {
						}


					#signInForm a:not(.button) {
						text-decoration: underline;
					}

					#signInForm .close {
						position: absolute;
						top: 0.25em;
						right: 0.25em;
					}

						#signInForm .close:active {
							top: calc(0.25em + 1px);
							right: calc(0.25em - 1px);
							left: unset;
						}

					#signInForm button, #signInForm .button {
						display: inline-flex !important;
						margin-top: 25px !important;
						margin-bottom: 25px !important;
						justify-content: center;
						vertical-align: middle;
					}

						#signInForm .button.registerLink {
							margin-left: 20px;
							margin-right: 0;
						}


					@media screen and (max-width: 600px), (max-height: 600px) {

						#signInForm{
							left: 0;
							right: 0;
							width: auto;
							transform: none;
							top: 0;
							bottom: 0;
							overflow: auto;
							border-radius: 0;
						}

					}



/*
		@media screen and (max-width: 639px),
		(max-height: 567px) {

			.plate { top: 50px; }
			.holdout.top { height: 50px; }

			#plate, #pageContent { padding-top: 108px; }

			.search #pageContent { padding-top: 204px; }

			#homeLink { display: none; }

			/*
			#headerTop { padding-top: 60px; }
			*/
/*
				#headerTop .menu {
					margin-top: 6px; /* to match .showMenu's top border */
/*					margin-right: 0;
					white-space: normal;
				}

			#hfLogo {
				height: 46px;
				height: 60px;
				text-align: left;
			}

				#hfLogo img {
					height: 46px;
					height: 60px;
					padding: 2px;
				}

			.myLinks {
				white-space: nowrap;
				top: 4px;
				left: 260px; 
				right: 2px;
			}

				.myLinks a { color: #b9cbcd; }

				.myLinks > .menuGroup {
					max-width: 50%;
					min-width: 25%;
				}

				.myLinks .menuGroup > a.showMenu {
					display: inline-block !important;
					position: relative;
					margin-right: 0 !important;
					border-top-width: 6px !important;
					border-right-width: 10px !important;
					border-left-width: 10px !important;
							padding-right: 0;
							max-width: 100%;
				}

				.myLinks a:hover { color: white; }

				.myLinks a.showMenu:after {
					border-top-color: #b9cbcd;
					position: absolute;
					top: 100%;
					left: 50%;
					margin-left: -0.15em;
					margin-top: 0;
				}
				.myLinks a.showMenu:hover:after { border-top-color: white; }

					.myLinks a.showMenu.showAccount span.userName {
						display: inline-block;
						max-width: 110px;
						white-space: nowrape;
						overflow: hidden;
						vertical-align: top;
						text-overflow: ellipsis;
					}

				/*
				.myLinks #signIn,
				.myLinks .showAccount { border-right-width: 1.625em !important; }
				*/

/*					.myLinks #signInForm {
						/* left: 3px; */
/*						top: 26px;
					}

				/*.myLinks > a.showMenu.showBins { border-right-width: 4.75em; }*/
				/*
				.myLinks > a.showMenu.showBins .count { margin-right: -4.75em; }
				.myLinks > a.showMenu.showBins:after { margin-left: 5.125em; }
				*/
				/*
				.myLinks > a.showMenu.showBins .count {
					position: absolute;
					right: -4em;
				}
				.myLinks > a.showMenu.showBins:after {
					position: absolute;
					right: -4.75em;
					top: 0.4375em;
				}
				*/

/*				.myLinks .menuGroup { text-align: center; }

				.myLinks a.showBins .name,
				.myLinks a.showAccount .userName,
				.myLinks a.showAbout .text {
					max-width: 100% !important;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.myLinks a.showBins .name {
					max-width: 75% !important;
					min-width: 3.25em;
				}

				/* .myLinks a.showBins .count { margin-right: -2.5em; } */
/*
		}

		@media screen and (min-width: 640px) and (max-height: 567px) {
			.search #pageContent { padding-top: 164px; }
		}

		@media screen and (max-width: 549px) {

			.myLinks .menuGroup { max-width: 35%; }
			.myLinks .menuGroup.accountLinks { max-width: none; }
			.myLinks.signedIn .menuGroup { max-width: 50%; }

		}

		@media screen and (max-width: 767px) {
			.myLinks .units { display: none; }
		}

		@media screen and (max-width: 767px) and (max-height: 567px),
		(max-width: 639px) {
			#headerTop .menu { margin-right: 0; }
			/*
			.myLinks > a.showMenu.showAccount { border-left-width: 0 !important; }
			.myLinks > a.showMenu.showBins { border-right-width: 2.75em; }
			*/
			/*
			.myLinks > a.showMenu.showBins .count { margin-right: -2.75em; }
			.myLinks > a.showMenu.showBins:after { margin-left: 3.125em; }
			*/
			/*
			.myLinks > a.showMenu.showBins .count {
				position: absolute;
				right: -2em;
			}
			*/
			/*
			.myLinks > a.showMenu.showBins:after {
				position: absolute;
				right: -2.75em;
				top: 0.4375em;
			}
			*/
/*		}

		@media screen and (max-width: 639px) {
			/* #signIn .or { display: none; } */
			/* #signIn .signInLink { padding-right: 10px; } */
/*		}

		/* home page title area */
/*		@media screen and (max-width: 959px) {
			body.home #headerTop h1 br { display: none; }
		}

		@media screen and (max-width: 649px) {
			body.home #headerTop h1 { margin: 0 25px; }
		}

		@media screen and (max-width: 567px) and (min-height: 568px) {
			body.home #headerTop h1 {
				font-size: 16px;
				line-height: 1.5em;
			}
		}

		@media screen and (max-height: 567px) {
			body.home #headerTop h1 { font-size: 16px; }
			body.home #plate, body.home #pageContent { padding-top: 86px; }
		}

*/
		.searchBar {
			background-image: url('searchBarBG.png');
			background-repeat: repeat;
			padding: 0.4375em 1.875em;
			position: relative;
			/* z-index: 99; */

			display: table;
			/* width: 100%; */
			box-sizing: padding-box;
		}

			body.home .searchBar { z-index: 3; }

			.searchBar .ui-widget-overlay {
				z-index:2;
			}

			.searchBar * {
				vertical-align: top;
			}

			.searchBar .dragBlackout {
				position: absolute;
			}

			.searchBar .searchTools {
				display: table-cell;
				width: 100%;
				position: relative;
			}

				.searchBar input.searchBox {
					color: #b2b2b2;
					/* font-family: Georgia; */
					font-family: "Georgia W01";
					font-style: italic;
					font-weight: normal;
					border: none;

					font-size: 20px;
					margin: 0.15em -0.825em 0 0;
					padding: 0 0.9em 0.075em 0.3em;

					box-sizing: border-box;
					width: 396px;
					height: 1.5em;
				}

					@media screen and (max-width: 799px) {
						.searchBar input.searchBox { width: 65%; }
					}

					.searchBar input.searchBox.active {
						color: black;
						font-size: 24px;
						margin: 0.125em -0.6875em 0 0;
						padding: 0 2em 0.0625em 0.25em;
						height: 1.25em;
					}

				.searchBar .nonSearchTools {
					display: table-cell;
					white-space: nowrap;
					padding-left: 2em;
				}

					@media screen and (max-width: 639px) {
						.searchBar { display: block; }
						.searchBar .searchTools { display: block; }
						.searchBar .nonSearchTools { display: none; }
					}


			#headerTop .searchBar a.btnMusicSearch {
				background-color: #ddd;
				background-image: url("musicIcon.png");
				background-position: 6px 8px;
				background-repeat: no-repeat;
				background-size: 16px 20px;
				box-shadow: 0.25em 0.25em 0 rgba(0, 0, 0, 0.25);
				font-size: 12px;
				font-weight: normal;
				line-height: 1em;
				margin: 0 0.3333em 0 0;
				padding: 0.5em 0.5em 0.4167em 2.25em;
				text-shadow: none;
				z-index: 11;
			}

				.searchBar a.btnMusicSearch.on {
					background-color: #eee;
				}

				/*
				.searchBar a.btnMusicSearch:before {
					content: "\266B";
					font-size: 24px;
					left: 0.25em;
					line-height: 1em;
					position: absolute;
				}
				*/

				.searchBar a.btnMusicSearch:hover {
					background-color: #eee;
					box-shadow: 0.25em 0.25em 0 rgba(0, 0, 0, 0.15);
					text-decoration: none;
				}

				.searchBar a.btnMusicSearch:active {
					box-shadow: inset 0 0 0.125em rgba(0, 0, 0, 0.15);
					margin-top: 0.25em;
					margin-left: 0.25em;
					margin-right: 0.0833em;
					margin-bottom: -0.25em;
				}

			/*
			#searchTypeSelect {
				display: inline-block;
				margin: -2px 0;
			}

				#searchTypeSelect img { vertical-align: middle; }

				#searchTypeSelect div {
					height: 16px;
					line-height: 13px;
					margin-left: -5px;
					padding-left: 5px;
					min-width: 163px; /* for highlight background image */
					/* negative left margin and padding is to allow the background image to bleed *//*
				}

					#searchTypeSelect div.selected {
						background: url('searchTypeHighlight.png') transparent -5px 1px no-repeat;
					}

					#searchTypeSelect div.selected label {
						font-weight: bold;
					}

				#searchTypeSelect label { cursor: pointer; }

				#searchTypeSelect input {
					margin: 0 2px 0 4px;
					padding: 0;
					height: 13px;
					width: 13px;
				}
			*/

			.musicSearchPane {
				position: absolute;
				z-index: 10;
				display: none;
				left: -7px;
				top: -7px;
				right: 0;
			}

				.musicSearchPane .body {
					-moz-box-shadow: 3px 3px 0 rgba(0,0,0,0.17);
					-webkit-box-shadow: 3px 3px 0 rgba(0,0,0,0.17);
					box-shadow: 3px 3px 0 rgba(0,0,0,0.17);
				}

				.musicSearchPane .body {
					position: relative;
					background-color: #dde3e3;
					padding: 7px 0 7px 7px;
					z-index: 2;
				}

				@media screen and (max-width: 799px) {
					/* .musicSearchPane input.searchBox { width: 100%; } */
				}

				.musicSearchPane .formLine {
					margin: 4px 0 4px -7px;
					padding: 1px 6px;
					font-size: 14px;
					text-align: right;
					background: #c6cece;
					background: -moz-linear-gradient(left, rgba(198,206,206,0) 0%, rgba(198,206,206,1) 40%, rgba(198,206,206,1) 100%); /* FF3.6+ */
					background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(198,206,206,0)), color-stop(40%,rgba(198,206,206,1)), color-stop(100%,rgba(198,206,206,1))); /* Chrome,Safari4+ */
					background: -webkit-linear-gradient(left, rgba(198,206,206,0) 0%,rgba(198,206,206,1) 40%,rgba(198,206,206,1) 100%); /* Chrome10+,Safari5.1+ */
					background: -o-linear-gradient(left, rgba(198,206,206,0) 0%,rgba(198,206,206,1) 40%,rgba(198,206,206,1) 100%); /* Opera11.10+ */
					background: -ms-linear-gradient(left, rgba(198,206,206,0) 0%,rgba(198,206,206,1) 40%,rgba(198,206,206,1) 100%); /* IE10+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c6cece', endColorstr='#c6cece',GradientType=1 ); /* IE6-9 */
					background: linear-gradient(left, rgba(198,206,206,0) 0%,rgba(198,206,206,1) 40%,rgba(198,206,206,1) 100%); /* W3C */
				}

					.musicSearchPane .formLine * { vertical-align: middle; }

					.musicSearchPane .formLine.first { margin-top: 10px; }

					.musicSearchPane .formLine input {
						width: 174px;
						font-size: 14px;
						margin-left: 5px;

						height: 1.25em;
						padding: 2px 24px 2px 2px;
					}

					.musicSearchPane .formLine em {
						font-size: 12px;
						width: 48px;
						margin: 0 2px;
					}

					.musicSearchPane .disclaimer {
						font-size: 12px;
						font-style: italic;
						margin: 5px 7px 0 0;
					}


		#filters {
			position: relative;
			padding: 0.5em;
			margin-bottom: 1em;
			font-size: 16px;

			/* text-align: center; */
			text-align: left;
			display: table;
			transition: margin-bottom 0.5s, padding-top 0.5s;
		}

			@media screen and (min-width: 640px) {
				#filters {
					margin-bottom: -1em;
					padding-top: 0;
				}
				#resultHeader.expanded #filters,
				#resultHeader.hasActiveFilters #filters {
					margin-bottom: 1em;
					padding-top: 0.5em;
					transition: margin-bottom 0.5s, padding-top 0.5s;
				}
			}

			#showFilters {
				vertical-align: top;

				display: table-cell;
			}

				/* #filters.hasActiveFilters #showFilters { display: table-cell; } */

				#showFilters .button { white-space: nowrap; }


			.filterListContainer {
				width: 100%;
				vertical-align: top;
				position: relative;

				/* display: none; */
				display: table-cell;
			}

				/* #filters.hasActiveFilters .filterListContainer { display: table-cell; } */

				.filterListInnerContainer {
					position: absolute;
					left: 0;
					right: 0;
				}

				.filterListContainer .controls {
					position: relative;
					height: 0;
					margin: 0 0.5em;
				}

					.filterListContainer .prev-page,
					.filterListContainer .next-page {
						position: absolute;
						cursor: pointer;
						clear: both;
						top: -2em;
						display: block;
						width: 0;
						height: 0;

						border-top: 0.75em solid transparent;
						border-right: 1.5em solid rgba(0, 0, 0, 0.5);
						border-bottom: 0.75em solid transparent;
						border-left: 1.5em solid rgba(0, 0, 0, 0.5);
						/* margin: 0.09375em 0; */
					}

						.filterListContainer .next-page {
							right: 0;
							border-right-width: 0;
						}

							.filterListContainer .next-page:before {
								border-left: 1px solid rgba(0, 0, 0, 0.25);
								box-shadow: -0.375em 0 0.5em -0.25em rgba(0, 0, 0, 0.5), 0.375em 0 0.5em -0.25em rgba(255, 255, 255, 0.25) inset;
								left: -2em;
							}

						.filterListContainer .prev-page {
							left: 0;
							border-left-width: 0;
						}

							.filterListContainer .prev-page:before {
								border-right: 1px solid rgba(0, 0, 0, 0.25);
								box-shadow: 0.375em 0 0.5em -0.25em rgba(0, 0, 0, 0.5), -0.375em 0 0.5em -0.25em rgba(255, 255, 255, 0.25) inset;
								right: -2em;
							}

						.filterListContainer .next-page:before,
						.filterListContainer .prev-page:before {
							content: "";
							display: block;
							height: 3.25em;
							position: absolute;
							top: -1.625em;
							width: 1em;
							z-index: 1;
						}

				.filterListContainer .edgeFadeOut {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 2.5em;
					right: 2.5em;
					box-shadow: -2em 0 1em -1em #ccccc9 inset, 2em 0 1em -1em #ccccc9 inset;
					pointer-events: none;
					z-index: 2;
				}

				#filterList {
					position: relative;
					overflow: hidden;
					margin: 0 2.5em;
					padding-bottom: 0.1875em;
				}

					#filterList ul {
						list-style: none;
						position: relative;
						margin-top: 0;
						padding: 0;
						/*
						margin-left: 5px;
						margin-right: 5px;
						*/
					}

						#filterList li {
							position: relative;
							display: block;
							float: left;
							background-color: #ddd;
							/* text-transform: uppercase; */
							/* font-weight: bold; */
							margin: 0 0.75em 0 0;
							padding: 0 2em 0 0.5em;
							box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
							vertical-align: top;
							border-radius: 4px;
							text-shadow: 0 0 0.25em rgba(255, 255, 255, 0.5);

							white-space: nowrap;
						}

							#filterList li .type {
								display: block;
								font-size: 0.75em;
								line-height: 0.75em;
								text-transform: none;
								margin-top: 0.3333em;
							}

								#filterList li .type:empty { margin-top: 0.6667em; }

							#filterList li .value {
								display: block;
								margin-bottom: 0.1875em;
								line-height: 1.25em;
							}

								#filterList li .type:empty ~ .value { margin-bottom: 0.5em; }

							#filterList li .remove {
								position: absolute;
								right: 0;
								top: 50%;
								margin-top: -0.75em;
								display: block;
								width: 1.5em;
								height: 1.5em;
								cursor: pointer;
							}

								#filterList li .remove:before,
								#filterList li .remove:after {
									position: absolute;
									background-color: black;
									height: 0.875em;
									width: 0.1875em;
									top: 50%;
									margin-top: -0.4375em;
									left: 50%;
									margin-left: -0.09375em;
									content: '';
								}

									#filterList li .remove:before {
										-webkit-transform:rotate(45deg);
										-ms-transform:rotate(45deg);
										-o-transform:rotate(45deg);
										transform:rotate(45deg);
									}

									#filterList li .remove:after {
										-webkit-transform:rotate(-45deg);
										-ms-transform:rotate(-45deg);
										-o-transform:rotate(-45deg);
										transform:rotate(-45deg);
									}

									#filterList li .remove:hover:before,
									#filterList li .remove:hover:after { background-color: #787d7d; }


	#pageContent {
		position: relative;
		margin: 0;
		z-index: 3;
		min-height: 475px;
		padding-bottom: 60px; /* room for footer; this was a margin, but that doesn't work in Safari for some reason */
	}

		#mainArea { padding-bottom: 1px; } /* for Safari, to ensure final elements with margin-bottom actually get their space */

		#mainArea.textPage,
		#mainArea.accountPage {
			max-width: 1200px;
			margin: 0 auto;
		}		

		#ordersDialog .note {
			font-style: italic;
			text-align: center;
			color: var(--hf-secondary-text-color);
		}

		#ordersDialog .dialogTableWrapper {
			background-color: var(--hf-panel-bg-color);
			border-radius: var(--hf-border-radius);
			overflow-y: auto;
			overflow-x: hidden;
			max-height: calc(100% - 90px);
			box-shadow: var(--hf-overlay-edge-shadow);
			margin: 1em 0.5em;
		}

		#ordersDialog table {
			border-collapse: collapse;
			position: relative;
			width: 100%;
		}

		#ordersDialog table tr { cursor: pointer; }

		#ordersDialog table thead {
			position: sticky;
			top: 0;
		}

			#ordersDialog table thead tr th {
				border-bottom: 1px solid rgba(0,0,0,0.5);
				background-color: var(--hf-accent-color);
				font-weight: normal;
				color: var(--hf-strong-color);
			}

		#ordersDialog table tfoot {
			position: sticky;
			bottom: 0;
			background-color: var(--hf-panel-bg-color);
		}

			#ordersDialog table tfoot tr td {
				border-bottom: 1px solid rgba(0,0,0,0.5);
				background-color: var(--hf-hover-color);
				padding: 0.75em;
			}		

		#ordersDialog table tr.new {
			color: var(--hf-strong-color);
		}

		#ordersDialog table tr.cancelled {
			color: var(--hf-faint-text-color);
			font-style: italic;
		}

		#ordersDialog table tr:hover {
			background-color: var(--hf-hover-color);
		}

		#ordersDialog table th,
		#ordersDialog table td {
			text-align: left;
			padding: 0.5em;
			border-bottom: 1px solid rgba(0,0,0,0.5);
		}

		.stretchableMain {
			position: relative;
			/* margin: 0 12px; */
		}


.proMinutesUnavailable {
	font-weight: bold;
	text-align: center;
	border-top: 1px solid gray;
	border-bottom: 1px solid grey;
	padding: 1em;
	margin: 1em;
}


/*** SIGN UP PAGE, ACCOUNT PAGE, CONTACT FORMS ***/


.accountPage h2 {
	margin: 20px 0 10px;
	text-align: center;
}
.accountPage h3 {
	font-size: 16px;
	text-align: center;
}

.accountPage .eip_buttons {
	top: -8px;
}

/* #addressInfo { font-size: 15px; } */

#proMinutes { text-align: center; }

	#proMinutes .proMinutesCount,
	#proMinutes .proMinutesDate {
		color: var(--hf-highlight-color);
	}

table.history {
	border-collapse: collapse;
	margin: auto;
	position: relative;
	background-color: var(--hf-panel-bg-color);
	box-shadow: var(--hf-overlay-edge-shadow);
	border-radius: var(--hf-border-radius);
	overflow: hidden;
}

	table.history td.date,
	table.history th.money,
	table.history td.money { text-align: right; }

	table.history tbody tr.noneLeft,
	table.history tbody tr.noneLeft a {
		font-style: italic;
		color: var(--hf-faint-text-color);
	}

	table.history tbody tr:hover {
		background-color: #b4baba; /* for non-rgba support */
		background-color: rgba(0,0,0,0.1);
	}

	table.history th {
		font-weight: normal;
		color: var(--hf-strong-color);
		background-color: var(--hf-accent-color);
	}	

	table.history th,
	table.history td {
		text-align: left;
		padding: 0.5em;
		border-bottom: 1px solid #00000080;
	}
	
	table.history tfoot td { background-color: var(--hf-hover-color); }

	table.history a { text-decoration: underline; }

	table.history .unpaid,
	table.history .pendingAgreements { color: var(--hf-highlight-color); }

	table.history .cancelled { 
		font-style: italic;
		color: var(--hf-faint-text-color);
	}

	table.history button {
		font-size: 14px;
		font-weight: normal;
		line-height: 1em;
		vertical-align: middle;
	}


#signUpDiv {
	margin: 1em 0 0;
	/*
	padding: 5px;
	font-size: 15px;
	*/
}


.changePasswordForm td:first-child {
	padding-right: 1em;
	white-space: nowrap;
}


#forgotPassForm table,
#requestDialog table {  }

	#contactAttachNotice {
		font-style: italic;
	}

	#signUpDiv p {
		margin: 5px 0 10px;
	}

	#addressInfo,
	#invoiceInfo {
		margin-top: 4rem;
	}

		#invoiceInfo p.empty {
			text-align: center;
		}

	#addressInfo p {
		margin: 0;
	}

		#addressInfo p.instructions { color: var(--hf-highlight-color); }

	/* #addressInfo table { width: 600px; } */
	/* #addressInfo table td { width: 50%; } */

	#addressInfo .address .display {
		line-height: 1.5;
		margin-top: 1em;
		transition: var(--hf-standard-transition);
		cursor: pointer;
	}	
	
		#addressInfo .address .display:hover { color: var(--hf-strong-color); }

	.userForm h3,
	#requestDialog h3 {
		margin: 20px 0 0;
		text-align: center;
		font-weight: bold;
		display: block;
	}

	.userForm h3 {
		font-size: 16px;
	}

		#requestDialog h3,
		#addressInfo h3 {
			font-size: 16px;
			color: var(--hf-strong-color);
			font-weight: 500;
		}
		#addressInfo h3 { margin-top: 2em; }

	#requestDialog div.other {
		font-style: italic;
		/* font-size: 12px; */
		color: var(--hf-faint-text-color);
		margin-top: 0.5em;
	}

		#requestDialog div.other textarea {
			margin-top: 0.25em;
		}


	#frmSignUp table { max-width: 720px; }

		#frmSignUp td {
			padding: 0.25em;
			vertical-align: top;
		}

			#frmSignUp td:first-child { padding-top: 0.875em; }

			#frmSignUp td:nth-child(2) { white-space: nowrap; }

			#frmSignUp .emailInput, 
			#frmSignUp .passwordInput {
				display: inline-block;
			}

			#frmSignUp div.note {
				color: var(--hf-faint-text-color);
				font-style: italic;
				margin: 0.25em 0;
			}




	td#errorMessage,
	td.errorMessage,
	tr.errorMessage td {
		padding-top: 1.5em;
		color: var(--hf-highlight-color);
	}

		#errorMessage ul {
			text-align: left;
			margin: 0 auto;
			display: inline-block;
		}

			#errorMessage ul li {
				margin: 0.5em 0;
			}

		#paymentForm td.error {
			color: var(--hf-highlight-color);
			text-align: center;
		}

		#paymentForm td a {
			text-decoration: underline;
		}

	.userForm td.buttons {
		text-align: center !important;
		padding: 30px 0 10px;
	}

	.userForm table {
		margin: 1rem auto;
	}

		.userForm table td { padding: 0.25em; }
		#addressInfo table td { padding: 0 0.25em; }
		#addressInfo table.addressForm td { padding: 0.25em; }

		/*
		@media screen and (max-width: 640px) {
			#requestDialog .userForm table td { max-width: calc(50vw - var(--hf-standard-margin)); }
		}
		*/
		@media screen and (max-width: 640px) {
			.userForm table td:nth-child(1) { max-width: calc(30vw - var(--hf-standard-margin)); }
			.userForm table td:nth-child(2) { max-width: calc(70vw - var(--hf-standard-margin)); }
		}

		/*
		.userForm table:not(.addressForm) td input,
		.userForm table:not(.addressForm) td select { margin: 0.25em; }
		*/

		.accountPage .userForm table td div.emailInput::before { margin: -11px 20px -11px -21px; }
		.accountPage .userForm table td div.emailInput + .eip_container .eip_editObjWrapper::before {  }


				.userForm table.addressForm select {
					/*
					padding-top: 10px;
					padding-bottom: 10px;
					padding: 10px 20px;
					*/
					font-size: 1rem;
					font-weight: normal;
					width: 100%;
				}

			.userForm table.addressForm + span.buttons {
				display: block;
				margin: 1em 0 2em;
			}

				.userForm table.addressForm + span.buttons > a {
					border-radius: ;
					border-left: none;
					border-radius: var(--hf-border-radius);
					margin: 0 0.25em;
				}


		#requestInfo > table,
		#existingProject > table,
		#projectInfo > table { width: 100%; }

		#requestInfo textarea,
		#existingProject textarea,
		#projectInfo textarea,
		#billing_streetAddress,
		#mailing_streetAddress,
		#projectName,
		#rightsSelect { width: 260px; }

		@media screen and (max-width: 640px) {
			#requestInfo textarea,
			#existingProject textarea,
			#projectInfo textarea,
			#billing_streetAddress,
			#mailing_streetAddress,
			#projectName,
			#rightsSelect { width: calc(50vw - 40px - var(--hf-standard-margin)); }
		}

		.userForm col.first { width: 45%; }
		.userForm col.second { width: 55%; }

		.userForm table tr.error td:first-child {
			color: #c00;
		}


		#addressInfo table tr td:nth-child(2) > div:first-child {
			cursor: pointer;
			padding: 10px 110px 10px 20px; /* padding is to leave room for the EIP UI */
			transition: var(--hf-standard-transition);
		}

			#addressInfo table tr td:nth-child(2) > div:first-child:hover {
				color: var(--hf-strong-color);
			}

		#addressInfo table tr td:first-child label {
			padding-left: 111px; /* to balance off the padding-right on the form element, for centering */
		}

			@media screen and (max-width: 640px) {
				#addressInfo table tr td:first-child label { padding-left: 0; }
			}

			#addressInfo table tr td:first-child .sameAs label {
				padding-left: 0;
			}

		#addressInfo table tr td:nth-child(2) > button {
			/* margin-left: 20px; */
			margin: 0.25em 0;
		}	

		.userForm table td div.accountInfo {
			background-color: var(--hf-background-color);
			border: 1px solid transparent;
			border-radius: var(--hf-border-radius);
			box-shadow: var(--hf-input-field-shadow);
			margin: 0.25em 0;
		}

		.userForm table td .eip_container {
			margin: 0.25em 0;
		}

		/*
		.userForm table tr.required td:first-child,
		#addressInfo table td {
		*/
		.userForm table tr.required td:first-child {
			color: var(--hf-accent-color-text);
		}
		.userForm table tr.error td:first-child {
			color: var(--hf-highlight-color);
		}

			.userForm table td:first-child { text-align: right; }
			.userForm td[colspan="2"]:first-child { text-align: center; }

				.userForm table table {
					margin: 1em auto;
				}

				.userForm table table td {
					text-align: left;
				}

				.userForm table table td:first-child {
					text-align: right;
				}

				.userForm table tr.required td:first-child::before {
					content: '*';
				}

				.userForm table input[type=text],
				.userForm table input[type=email],
				.userForm table input[type=tel] {
					font-size: 1rem;
					height: 100%;
				}

					@media screen and (max-width: 640px) {
						.userForm table input[type=text],
						.userForm table input[type=email],
						.userForm table input[type=tel] {
							width: calc(100% - 40px);
						}
					}

				.userForm table input[type=number] { 
					text-align: left; 
					padding: 10px 20px;
					margin-right: 0;
				}

				/*
				.userForm table #acct_billing_name,
				.userForm table #acct_mailing_name,
				.userForm table #acct_billing_streetAddress,
				.userForm table #acct_mailing_streetAddress,
				.userForm table #acct_billing_countryId,
				.userForm table #acct_mailing_countryId,
				.userForm table #billing_name,
				.userForm table #mailing_name,
				.userForm table #billing_streetAddress,
				.userForm table #mailing_streetAddress,
				.userForm table #billing_countryId,
				.userForm table #mailing_countryId {
					width: calc(100% - 12px);
					margin-right: 0;
				}
				*/

		#signUpDiv #extension { width: 50px; }

		#projectInfo #projectDate {
			margin-right: 5px;
			vertical-align: bottom;
		}

		#addressInfo .noValue {
			font-style: italic;
			color: #666;
			font-weight: normal;
		}

		.userForm .sameAs {
			text-align: center;
			color: var(--hf-faint-text-color);
			margin: 1em 0;
			font-style: italic;
		}

			.userForm .sameAs input[type=checkbox] {
				vertical-align: middle;
			}

		#pre_frmSignUp_captcha {
			padding: 30px 0 10px;
			text-align: center !important;
		}

		#frmSignUp_captcha {
			text-align: center;
			margin-top: 2em;
		}

		#mailCaptcha > div,
		#frmSignUp_captcha > div {
			/*
			background-color: #ced7d7;
			-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			*/
			/* padding: 10px; */
		}

		#mailCaptcha div { margin: auto; }

		/*
		html .rc-anchor {
			-moz-box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
			-webkit-box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
			box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
			border-radius: 4px;
		}

		html .rc-anchor-standard {
			border: none;
			background: #d3dada;
		}
		*/


		#frmSignUp .disclaimer {
			text-align: left;
			font-style: italic;
			margin: 2rem 0;
		}

		#frmSignUp_captcha > div {
			/* margin-left: 10px; */
			margin: auto;
			display: inline-block;
		}

			#mailCaptcha table.recaptchatable,
			#frmSignUp_captcha table.recaptchatable {
				border: none !important;
				background-color: #ced7d7;
				border: medium none !important;
				-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
				-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
				box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			}

				#mailCaptcha table.recaptchatable .recaptcha_image_cell,
				#frmSignUp_captcha table.recaptchatable .recaptcha_image_cell {
					background-color: #ced7d7 !important;
				}

					#mailCaptcha table.recaptchatable .recaptcha_image_cell #recaptcha_image,
					#frmSignUp_captcha table.recaptchatable .recaptcha_image_cell #recaptcha_image {
						margin: 0 !important;
						border: none !important;
					}

				#mailCaptcha #recaptcha_response_field,
				#frmSignUp_captcha #recaptcha_response_field {
					font-size: 16px;
					border: none !important;
				}

		#mailDialog table td:first-child { text-align: right; }
		#mailDialog table td { padding-bottom: 1em; }

		#mailDialog label { font-weight: normal; }

		#mailDialog table .error h3,
		#mailDialog table h3.error,
		#mailDialog table label.error { color: #c00; }

		#mailDialog #pre_mailCaptcha { text-align: center; }

		#mailDialog div.error { margin: 10px 10px 0; }

		#mailDialog p.note {
			font-weight: var(--hf-strong-weight);
			font-style: italic;
			color: var(--hf-strong-color);
		}

		#mailDialog td > input[type="text"],
		#mailDialog td > input[type="email"] { 
			box-sizing: border-box;
			width: 100%; 
		}

		#mailDialog td.emailInput > input[type="email"] { 
			width: calc(100% - 43px); 
		}

		#mailDialog td.emailInput::before {
			margin: -1px -43px -1px -1px; /* to compensate for td borders */
		}

		#mailDialog .formLine {
			margin: 2em 0 1em;
			text-align: left;
		}

			#mailDialog .formLine label {
				display: block;
				margin: 1em 0;
			}

			#mailDialog .formLine input[type="text"],
			#mailDialog .formLine textarea {
				width: 100%; 
				box-sizing: border-box;
			}

			#mailDialog .formLine input[type="email"],
			#mailDialog .formLine input[type="password"] {
				width: calc(100% - 43px);
				box-sizing: border-box;
			}

			#mailDialog .emailInput::before { margin-right: -44px; }



/*** TEXT PAGES ***/

.textContent {
	margin: 0;
	padding: 0.25em;
	font-size: 1rem;
	line-height: 1.5;
}

	.textContent p {
		margin: 0.25em 0 0.5em;
	}

	.textContent h2 {
		font-size: 20px;
		margin: 1em 0 0.5em;
	}

	.textContent h3 {
		font-size: 20px;
		margin: 1em 0 0.5em;

	}

	.textContent h4 {
		font-size: 18px;
		margin: 20px 0 10px;
	}

	.textContent h5 {
		font-size: 18px;
		font-weight: normal;
		margin: 20px 0 10px;
		text-decoration: underline;
	}

	.textContent h6 {
		font-size: 18px;
		font-weight: normal;
		margin: 20px 0 10px;
		font-style: italic;
		color: #272622;
	}

	.textContent a:hover {
		color: white;
	}

	.textContent a.playVideoLink { text-decoration: underline; }

	a.badMath {
		display: block;
		clear: both;
		margin-top: 3em;
		padding: 1em 0;
		text-align: center;
		text-decoration: none;
	}

		a.badMath:hover {
			color: black;
			text-decoration: underline;
		}

		a.badMath img {
			height: 32px;
			width: 32px;
			margin: 0 5px;
			vertical-align: middle;
		}

	#libraryList div.videoPlayerBox,
	#libraryList div.imageBox {
		float: right;
		margin: 0 3px 10px 10px;
		max-width: 60%;		
	}

		@media screen and (max-width: 640px) {
			#libraryList div.videoPlayerBox,
			#libraryList div.imageBox {
				max-width: 100%;
				width: 100%;
				float: none;
				margin-left: 0;
				margin-right: 0;
			}
		}

		#libraryList div.videoPlayerBox video { width: 100%; height: 100%; }
		div.videoPlayerBox .videoSpacer {
			transition: padding-top 0.375s ease-in-out;
			padding-top: 75%;
			display: block;
		}



	#libraryList > ul,
	#acquisitionsList > ul,
	#compReelList {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

		#compReelList { overflow: auto; }

		#compReelList li.category {
			overflow: auto;
			margin: 10px 0;
			padding: 0;
			float: left;
			width: 50%;
		}

			@media screen and (max-width: 699px) {
				#compReelList li.category {
					width: auto;
					float: none;
				}
			}

			#compReelList li.category:nth-child(2n-1) { clear: left; }

			#compReelList li.category > div {
				padding-left: 180px;
				padding-right: 10px;
			}

				#compReelList div.imageBox {
					float: left;
					margin: 0 10px 10px -170px;
				}

					#compReelList div.imageBox img {
						display: block;
					}

				#compReelList li.category h2 { margin: 0 0 2px; }

			#compReelList ul {
				margin: 0;
				padding-left: 20px;
				list-style-type: disc;
			}

		#libraryList > ul li {
			margin: 0;
			overflow: auto;
		}

		#acquisitionsList > ul li {
			margin: 10px 0 30px;
			overflow: auto;
		}

			#libraryList > ul li > h2 {
				cursor: pointer;
				margin: 0;
				padding: 5px 0;
			}

			/*
			#libraryList > ul li > h2:hover,
			#libraryList > ul li > h2.ui-state-active {
				border-top: 1px solid rgba(0, 0, 0, 0.1);
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding: 4px 0;
			}
			*/

			#acquisitionsList > ul li > h2 {
				margin-top: 0;
			}

			#libraryList > ul li > div.description {
				margin: 10px 0 30px;
				padding-left: 15px;
				overflow-x: hidden; /* for some jQuery ui-accordion weirdness */
			}

		#acquisitionsList .thumbnail {
			float: left;
			margin-right: 10px;
			margin-bottom: 5px;
			background-color: #444;
			border-radius: 11px;
		}

		#newsletterSignupBtn {
			position: relative;
			margin: 23px auto 0;
			text-align: center;
		}

		#newsList {
			margin-top: 20px;
			text-align: center;
			line-height: 1.5;
		}

			#newsList .item {
				margin: 6px 0 15px;
				font-size: 14px;
			}

			#newsList a {
				text-decoration: none;
			}

			#newsList a,
			#newsList a > * { transition: var(--hf-standard-transition); }

			#newsList h4 {
				font-size: 14px;
				text-transform: uppercase;
				font-weight: normal;
				margin: 0;
			}

			#newsList h5 {
				font-size: 16px;
				font-weight: normal;
				font-style: italic;
				margin: 0;
			}

			#newsList .byline {
				color: var(--hf-faint-text-color);
			}

				#newsList a:hover .byline,
				#newsList a:hover h4,
				#newsList a:hover h5 {
					color: var(--hf-strong-color);
				}

		.newsArticle h4.publication {
			margin: 0;
		}


/*** GENERAL STYLES ***/

#mainArea > h1 {
	margin: 2rem auto 1rem;
	padding: 0 2px 0 4px;
	position: relative;
	font-weight: 500;
	font-size: 36px;
	text-transform: uppercase;
	text-align: center;
	position: relative;
	color: var(--hf-strong-color);
}
	#mainArea > h1 + .line {
		/*
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		margin: -5px 5px 4px;
		*/
		display: none;
	}
	
#mainArea > div > h2,
#mainArea td > h2 {
	text-align: center;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	margin: 1em 0;
	color: var(--hf-strong-color);
}

	#mainArea > div > h2 + p { margin-top: 3rem; }

	#mainArea.textPage > div > h2 { text-align: left; }
	#mainArea.textPage > div > h2 + p { margin-top: 0.25em; }
	
#mainArea > div > h3 {
	text-align: center;
	font-size: 16px;
	font-weight: normal;	
	margin: 2rem 0 1.5rem;
}

	#mainArea.textPage > div > h3 { text-align: left; }
	
#mainArea > div > p {
	line-height: 1.5;
}

.eip_container { 
	position: relative; 
	display: grid;
	grid-template-columns: auto min-content;
}

	.eip_container.eip_multiline { 
	}

	.eip_editObjWrapper {  }

		.eip_editObj {
			width: 100%;
			min-width: 100%; /* to limit horizontal scaling of <textarea>s */
			padding: 0;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			box-sizing: border-box;
		}

	.eip_buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

		.eip_multiline .eip_buttons {
			flex-direction: column;
		}

		.eip_saveButton,
		.eip_cancelButton {
			display: inline-flex;
			width: 45px;
			min-height: 40px;
			height: 100%;
			margin-left: 0;
			align-items: center;
			justify-content: center;
			vertical-align: top;
			padding: 0;
			opacity: 1;
			transition: var(--hf-standard-transition);
			color: var(--hf-button-text-color);
			position: relative;
			border-left: 1px solid var(--hf-input-divider-color);
			cursor: pointer;
			background-color: var(--hf-background-color);
			border: var(--hf-button-border);
			border-left-color: var(--hf-panel-divider-color);
			box-shadow: var(--hf-input-field-shadow);
			box-sizing: border-box;
		}
			.eip_saveButton {
				color: var(--hf-accent-color);
			}
			.eip_cancelButton {
				border-top-right-radius: var(--hf-border-radius);
				border-bottom-right-radius: var(--hf-border-radius);
				color: var(--hf-highlight-color);
			}

			.eip_multiline .eip_saveButton,
			.eip_multiline .eip_cancelButton { 
				min-height: unset;
				flex-basis: calc(50% - 1px); /* 1px for border */
			}

			.eip_multiline .eip_saveButton { 
				border-top-right-radius: var(--hf-border-radius); 
				border-bottom-right-radius: 0;
			}
			.eip_multiline .eip_cancelButton { 
				border-top-right-radius: 0;
				border-bottom-right-radius: var(--hf-border-radius); 
			}

			.eip_multiline .eip_saveButton:not(:hover) { border-bottom-color: var(--hf-panel-divider-color); }

			.eip_saveButton::before,
			.eip_cancelButton::before {
				font-family: var(--hf-icon-font);
				font-weight: 700;
				font-size: 18px;
			}			

			.eip_saveButton::before { content: '\f00c'; }
			.eip_cancelButton::before { content: '\f00d'; }

			.eip_saveButton:focus-visible,
			.eip_cancelButton:focus-visible {
				box-shadow: var(--hf-button-focus-shadow);
				outline: none;
			}
			.eip_saveButton:hover,
			.eip_cancelButton:hover {
				box-shadow: var(--hf-button-hover-shadow);
				text-decoration: none !important;
				z-index: 2;
			}
			.eip_saveButton:active, 
			.eip_cancelButton:active {
				box-shadow: var(--hf-button-active-shadow) !important;
				left: 1px;
				top: 1px;
				z-index: 3;
			}


.empty {
	color: var(--hf-faint-text-color);
	font-style: italic;
}

.noWrap { white-space: nowrap; }

a {
	cursor: pointer;
	color: inherit;
	transition: var(--hf-standard-transition);
}

a:not(.disabled):hover {
	color: var(--hf-strong-color);
}


a.disabled,
button.disabled,
img.disabled {
	cursor: default !important;
	opacity: 0.5 !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)"; /* IE8 */
}
	a.disabled:hover { text-decoration: none !important; }

a img { border: none; }

a.hasIcon:hover { text-decoration: none !important; }
a.hasIcon:hover span { text-decoration: underline; }

a button { text-decoration: none; }

p a { text-decoration: underline; }





div.jquery-tick-wrapper {
    height: 18px;
    display: block;
    float: left;
    margin: 2px 5px 2px 2px;
    width: 18px;
    background-color: #fff;
    border-radius: 4px;
}

	a.jquery-tick {
		cursor: pointer;
		display: block;
		height: 18px;
		outline: medium none;
		width: 18px;
		position: relative;
	}

	a.jquery-tick-on:after { /* checkmark */
		border-color: black;
		border-style: solid;
		border-width: 0 0 0.25em 0.25em;
		content: "";
		display: block;
		height: 0.25em;
		left: 0.125em;
		position: absolute;
		top: 0.125em;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		transform: rotate(-45deg);
		width: 0.9375em;
		z-index: 2;
	}

/*
h1 {
	font-size: 24px;
	font-weight: normal;
	text-align: center;
	color: #272622;
	padding: 2px 0 12px; /* padding instead of margin for IE7 */
	/*margin: 0;
}
*/

h2 {
	font-size: 18px;
	font-weight: normal;
}

h2.instructions {
	font-size: 16px;
	text-align: center;
	margin-top: 0;
	color: var(--hf-highlight-color);
}

	h2.instructions.disabled { display: none; }

	h2.instructions.small { font-size: 12px; }

	h2.instructions img {
		vertical-align: middle;
		margin-bottom: 3px;
	}

		h2.instructions img.copyIcon,
		h2.instructions img.contactIcon {
			width: 38px;
			height: 38px;
		}

	#searchResults h2.instructions {
		color: black;
		font-size: 17px;
	}

p.instructions {
	color: var(--hf-faint-text-color);
	/* font-size: 13px; */
	font-style: italic;
	margin: 10px 0 20px;
	text-align: center;
}

a.more {
	text-transform: lowercase;
	/* color: #d69a00; */
	font-style: italic;
	font-weight: bold;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
}
	a.more:hover { color: white; }


a.next, a.prev {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-repeat: no-repeat;
	position: relative;
	text-decoration: none;
	vertical-align: middle;
}
	a.next { background-image: url('arrow_right.png'); }
	a.prev { background-image: url('arrow_left.png'); }

select option.action { font-style: italic; }

p.keywords {
	color: #666;
	font-style: italic;
	line-height: 1.75em;
}

	p.keywords a {
		text-decoration: none;
		color: #666;
		padding: 0.5em 0 0.5em 0.5em;
	}

		p.keywords a:hover {
			text-decoration: underline;
			color: black;
		}



.pagination {
	text-align: right;
	margin: 1em 0;
	white-space: nowrap;
	margin-left: calc(var(--hf-standard-margin) * 2);
}

	.pagination input[type="number"] {
		width: 2.5em;
	}

	#mainArea .pagination .button {
		box-shadow: none;
		min-height: unset;
		height: 40px;
		margin: 0 1em;
	}

		#mainArea .pagination .button::before,
		#mainArea .pagination .button::after {
			font-family: var(--hf-icon-font);
			font-size: 24px;
			font-weight: 900;
		}	

		#mainArea .pagination .button.pager-prev::before {
			margin-right: 10px;
			content: '\f0d9';
		}
		#mainArea .pagination .button.pager-next::after {
			margin-left: 10px;
			content: '\f0da';
		}

	#mainArea .pagination .button:not(.disabled):hover,
	#mainArea .pagination .button:not(.disabled):focus {
		background-color: var(--hf-accent-color);
		box-shadow: none;
		text-decoration: none;
	}

	#mainArea .pagination .button:not(.disabled):active {
		box-shadow: none !important;
	}

	/* old? */
	.pagination a.current {
		font-weight: bold;
		text-decoration: underline;
	}

.paginatedTable .pagination {
	float: right;
	margin: 0;
}

.paginatedTable .pagination a,
.paginatedTable .pagination span{
	text-decoration: none;
	margin-right: 0.25em;
	padding: 0.5em;
}

.paginatedTable .pagination a.current {
	text-decoration: underline;
}

/*
button.toolButton {
	height: 24px;
	background: none;
	border: none;
	margin: 0;
	font-size: 12px;
	padding: 0;
	cursor: pointer;
	position: relative;
	vertical-align: middle;
}
*/

	a.close.clear {
		color: transparent;
		overflow: hidden;
		position: absolute;
		z-index: 1;
		margin-left: -1.75em;
		margin-top: 0.25em;
		font-size: 1.125em;
		background-color: #ebebeb;
		opacity: 0.5;
		display: inline-block;
	}

		a.close.clear:hover { opacity: 1 }

		.formLine a.close.clear {
			font-size: 0.857em;
			margin-left: -2em;
			margin-top: 0.125em;
		}

.dragBlackout {
	position: fixed;
	z-index: 150;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: black;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE8 */
	display: none;
}

.playerBlackout,
.filterBlackout {
	position: fixed;
	z-index: 5;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: black;
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"; /* IE8 */
	display: block;
}

li.dragHelper {
	list-style: none;
	margin: 0;
	padding: 0;
	outline: 1px dashed black;
	z-index: 5;
	opacity: 0.8;
}

div.videoPlayerBox,
div.imageBox,
img.imageBox {
	/*
	-moz-box-shadow: 3px 3px 0 #a6aeae;
	-webkit-box-shadow: 3px 3px 0 #a6aeae;
	box-shadow: 3px 3px 0 #a6aeae;
	*/
	background-color: #444;
	/* border-radius: 16px; */
	overflow: hidden;

	position: relative;
}

	/* .textPage img.imageBox { border-radius: 11px; } */

	div.videoPlayerBox {
		width: 480px;
		max-width: 100%;
	}

	div.imageBox {
		max-width: 480px;
		max-height: 360px;
	}

		div.imageBox img { 
			display: block;
			max-width: 100%;
			width: 100%; 
		}

		.imageBox.inline { margin: 0 5px 3px; }
		.imageBox.floatRight { margin: 0 0 3px 10px; }
		.imageBox.floatLeft { margin: 0 10px 3px 0; }

.positionBar {
	position: relative;
	background-color: var(--hf-background-color);
    height: 2px;
    padding: 0;
    width: 100%;
	background-color: var(--hf-darker-background-color);
	border-bottom: 1px solid #444;
}

.positionBar {
		/* background-color: var(--hf-background-color); */
		/* margin: -1px 0 0 0; */
		/* border-top: 1px solid #444; */
}


.searchResult  .positionBar {
	 height: 20px;
}
	.positionBar div {
		position: absolute;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.positionBar .clip { background-color: var(--hf-accent-color); }

	.positionBar .thumbPos {
		background-color: #ebeeee;
		width: 3px;
		margin: 0 -1px;
		z-index: 2;
	}

.bigTools {
	position: relative;
	white-space: nowrap;
	margin-left: var(--hf-standard-margin);
}

	#mainArea .bigTools a.button {
		padding: 5px 15px 5px 44px;
	}
	.bigTools a {
	}

		.bigTools a::before {
			font-family: var(--hf-icon-font);
			font-size: 20px;
			font-weight: 900;
			position: absolute;
			left: 10px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}

		.bigTools a:last-child { padding-right: 0.5em; }

		.bigTools a.disabled { opacity: 0.35 !important; }
		.bigTools a.contactResearcherBinPage::before,
		.bigTools a.orderRequest::before { content: '\f06a'; }
		.bigTools a.mailBin::before { content: '\f658'; }
		.bigTools a.saveBinCopy::before { content: '\f0c5'; }
		.bigTools a.saveBinCSV::before { content: '\f65b'; }
		.bigTools a.cancelOrder::before,
		.bigTools a.deleteBin::before { content: '\f056'; }
		.bigTools a.downloadOrder::before { content: '\f1c1'; left: 13px; }

	.bigTools img {
		margin: 0 5px 2px 1px;
		vertical-align: middle;
		text-decoration: none;
	}

.toolbar {
	position: relative;
	padding-top: 0.5625em;
	clear: right;
}

	.toolbar .bigTools { 
		float: right; 
		z-index: 2;
	}

	.toolbar h1 {
		position: relative;
		margin: 0 0 0.5em;
		margin: -8px 0 0.5em -20px;
		font-size: 24px;
		font-weight: 500;
		color: var(--hf-strong-color);
		padding: 8px 20px;
	}	

		.toolbar h1.resultCount {
			display: inline-block;
			left: 13px;
			margin-right: 18px;
			text-align: center;
			line-height: 11px;
		}

	.toolbar h4 {
		position: relative;
		display: inline;
		margin: 0;
		font-size: 16px;
		font-weight: normal;
		font-style: italic;
		padding-left: 20px;
	}

	.toolbar a {
		text-decoration: none;
	}

	.toolbar .pagination,
	.toolbar .topRight {
		position: relative;
		float: right;
		padding-right: 20px;
		margin-top: 1em;
	}
		.toolbar .pagination a { border-right: none; }

		.toolbar .pagination .current {
			font-weight: bold;
			text-decoration: underline;
		}

	.toolbar select {
		max-width: 150px;
	}

	.toolbar .tools {
		position: relative;
		clear: both;
		font-weight: 500;
		display: flex;
		flex-wrap: wrap;
		gap: calc(var(--hf-standard-margin) / 2) var(--hf-standard-margin);
		justify-content: end;
		align-items: baseline;
		text-transform: uppercase;
		margin-bottom: var(--hf-standard-margin);
	}
		.toolbar .tools a {
			height: 25px;
			display: inline-block;
			text-transform: uppercase;
		}

		.toolbar .tools a img {
			margin: 0 5px 2px 0;
			vertical-align: middle;
			text-decoration: none;
		}

		.toolbar .tools .resultBinSelect {
			white-space: nowrap;
			display: flex;
			align-items: baseline;
			gap: 0.5em;
			transition: var(--hf-standard-transition);
		}

			.toolbar .tools .resultBinSelect .selectWrapper {
				display: inline-block;
				height: var(--hf-button-min-height);
			}		

			.toolbar .tools .resultBinSelect button {
				margin: 0 0 0 0.5em;
			}

			.toolbar .tools .resultBinSelect.disabled {
				opacity: 0.5;
			}

				.toolbar .tools .resultBinSelect.disabled select,
				.toolbar .tools .resultBinSelect.disabled button {
					opacity: 1 !important; /* because their parent already has it */
				}



	.toolbar div.info {
		position: relative;
		padding: 1em 20px;
		text-align: right;
		clear: both;
	}

		.toolbar div.info span.info {
			display: inline-block;
			padding: 1px 0 0 0.5em;
			margin: 0 0 0 0.25em;
			border-left: 1px solid var(--hf-faint-text-color);
		}



			.toolbar div.info span.info:first-child { border-left: none; }


.popup,
div.ui-dialog-content.popup {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 200;
	margin: auto;
	background-color: #ced7d7;
	padding: 0;
}

div.thumb span.specialty,
div.thumb span.noVideo {
	position: absolute;
	left: 0;
	right: 0;
	padding: 3px 15px 3px 45px;
	display: block;
	background-position: 12px center;
	background-repeat: no-repeat;
	background-color: rgba(255,255,255,0.7);
	font-style: italic;
}
div.thumb span.specialty {
	/* top: 36px; */
	bottom: 4px;
	background-image: url('icon_specialty.png');
	background-size: 24px 24px;
}
	.hasThumbnails div.thumb span.specialty {
		bottom: 26px;
	}

div.thumb span.noVideo {
	/* top: 43px; */
	top: auto;
	bottom: 4px;
	background-image: url('icon_footage.png');
	background-size: 24px 24px;
}

	/*
	li.clipDetails.aspect5625 div.thumb span.noVideo { top: 28px; }
	li.clipDetails.aspect6666 div.thumb span.noVideo { top: 37px; }
	li.clipDetails.aspect6864 div.thumb span.noVideo { top: 38px; }
	li.clipDetails.aspect7272 div.thumb span.noVideo { top: 41px; }
	*/
	.hasThumbnails div.thumb span.noVideo {
		bottom: 26px;
	}


#alertDialog a { text-decoration: underline; }
#alertDialog a:hover { color: white; }

input#ccNumber { width: 150px; }
input#ccCode { width: 30px; }




.exampleButton {
	margin: 5px 10px;
	vertical-align: middle;
	height: 2.25em;
	min-height: unset;
	cursor: default;
}
	
	.exampleButton:focus-visible { outline: none; }


div.ui-dialog {
	box-shadow: var(--hf-modal-shadow);
	border: var(--hf-modal-border);
	background-color: var(--hf-background-color);
	border-radius: var(--hf-border-radius);
	padding: 1em 1.25em 0.625em;
}

	div.ui-dialog p {
		margin: 0 0 1em;
		line-height: 1.25em;
	}

	div.ui-dialog h3 {
		font-size: inherit;
		display: inline-block;
		margin: 0 0.75em 0;
	}

	div.ui-dialog div.line {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 10px;
	}

	div.ui-dialog-titlebar {
		position: relative;
		font-size: 18px;
		font-weight: normal;
		padding: 0.83em 0 2.5em;
		text-align: center;
		color: var(--hf-strong-color);
	}


		a.ui-dialog-titlebar-close span.ui-icon,
		button.ui-dialog-titlebar-close span.ui-icon{
			/*
			background: url("closeButton.png") no-repeat scroll 0 0 transparent;
			display: block;
			height: 15px;
			width: 15px;
			overflow: hidden;
			text-indent: -99999px;
			*/
			display: none;
		}

		button.ui-dialog-titlebar-close .ui-button-text {
			display:none;
		}

	div.ui-dialog div.error {
		padding-top: 30px;
		font-weight: bold;
		color: #c00;
	}

	div.ui-dialog-content {
		overflow-y: auto;
		overflow-x: hidden;
		/* padding-bottom: 1em; */
		padding-left: 3px; /* to allow for shadows on children, given the hidden overflow-x */
		padding-right: 3px;
		line-height: 1.25em;
	}

		/* div.ui-dialog-content form { margin: 1em 2px 0; } */

	div.ui-dialog-buttonpane {
		text-align: center;
		padding: 25px 0;
	}

		div.ui-dialog-buttonpane button {
			margin-left: 15px;
			margin-top: 15px;
		}

	div.ui-widget-overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.9;
		background-color: #333;
	}


	/* full-screen overlays */
	div.ui-dialog.fullScreen,
	div.videoPlayer.fullScreen {
		top: 0 !important;
		right: 0 !important;
		left: 0 !important;
		bottom: 0 !important;
		width: auto !important;
	}

	div.ui-dialog.fullScreen {
		display: grid;
		grid-template-rows: min-content auto min-content;
		grid-template-columns: 1fr;
		padding: 0;
	}

		div.ui-dialog.fullScreen .ui-dialog-titlebar {
			/*
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 19px;
			*/
			padding: calc(var(--hf-standard-margin) / 3);
			padding-left: calc((var(--hf-standard-margin) / 3) + 36px); /* to compensate for the "close" button */
			background-color: var(--hf-panel-bg-color);
			box-shadow: var(--hf-overlay-edge-shadow);
			display: grid;
			grid-template-columns: 1fr min-content;
			align-items: center;
			justify-content: center;
		}
			div.ui-dialog.fullScreen .ui-dialog-titlebar > button.ui-dialog-titlebar-close {
				position: relative;
				right: unset;
				top: unset;
				background-color: transparent;
			}
		div.ui-dialog.fullScreen .ui-dialog-content {
			/*
			bottom: 0;
			margin-bottom: 53px;
			margin-top: 35px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			*/
			height: auto !important;
			padding: var(--hf-standard-margin);
		}
		div.ui-dialog.fullScreen .ui-dialog-buttonpane {
			/*
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			height: 37px; 
			*/
			background-color: var(--hf-panel-bg-color);
			padding: 0 calc(var(--hf-standard-margin) / 2) calc(var(--hf-standard-margin) / 2);
			box-shadow: var(--hf-overlay-edge-shadow);
		}

		div.ui-dialog.fullScreen .videoPlayerBox { margin: auto; }

		html .jQueryDatePicker .ui-widget,
		html .jQueryDatePicker .ui-widget input,
		html .jQueryDatePicker .ui-widget select,
		html .jQueryDatePicker .ui-widget textarea,
		html .jQueryDatePicker .ui-widget button { font-family: "Calibri W01"; }



/*** HOME PAGE ***/

#featuresBox {
	position: relative;
	margin: 75px 55px 60px;
	padding: 0;
	min-height: 457px;
	clear: left;
}

	.newsArea + #featuresBox { margin-top: 0; }

	#featuresBox .boxTop {
		position: absolute;
		top: -7px;
		left: 6px;
	}

	#featuresBox .boxBottom {
		position: absolute;
		bottom: -7px;
		left: 6px;
	}

	#featuresBox .boxFlourish {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	#featuresBox h2 {
   		font-size: 24px;
		/*
		position: absolute;
		width: 480px;
		*/
		text-align: center;
		margin: 0;
		padding: 0.5em 0.6667em 0.25em;
	}

	.videoPlayerBox .playerDiv {
		position: absolute;
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	/*.videoPlayerBox:after{
		content: "";
		display: block;
		padding-top: 75%;
		transition: padding-top 0.375s ease-in-out;
	}*/

		.videoPlayerBox.aspect5625:after { padding-top: 56.25%; }
		.videoPlayerBox.aspect6666:after { padding-top: 66.66%; }
		.videoPlayerBox.aspect6864:after { padding-top: 68.64%; }
		.videoPlayerBox.aspect7272:after { padding-top: 72.72%; }


	.video-js {
		width:100% !important;
		height:100% !important;
	}

	#featuresBox .videoPlayerBox {
		position: absolute;
		width:480px;
		top: 62px;
		right: 0;
		z-index: 2;
		border-radius: 16px;
		overflow: hidden;
	}

		#featuresBox .videoPlayerBox .video-js,
		#featuresBox .videoPlayerBox .video-js video {
			border-radius: 16px;
			overflow: hidden;
		} /* for Safari */

	#featuresBox #extraVidControls {
		font-size: 12px;
		position: absolute;
		text-align: right;
		right: 15px;
		top: 422px;
		z-index: 2;
		min-height: 34px; /* to preserve the space if the contents are hidden */
		transition: top 0.375s ease-in-out;
	}

		#featuresBox .videoPlayerBox.aspect5625 + #extraVidControls { top: 332px; }
		#featuresBox .videoPlayerBox.aspect6666 + #extraVidControls { top: 382px; }
		#featuresBox .videoPlayerBox.aspect6864 + #extraVidControls { top: 391px; }
		#featuresBox .videoPlayerBox.aspect7272 + #extraVidControls { top: 411px; }

		#featuresBox #extraVidControls a {
			text-decoration: none;
			display: inline-block;
			padding: 10px;
		}

			#featuresBox #extraVidControls a:hover { text-decoration: underline; }

	#featuresBox #featuresList {
		top: 15px;
		left: 0;
		right: 0;
		padding: 0;
   		margin: 0 -55px;
		list-style-type: none;
		color: black;
		font-size: 16px;
	    line-height: 1.125em;
	}

		#featuresBox #featuresList li {
			position: relative;
   			/* padding: 10px 534px 13px 60px; */
   			padding: 10px 589px 13px 55px;
			overflow: hidden;
		}

			#featuresBox #featuresList li:before {
				border-left-width: 1.5em;
				border-left-style: solid;
				border-left-color: transparent;
				border-top: 0.75em solid transparent;
				border-bottom: 0.75em solid transparent;
				content: "";
				height: 0;
				left: 17px;
				margin-top: -0.75em;
				position: absolute;
				top: 39px;
				width: 0;
			}

			#featuresBox #featuresList li.active:before {
				border-left-color: rgba(0, 0, 0, 0.25);
				transition: border-left-color 0.5s;
			}


			#featuresBox #featuresList li .playlistItem {
				padding-left: 90px;
				position: relative;
				cursor: pointer;
				min-height: 58px; /* for the thumb, which is absolutely positioned */
			}

				#featuresBox #featuresList li .playlistItem:before {
					content: "";
					position: absolute;
					left: -55px;
					right: -589px;
					top: -10px;
					bottom: -13px;
					pointer-events: none;
				}

					#featuresBox #featuresList li .playlistItem:hover:before,
					#featuresBox #featuresList li.active .playlistItem:before {
						background: -moz-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.07) 70%); /* FF3.6+ */
						background: -webkit-gradient(linear, right top, left top, color-stop(0%,rgba(0,0,0,0)), color-stop(70%,rgba(0,0,0,0.07))); /* Chrome,Safari4+ */
						background: -webkit-linear-gradient(right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.07) 70%); /* Chrome10+,Safari5.1+ */
						background: -o-linear-gradient(right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.07) 70%); /* Opera11.10+ */
						background: -ms-linear-gradient(right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.07) 70%); /* IE10+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
						background: linear-gradient(right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.07) 70%); /* W3C */
					}

		#featuresBox #featuresList li .thumb {
			/*
			-moz-box-shadow: 3px 3px 0 #a6aeae;
			-webkit-box-shadow: 3px 3px 0 #a6aeae;
			box-shadow: 3px 3px 0 #a6aeae;
			background-color: #444;
			*/

			/*
			width: 80px;
			height: 60px;
			*//* a pixel shaved off each side to trim the outer edge of the image, to avoid showing our "underscan" */
			width: 78px;
			height: 58px;

			border-radius: 8px;
			overflow: hidden;

			/*
			float: left;
			margin-right: 15px;
			margin-top: 4px;
			position: relative;
			*/

			position: absolute;
			left: 0;
			top: 0;
		}

			#featuresBox #featuresList li .thumb img { margin: -1px; } /* a pixel shaved off each side to trim the outer edge of the image, to avoid showing our "underscan" */

			#featuresBox #featuresList li .thumb:before {
				border-left-width: 2em;
				border-left-style: solid;
				border-left-color: transparent;
				border-top: 1em solid transparent;
				border-bottom: 1em solid transparent;
				content: "";
				height: 0;
				left: 50%;
				margin-left: -0.75em;
				margin-top: -1em;
				position: absolute;
				top: 50%;
				width: 0;
			}

				#featuresBox #featuresList li:hover .thumb:before {
					border-left-color: rgba(255, 255, 255, 0.65);
					transition: border-left-color 0.5s;
				}

		#featuresBox #featuresList li h4 {
			font-weight: bold;
			font-size: 16px;
			margin: 0 0 5px;
			text-transform: uppercase;
		}

.newsArea {
	position: relative;
	/* width: 880px; */
	margin: 36px 55px 20px;
	overflow: visible;
}

	.newsArea:first-child {
		margin-top: 100px;
		margin-bottom: 8px;
	}

	.newsArea:after { /* float clearing; can't use overflow in this case */
		content: ".";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}

	.newsArea p {
		margin: 0 0 0.5em;
	}

	.newsArea #news,
	.newsArea #additions {
		position: relative;
	}

	.newsArea #additions {
	}

		.newsArea.hasPromo #additions {
			float: right;
			width: 480px;
			margin-left: 54px;
		}

		.newsArea .image {
			float: left;
			/*
			width: 160px;
			height: 120px;
			*//* a pixel shaved off each side to trim the outer edge of the image, to avoid showing our "underscan" */
			width: 158px;
			height: 118px;

			margin-right: 1em;
			margin-bottom: 5px;
			/*
			-moz-box-shadow: 3px 3px 0 #adaeab;
			-webkit-box-shadow: 3px 3px 0 #adaeab;
			box-shadow: 3px 3px 0 #adaeab;
			background-color: #444;
			*/

			position: relative;
			border-radius: 11px;
			overflow: hidden;
		}

			.newsArea #news .image {
				/* a pixel shaved off each side to trim the outer edge of the image, to avoid showing our "underscan" */
				width: 118px;
				height: 88px;
			}

			.newsArea .image img {
				margin: -1px; /* a pixel shaved off each side to trim the outer edge of the image, to avoid showing our "underscan" */
				width: 160px;
				height: 120px;
			}

				.newsArea #news .image img {
					width: 120px;
					height: 90px;
				}

			#featuresBox #featuresList li .thumb:after,
			.newsArea .image:after,
			div.imageBox:after,
			img.imageBox:after  {
				position: absolute;
				content: "";
				right: 0;
				top: 0;
				left: 0;
				bottom: 0;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.25) inset;
			}

		.newsArea #additions h4 {
			text-transform: uppercase;
			font-weight: normal;
			margin-top: 0;
		}

	.newsArea h2 {
		/*
		margin: 0;
		padding: 0 1px 0 4px;
		position: absolute;
		top: -13px;
		left: 13px;
		border-bottom: 1px solid #d0d1ce;
		*/
		line-height: 16px;

		font-family: inherit;
		font-style: normal;
		font-weight: bold;
		text-transform: uppercase;
		margin: 0 0 1em;
	}

		.newsArea #news h2 { margin-bottom: 0.25em; } /* this one floats next to an image, doesn't need as much space */


	/* home page layout responsiveness */

	@media screen and (max-width: 959px) {

		#featuresBox #videoPlayerContainer {
			margin: 10px auto 0;
			position: relative;
			right: auto;
			top: 0;
		}

		#featuresBox #extraVidControls {
			margin: auto;
			position: relative;
			right: auto;
			top: 0;
			width: 450px;
		}

		#featuresBox #featuresList li {
			padding-right: 55px;
		}

		.newsArea #additions { margin-bottom: 60px; }

			.newsArea.hasPromo #additions {
				float: none;
				margin-left: 0;
				width: auto;
			}

	}

	@media screen and (max-width: 639px),
	(max-height: 567px) {

		#featuresBox #videoPlayerContainer,
		#featuresBox #extraVidControls {
			display:none;
		}

		#featuresBox #featuresList {
			margin-top: 15px;
		}

			#featuresBox #featuresList li.active:before { display: none; }

	}

	@media screen and (max-width: 499px) {
		#videoPopup { padding: 0; }
		#videoPopup div.videoPlayerBox { border-radius: 0; }
	}


/*** SEARCH PAGE ***/

#resultHeader {
	text-align: center;
}

	#resultHeader .toolbar h1 { border-bottom: none; }

	@media screen and (min-width: 640px) and (max-width: 899px) {

		#resultHeader .resultCount {
			margin-left: 10%;
			transition: margin-left 0.5s;
		}

		#resultHeader.expanded .resultCount,
		#resultHeader.hasActiveFilters .resultCount { margin-left: 0; }

	}

	@media screen and (min-width: 640px) and (max-width: 767px) {

		#resultHeader {
			text-align: right;
			margin-right: 0.75em;
			transition: text-align 0.5s, margin-right 0.5s;
		}

		#resultHeader.expanded,
		#resultHeader.hasActiveFilters {
			text-align: center;
			margin-right: 0;
		}

		#resultHeader .resultCount { margin-left: 0; }

	}

.searchResults {
	/* padding-top: 128px; */
	/* padding-top: 230px; */
	padding-top: 1.25em;
}

	.filterPane {
		/*
		position: relative;
		margin: 30px 30px 10px;
		*/

		/*
		top: 152px;
		left: 30px;
		left: 225px;
		right: 30px;
		*/

		/*
		position: absolute;

		top: 30px;
		left: 0;
		right: 18px;
		margin: 0;
		z-index: 5;

		padding: 9px 0 7px;
		background-color: #c4cccc;
		-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
		-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
		box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
 		*/
		text-align: center;
		position: relative;
		margin: 1em 0 2em;
		padding-bottom: 0.5em
	}

		.filterPane .pullTab {
			position: absolute;
			bottom: -10px;
			width: 96px;
			margin-left: -48px;
			height: 11px;
			left: 50%;
			z-index: 10;
			background-position: center top;
			background-repeat: no-repeat;
			cursor: pointer;
			background-image: url('verticalPullTab-open.png');
		}

			.filterPane.expanded .pullTab { background-image: url('verticalPullTab-close.png'); }
			.filterPane.collapsed .pullTab { background-image: url('verticalPullTab-open.png'); }


		.filterPane .titleBar {
			/*
			position: absolute;
			top: -19px;
			left: 0;
			height: 16px;
			width: 100%;
			border-bottom: 1px solid #b4b4b4; /* for non-rgba support */
			/* border-bottom: 1px solid rgba(0,0,0,0.1); */
		}

			.filterPane h2 {
				/*
				position: absolute;
				bottom: -1px;
				border-bottom: 1px solid #c7c7c4;
				left: 13px;
				*/
				margin: 0;
				padding: 0 1px 0 4px;
				line-height: 12px;
				text-align: center;
			}

			table.searchFiltersWrapper { width: 100%; }
			table.searchFiltersWrapper td.groupCell { padding: 0; }
			table.searchFiltersWrapper td.groupCell:last-child table.searchFilters { margin-left: -12px; } /* to compensate for it's border-spacing */

			/* split filters into two rows */
			@media (max-width: 799px) {
				table.searchFiltersWrapper td.groupCell {
					display: block;

					/* for IE9, where "display: block" doesn't work: */
					width: 100%;
					float: left;
				}
				table.searchFiltersWrapper td.groupCell table.searchFilters { width: 100%; } /* for Safari */
				table.searchFiltersWrapper td.groupCell:first-child table.searchFilters { height: auto; }
				table.searchFiltersWrapper td.groupCell:last-child table.searchFilters { margin-left: 0; }
				table.searchFiltersWrapper table.searchFilters col.specialty { width: 60%; }
			}

			table.searchFilters {
				width: 100%;
				/* font-size: 16px; */
				font-size: 15px;
				border-spacing: 0.625em 0;
				text-align: left;
				margin-bottom: 1em;

				border-spacing: 0.667em 0 ;
				min-width: 100%; /* to ensure it fills the space */
				width: auto; /* so that it can be bigger than 100%, if negative margins allow it */
				height: 10em;
			}

				/*
				table.searchFilters col.specialty { width: 138px; }
				table.searchFilters col.year { width: 86px; }
				table.searchFilters col.color { width: 117px; }
				*/
				table.searchFilters col.specialty { width: 142px; }
				table.searchFilters col.year { width: 90px; }
				/*table.searchFilters col.color { width: 121px; }*/
				table.searchFilters col.color { width: 73px; } /* shortened "Black & White" to "B&W" */

				table.searchFilters th {
					font-weight: bold;
					font-size: 16px;
					text-transform: uppercase;
					text-align: center;
					padding: 0 0 0 2px;
					color: black;
					height: 1.1875em;
				}

					table.searchFilters th .availHelp {
						background-image: url("icon_askUs.png");
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 1.1875em 1.1875em;
						display: inline-block;
						width: 2em;
						height: 2em;
						line-height: 1.1875em;
						margin: -0.625em -0.406255em -0.406255em;
						vertical-align: middle;
						cursor: pointer;
						opacity: 0.75;
					}

						table.searchFilters th .availHelp:hover { opacity: 1; }

				table.searchFilters td.filterBox {
					background-color: #d3dada;
					-moz-box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
					-webkit-box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
					box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
					/* padding: 8px 7px; */
					vertical-align: middle;
					white-space: nowrap;
					border-radius: 4px;
					overflow: hidden; /* to enforce border-radius */
					color: black;

					padding: 0;
				}

					table.searchFilters td.filterBox.list {
						padding-left: 0;
						padding-right: 0;
					}

					table.searchFilters td.filterBox.longList {
						padding: 0;
						height: 8.75em;
						white-space: normal;

					}
						table.searchFilters td.filterBox.longList ul {
							position: relative;
							height: 8.75em;
						}

					.filterPane.expanded table.searchFilters td.filterBox.longList {
						height: 204px;
					}

					table.searchFilters td.filterBox.year {
						text-align: right;
						padding: 0 7px;
					}

						table.searchFilters td.filterBox.year input {
							width: 2.25em;
							/* height: 15px; */
						}

					table.searchFilters td.filterBox#specialtycollection { padding: 0; }

				.filterPane input[type=text] {
					padding: 0 2px;
					margin: 0.25em 0;
					font-size: 16px;
					border: none;
				}

				.filterPane input[type=radio] {
					vertical-align: bottom;
					margin: 2px 2px 1px 0;
				}

				.filterPane label { cursor: pointer; }

				.filterPane dfn,
				.filterPane .count { color: #666; }

					.filterPane .selected .count { color: #aaa; }

				.filterPane ul.facetSelect {
					padding: 0;
					margin: 0;
					list-style-type: none;
					overflow-y: auto;
					overflow-x: hidden;
				}

					.filterPane ul.facetSelect li {
						margin: 0;
						line-height: 1.1875em;
						padding: 0.5em 0.625em 0.5em 2.25em;
						/*
						background: transparent url('check_off.png') 0.4375em 0.4375em no-repeat;
						background-size: 1.3125em 1.3125em;
						*/
						cursor: pointer;
						white-space: nowrap; /* for IE7, which doesn't observe white-space on <td>s (table.searchFilters td.filterBox) */
						position: relative;
					}

						table.searchFilters td.filterBox.longList ul.facetSelect li { white-space: normal; } /* for IE7; see above */

						/* circle */
						.filterPane ul.facetSelect li:before {
							background-color: #bac3c3;
							border-radius: 0.5625em;
							content: "";
							height: 1.125em;
							left: 0.5625em;
							position: absolute;
							top: 0.5625em;
							width: 1.125em;
							z-index: 1;
						}

						/* checkmark */
						.filterPane ul.facetSelect li:after {
							border-color: transparent;
							border-style: solid;
							border-width: 0 0 0.25em 0.25em;
							content: "";
							display: block;
							height: 0.25em;
							left: 0.75em;
							position: absolute;
							top: 0.625em;
							-webkit-transform: rotate(-45deg);
							-ms-transform: rotate(-45deg);
							-o-transform: rotate(-45deg);
							transform: rotate(-45deg);
							width: 0.9375em;
							z-index: 2;
						}

					/*
					.filterPane .list ul.facetSelect li {
						margin: 0;
						padding: 8px 10px 8px 36px;
						cursor: pointer;
					}
					*/

					/*.filterPane ul.facetSelect.noneSelected li { background-image: url('check_semi.png'); }*/
					.filterPane ul.facetSelect.noneSelected li:after { border-color: rgba(0, 0, 0, 0.25); }

					.filterPane ul.facetSelect li.odd { background-color: #c8cfcf; }

					.filterPane ul.facetSelect li.selected {
						background-color: #444;
						/* background-image: url('check_on.png'); */
						color: white;
					}

						.filterPane ul.facetSelect li.selected:after { border-color: white; }

					.filterPane ul.facetSelect li:hover { background-color: #969d9d; }


	#resultFooter {
		margin-top: 40px;
	}

	#resultsList {
		position:relative;
	}

	#resultsList,
	#resultsList .resultPage {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

		#resultsList .resultPage li.resultPageHeader {
			display: table;
			margin-bottom: 1.25em;
		}

			#resultsList .resultPage li.resultPageHeader span.divider {
				display: table-cell;
				content: '';
				vertical-align: baseline;
				width: 100%;
			}

				#resultsList .resultPage li.resultPageHeader span.divider span {
					display: block;
					border-top: 1px solid rgba(0,0,0,0.35);
					height: 0.15em;
					margin-right: 0.25em;
				}

			#resultsList .resultPage li.resultPageHeader span.jumpTo {
				display: table-cell;
				white-space: nowrap;
				color: rgba(0,0,0,0.5);
				font-style: italic;
				text-align: right;
			}

				#resultsList .resultPage li.resultPageHeader span.jumpTo input {
					width: 2em;
					font-size: 16px;
					border: none;
				}

		#resultsList .resultPage li.searchResult {
			margin: 0 0 20px 0;
			padding: 0;
			background-color: #c8cfcf;
			-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
			/* min-height: 146px; */
			position: relative;
			overflow: visible;
			cursor: pointer;
		}

			#resultsList .resultPage li.searchResult .reelDetails {
				/*
				font-size: 12px;
				min-height: 146px;
				*/
				cursor: pointer;
				overflow: auto; /* float clearing */
			}

				#resultsList .resultPage li.searchResult .reelDetails a:hover {
					text-decoration: underline;
					color: black;
				}

				#resultsList .resultPage li.searchResult .reelDetails .titleBar:hover {
					background-color: #737575;
				}

				#resultsList .resultPage li.searchResult .reelDetails .thumb {
					border-radius: 8px;
					overflow: hidden;
					background-color: #d9dddd;
				}

					/* for old WebKit; Safari 5, Android 4.0 */
					#resultsList .resultPage li.searchResult .reelDetails .thumb .img { border-radius: 8px 8px 0 0; }
					#resultsList .resultPage li.searchResult .reelDetails .thumb .positionBar { border-radius: 0 0 8px 8px; }

			#resultsList .resultPage li.searchResult.hasSceneMatches .reelDetails {
				width: 54%;
				display: inline-block;
				vertical-align: top;
			}

			#resultsList .blackout {
				position: fixed;
				z-index: 4;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE8 */
				background-color: black;
				display: none;
			}

			#resultsList button {
				padding: 0.25em 0.5em;
			}

			#resultsList li.expanded .blackout {
				opacity: 0.2;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)"; /* IE8 */
				display: block;
			}

			#resultsList .resultPage li.searchResult .titleBar {
				/*
				height: 22px;
				font-size: 16px;
				font-weight: bold;
				*/
				background-color: #9ea5a5;
				line-height: 21px;

				height: 29px;
				font-size: 20px;
				/* font-family: Georgia; */
				font-family: "Georgia W01";
				font-style: italic;
				padding: 0.25em;
			}

				#resultsList .resultPage li.searchResult.selected .titleBar {
					background-color: #444;
					color: white;
				}

				#resultsList .resultPage li.searchResult .titleBar > input[type=checkbox],
				#resultsList .resultPage li.searchResult .titleBar > label {
					vertical-align: middle;
					line-height: 22px;
				}

				#resultsList .resultPage li.searchResult .titleBar .addToBin { margin-right: 12px; }
				#resultsList .resultPage li.searchResult .titleBar .addToBin:active { margin-right: 9px; }

				#resultsList .resultPage li.searchResult .titleBar > * {
					vertical-align: middle;
				}

			#resultsList .resultPage li.searchResult div.thumb {
				width: 160px;
				/* height: 124px; */
				margin: 8px;
				float: left;
				position: relative;
				font-size: 14px;
			}

				#resultsList .resultPage li.searchResult div.thumb img,
				#resultsList .resultPage li.searchResult div.thumb div.img {
					width: 160px;
					height: 120px;
					display: block;
					cursor: pointer;
				}

					#resultsList .resultPage li.searchResult.aspect5625 div.thumb img,
					#resultsList .resultPage li.searchResult.aspect5625 div.thumb div.img { height: 90px; }
					#resultsList .resultPage li.searchResult.aspect6666 div.thumb img,
					#resultsList .resultPage li.searchResult.aspect6666 div.thumb div.img { height: 107px; }
					#resultsList .resultPage li.searchResult.aspect6864 div.thumb img,
					#resultsList .resultPage li.searchResult.aspect6864 div.thumb div.img { height: 110px; }
					#resultsList .resultPage li.searchResult.aspect7272 div.thumb img,
					#resultsList .resultPage li.searchResult.aspect7272 div.thumb div.img { height: 116px; }

				#resultsList .resultPage li.searchResult div.thumb div.positionBar {
					/*
					position: absolute;
					bottom: 0;
					left: 0;
					*/
					position: relative;
				}

			#resultsList .resultPage li.searchResult em {
				background-color: #dbb865;
				font-style: normal;
			}

			#resultsList .resultPage li.searchResult h4 {
				/*
				font-size: 12px;
				font-style: italic;
				*/
				font-weight: bold;
				margin: 3px 8px;
			}

				#resultsList .resultPage li.searchResult h4 em { font-style: italic; }

			#resultsList .resultPage li.searchResult p {
				font-size: 14px;
				margin: 3px 8px;
				/* disable mobile text inflation algorithms */
				-moz-text-size-adjust: 100%;
				-webkit-text-size-adjust: 100%;
				-ms-text-size-adjust: 100%;
			}

				#resultsList .resultPage li.searchResult p.description {
					background-color: #d1d8d8;
					padding: 1px 2px;
					/* max-height: 85px; */
					max-height: 200px;
					overflow-x: hidden;
					overflow-y: auto;
					margin-bottom: 0.5em; /* now that keywords are hidden */
					line-height: 1.2em;
				}

				#resultsList .resultPage li.searchResult p.noVideo {
					background-color: #d9dddd;
					padding: 1px 9px;
					/* margin: 0 0 5px; */
					font-style: italic;
					text-align: center;
				}

				#resultsList .resultPage li.searchResult p.keywords {
				}

					#resultsList .resultPage li.searchResult p.keywords em { font-style: italic; }

			#resultsList .resultPage li.searchResult > .sceneMatches .pullTab {
				position: absolute;
				bottom: -11px;
				width: 96px;
				margin-left: -48px;
				height: 11px;
				left: 50%;
				z-index: 10;
				background-position: center top;
				background-repeat: no-repeat;
				cursor: pointer;
				background-image: url('logPullTab-open.png');
			}

				#resultsList .resultPage li.searchResult.expanded > .sceneMatches .pullTab { background-image: url('logPullTab-close.png'); }
				#resultsList .resultPage li.searchResult.collapsed > .sceneMatches .pullTab { background-image: url('logPullTab-open.png'); }


			#resultsList .sceneMatches {
				width: 46%;
				/*
				top: 0;
				bottom: 0;
				right: 0;
				position: absolute;
				*/
				z-index: 1;
				display: inline-block;
				position: relative;
				vertical-align: top;
			}

				#resultsList li.expanded { z-index: 99; }

				#resultsList li.expanded .sceneMatches {
					-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					z-index: 105;
				}

				#resultsList .sceneMatches .titleBar {
					color: #3c3f3f;
					background-color: #969d9d !important;
					font-style: italic;
					text-align: center;
					font-size: 13px;
				}

					#resultsList li.selected .sceneMatches .titleBar {
						background-color: #333;
					}

					#resultsList .sceneMatches .titleBar a {
						text-decoration: underline;
						color: black;
					}
						#resultsList li.selected .sceneMatches .titleBar a,
						#resultsList .sceneMatches .titleBar a:hover { color: white; }

				#resultsList .sceneMatches > ul {
					list-style-type: none;
					margin: 0;
					overflow-x: hidden;
					overflow-y: auto;
					width: 100%;
					/*
					position: absolute;
					top: 22px;
					bottom: 0;
					padding: 5px 0;
					*/
					padding: 0;
					position: relative;
					background-color: #bac3c3;
				}

					#resultsList li.expanded .sceneMatches > ul { overflow-y: scroll; }

					#resultsList .sceneMatches > ul > li.logEntry {
						margin: 8px;
						font-size: 14px;
						position: relative;
						/*
						padding: 3px 5px 3px 63px;
						min-height: 49px;
						*/
						padding: 3px 5px 3px 79px;
						min-height: 61px;
						cursor: pointer;
						background-color: #d2dada;
						max-height: 999999px;

						/* disable mobile text inflation algorithms */
						-moz-text-size-adjust: 100%;
						-webkit-text-size-adjust: 100%;
						-ms-text-size-adjust: 100%;
					}

						#resultsList .sceneMatches > ul > li.logEntry.logMusic {
							/* padding-left: 80px;
							background-position: 62px 6px; */
							padding-left: 96px;
							background-image: url('logMusic.png');
							background-repeat: no-repeat;
							background-position: 78px 6px;
							font-style: italic;
						}

							#resultsList .sceneMatches > ul > li.logEntry.logMusic em { font-style: italic; }

						#resultsList .sceneMatches > ul > li.logEntry.odd, /* for IE<9 */
						#resultsList .sceneMatches > ul > li.logEntry:nth-child(2n) { /* background-color: #cad5d5; */ }

						#resultsList .sceneMatches > ul > li.logEntry:hover { background-color: #969d9d; }

						#resultsList .sceneMatches > ul > li.logEntry.selected {
							background-color: #737575;
							color: white;
						}

							#resultsList .sceneMatches > ul > li.logEntry.selected em { background-color: #b6913e; }

						#resultsList .sceneMatches > ul > li.logEntry input[type=checkbox] {
							vertical-align: middle;
							position: absolute;
							left: 3px;
							top: 12px;
						}

						#resultsList .sceneMatches > ul > li.logEntry div.thumb {
							position: absolute;
							left: 8px;
							top: 5px;
							/*
							width: 48px;
							padding-top: 36px;
							*/
							width: 64px;
							padding-top: 48px;
							height: 13px;
							/*
							background-image: url('defaultThumb_mini.png');
							background-repeat: no-repeat;
							background-position: top left;
							*/
							margin: 0 5px 2px 0;
							line-height: 9px;
							cursor: pointer;
							vertical-align: middle;
							font-style: normal;
							text-align: center;
						}
							#resultsList .aspect5625 .sceneMatches > ul > li.logEntry div.thumb { padding-top: 36px; }
							#resultsList .aspect6666 .sceneMatches > ul > li.logEntry div.thumb { padding-top: 43px; }
							#resultsList .aspect6864 .sceneMatches > ul > li.logEntry div.thumb { padding-top: 44px; }
							#resultsList .aspect7272 .sceneMatches > ul > li.logEntry div.thumb { padding-top: 47px; }

							#resultsList .sceneMatches > ul > li.logEntry div.thumb .label {
								/* font-family: Consolas, monospace; */
								font-family: "Consolas W01 Mono",monospace;
								font-size: 11px;
								font-weight: normal;
								margin: 2px 0 0;
								display: block;
								width: 100%;
								white-space: nowrap;
								overflow: hidden;
							}

							#resultsList .sceneMatches > ul > li.logEntry div.thumb img {
								position: absolute;
								top: 0;
								left: 0;
								/*
								width: 48px;
								height: 36px;
								*/
								width: 64px;
								height: auto !important;

								border-radius: 5px;
							}

								/*
								#resultsList .aspect5625 .sceneMatches > ul > li.logEntry div.thumb img { height: 27px; }
								#resultsList .aspect6666 .sceneMatches > ul > li.logEntry div.thumb img { height: 32px; }
								#resultsList .aspect6864 .sceneMatches > ul > li.logEntry div.thumb img { height: 33px; }
								#resultsList .aspect7272 .sceneMatches > ul > li.logEntry div.thumb img { height: 35px; }
								*/

							#resultsList .sceneMatches > ul > li.logEntry .addToBin {
								float: right;
								font-size: 14px;
								font-weight: normal;
								line-height: 1em;
								margin-right: 5px;
								margin-top: 5px;
								margin-left: 8px;
							}

								#resultsList .sceneMatches > ul > li.logEntry .addToBin:active {
									margin-top: 8px;
									margin-right: 2px;
									margin-left: 11px;
								}

					#resultsList .sceneMatches ul > li.hidden { display: none; }

						#resultsList .sceneMatches.showAll ul > li.hidden { display: block; }

					#resultsList .sceneMatches ul > li.moreOrLess {
						text-align: center;
						padding-bottom: 9px;
					}

						/*
						#resultsList .sceneMatches button.showAll { margin-left: 8px; }
							#resultsList .sceneMatches button.showAll:active { margin-left: 11px; }
						*/

						#resultsList .sceneMatches button.showAll:before { content: 'Show All'; }

							#resultsList .sceneMatches.showAll button.showAll:before {	content: 'Show Less'; }

				@media screen and (max-width: 639px) {

					/* move scene matches below details */
					#resultsList .resultPage li.searchResult.hasSceneMatches .reelDetails,
					#resultsList .sceneMatches {
						width: auto;
						display: block;
					}

					#resultsList .sceneMatches .titleBar {
						background-color: #bac3c3 !important;
						padding-top: 0.5em !important;
						height: auto !important;
					}

					#resultsList .resultPage li.searchResult p.keywords { margin-bottom: 8px; }

				}



				@media screen and (max-width: 480px) {
					#resultsList .resultPage li.searchResult p,
					#resultsList .sceneMatches > ul > li.logEntry { font-size: 18px; }

					#resultsList .resultPage li.searchResult p.keywords {
						margin-top: 8px;
						line-height: 1.5em;
					}

					#resultsList .resultPage li.resultPageHeader span.jumpTo { font-size: 20px; }
					#resultsList .resultPage li.resultPageHeader span.jumpTo input { font-size: 24px; }
				}

/*** ORDER PAGE ***/

.viewOrder {
}

	.viewOrder #orderDescription {
		font-style: italic;
		margin: 1em 0;
	}

	table.clientInfo {
		border-collapse: collapse;
		margin: 10px auto 25px auto;
		line-height: 1.5;
	}

		table.clientInfo th {
			font-weight: bold;
		}

		table.clientInfo th,
		table.clientInfo td {
			text-align: left;
			padding: 0 1em;
			vertical-align: top;
		}

	table.invoice {
		border-collapse: collapse;
		margin: 2em auto;
		width: 75%;
		background-color: var(--hf-panel-bg-color);
		box-shadow: var(--hf-panel-shadow);
		border-radius: var(--hf-border-radius);
		overflow: hidden;
	}	

		@media (max-width: 639px) {
			table.invoice { width: 100%; }
		}

		table.invoice tr:hover {
			background-color: #b4baba; /* for non-rgba support */
			background-color: rgba(0,0,0,0.1);
		}

		table.invoice thead th {
			font-weight: normal;
			background-color: var(--hf-accent-color);
			color: var(--hf-strong-color);
		}

		table.invoice tfoot td {
			text-transform: uppercase;
			background-color: var(--hf-hover-color);
		}
			table.invoice thead tr:hover,
			table.invoice tfoot tr:hover { color: inherit; }

		table.invoice col.item { width: auto; }
		table.invoice col.quantity { width: 35px; }
		table.invoice col.rate { width: 90px; }
		table.invoice col.amount { width: 90px; }

		table.invoice th,
		table.invoice td {
			cursor: default !important;
			padding: 0.5em;
			line-height: normal;
			border-bottom: 1px solid #00000080;
		}

		table.invoice th,
		table.invoice td { text-align: right; }

		table.invoice th:first-child,
		table.invoice td:first-child { text-align: left; }

		table.invoice th:first-child + th,
		table.invoice td:first-child + td { text-align: center; }

		table.invoice td:first-child + td + td + td { color: var(--hf-strong-color); }

		table.invoice tfoot td:first-child + td + td + td { text-decoration: underline; }

	.viewOrder #paymentInfo {
		margin-bottom: 1em;
		color: inherit;
	}

	.viewOrder #licenseAgreements {
		margin-bottom: 2.5em;
		line-height: 2em;
		color: var(--hf-accent-color-text);
	}

		.viewOrder #licenseAgreements a { 
			color: var(--hf-highlight-color); 
			text-decoration: none;
		}

		.viewOrder #licenseAgreements a:hover { 
			color: var(--hf-strong-color); 
			text-decoration: underline; 
		}



/*** BIN PAGE ***/

.manageBin {
}

	.manageBin.writableBin .readOnlyInfo {
		display: none;
	}

	.manageBin h2.instructions {
		margin: var(--hf-standard-margin);
		font-weight: 500;
		color: var(--hf-text-color);
	}

		.manageBin h2.instructions a {
			color: var(--hf-link-color);
		}	

		.manageBin h2.instructions a:hover {
			color: var(--hf-strong-color);
			text-decoration: underline;
		}

		.contactResearcherBinPage { white-space: nowrap; }

		/*
		@media (max-width: 799px) {
			.manageBin h2.instructions {
				clear: right;
				margin: 1em 0 0;
			}
		}
		*/

		@media (max-width: 639px) {
			.manageBin .orderBin {
				float: right;
				clear: right;
			}
		}

	.manageBin .binInfo {
		display: grid;
		grid-template-columns: auto min-content;
	}

	.manageBin .attribution {
		font-style: italic;
		grid-row: 2;
		grid-column: 1;
	}
	.manageBin #binDescription { 
		padding: 10px 20px; 
		border: 1px solid transparent;
		border-radius: var(--hf-border-radius);
		overflow: auto;
	}
	.manageBin #eipContainer-binDescription {
		grid-template-columns: auto min-content;
	}
	.manageBin #binDescription,
	.manageBin #eipContainer-binDescription {
		margin: 1em 0;
	}
	.manageBin #binDescription,
	.manageBin #eipContainer-binDescription textarea {
		font-style: italic;
		font-weight: 300;
		line-height: 1.25em;
		box-sizing: border-box;
		height: calc(3.75em + 22px); /* 3 lines + padding and borders */
		min-height: calc(3.75em + 22px);
	}
	.manageBin #binDescription.editable {
		height: 5.125em; 
		min-height: 5.125em;
	}
	.manageBin #binDescription .empty { color: var(--hf-faint-text-color); }
	.manageBin .editable {
		border: 1px solid transparent;
	}
	.manageBin .editable:hover {
		box-shadow: var(--hf-input-field-shadow);
		background-color: var(--hf-input-hover-bg-color);
		color: var(--hf-input-hover-color);
		cursor: text;
		border-radius: var(--hf-border-radius);
	}
	.manageBin #editObjbinTitle {
		font-size: 24px;
		font-weight: 500;
		padding: 8px 20px;
	}
	.manageBin #eipContainer-binTitle {
		margin-bottom: 0.75em;
	}	

	.toolbar .bigTools::before {
		content: 'This bin:';
		line-height: var(--hf-button-min-height);
		margin-right: 1em;
		color: var(--hf-faint-text-color);
	}	

	@media screen and (max-width: 1150px) {
		.toolbar .bigTools {
			display: flex;
			flex-wrap: wrap;
			gap: 15px 0;
			justify-content: end;
			min-width: 320px;
			align-content: start;
			align-items: start;
		}
		.manageBin .binInfo {
			grid-template-columns: auto auto;
		}
	}

	@media screen and (max-width: 960px) {
		.toolbar .bigTools::before {
			line-height: 40px;
		}		
		#mainArea .bigTools a.button {
			min-height: 40px;
		}		
		.manageBin .binInfo { grid-template-columns: auto; }
		.manageBin .toolbar .bigTools { 
			grid-row: 1; 
			margin-bottom: calc(var(--hf-standard-margin) / 2);
			margin-left: 0;
			min-width: unset;
		}
		.manageBin .toolbar h1 { 
			grid-row: 2; 
			margin-top: 0;
		}
		.manageBin .attribution { grid-row: 3; }
	}


	@media screen and (max-width: 632px) {
		.manageBin .toolbar h1 { 
			margin-left: 0;
			padding: 8px;
		}
		.manageBin .attribution { margin: 0 8px; }
	}


	#binContents {
		list-style-type: none;
		padding: 0;
		/* overflow: auto; */
		display: flex;
		gap: calc(var(--hf-standard-margin) / 1.5);
		flex-wrap: wrap;
	}

		#binContents li.noClips{
			font-style: italic;
			color: #535352;
			text-align: center;
			width: 100%;
		}

		li.clipDetails {
			display: inline-block;
			vertical-align: top;
			flex-basis: min-content;
			position: relative;
		}

			li.clipDetails.ui-sortable-placeholder { 
				outline: 2px dashed var(--hf-text-color); 
				visibility: visible !important; 
			}

			/* li.clipDetails:hover { box-shadow: var(--hf-thumbnail-shadow); } */

			.reelPage li.clipDetails { width: 100%; }

			li.clipDetails .clipDetailsContents {
				position: relative;
				background-color: var(--hf-darker-background-color);
				border-radius: var(--hf-border-radius);
			}

				@media (max-width: 799px) {

					li.clipDetails {
						width: 100%;
					}

				}

				li.clipDetails .info {
					/*
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					z-index: 2;
					display: none;
					*/

					/*
					padding: 3px;
					max-height: 178px;
					overflow-y: auto;
					overflow-x: hidden;
					background-color: #dde3e3;
					font-size: 12px;
					-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
					*/

					height: 155px;
					overflow-y: auto;
					padding: calc(var(--hf-standard-margin) / 1.5) calc(var(--hf-standard-margin) / 1.5) 0;
					margin-bottom: calc(var(--hf-standard-margin) / 1.5);
					font-size: 14px;
					/*! color: var(--hf-faint-text-color); */
				}

					

					li.clipDetails .hasClipFlag .info { max-height: 137px; }

					li.clipDetails .info .content { /*! padding: calc(var(--hf-standard-margin) / 1.5) calc(var(--hf-standard-margin) / 1.5) 0; */ /*! margin-bottom: calc(var(--hf-standard-margin) / 1.5); */}

					li.clipDetails .info h4 {
						/*! font-weight: bold; */
						margin: 0;
					}

					li.clipDetails .info p {
						/*! margin: 3px 8px 3px 0; */
						/*! font-size: 14px; */
					}

						li.clipDetails .info p.description {
							/*! background-color: #d1d8d8; */
							/*! padding: 1px 2px; */
							/*! overflow-y: auto; */
							color: var(--hf-faint-text-color);
							margin-bottom: 0;
						}

				li.clipDetails .controls {
					position: absolute;
					top: -22px;
					height: 22px;
					left: 0;
					right: 0;
					overflow: hidden;
					background-color: #c6d1d1;
					font-size: 12px;
					vertical-align: middle;
					line-height: 21px;
					display: none;
				}



					li.clipDetails:hover .controls,
					#binContents.deleteMode > li.clipDetails .controls {
						display: block !important;
					}

					li.clipDetails .controls * {
						vertical-align: middle;
						cursor: pointer;
					}

					li.clipDetails .controls span {
						display: inline-block;
						background-color: #dde3e3;
						padding: 0 3px;
						float: left;
						height: 100%;
					}

					li.clipDetails .controls img {
						position: absolute;
						top: 3px;
						right: 3px;
					}

				li.clipDetails .titleBar {
					white-space: nowrap;
					cursor: pointer;
					font-weight: 500;
					padding: 5px 10px 10px;
				}				

					.reelPage li.clipDetails .titleBar { padding-left: 5px; }
					.reelPage li.clipDetails .titleBar:hover { background-color: #9ea5a5; }

					li.clipDetails.selected .titleBar {
						background-color: var(--hf-inner-panel-bg-color);
						color: var(--hf-strong-color);
					}

					li.clipDetails .handle {
						display: inline-flex;
						justify-content: center;
						width: 1em;
						height: 100%;
						cursor: move;
						align-items: center;
						vertical-align: middle;
						color: var(--hf-input-divider-color);
						transition: var(--hf-standard-transition);
					}

						li.clipDetails:hover .handle { color: var(--hf-faint-text-color); }

						li.clipDetails .handleTouchOverlay:hover + .handle { color: var(--hf-text-color); }

						li.clipDetails .handle::before {
							content: '\f58e';
							font-family: var(--hf-icon-font);
							font-size: 20px;
							font-weight: 900;
						}

						li.clipDetails .handle:hover {
							color: var(--hf-strong-color);
						}


					li.clipDetails .titleBar > input[type=checkbox] {
						vertical-align: middle;
						cursor: pointer;
						margin: 0 0.75em 0 0;
						border-color: var(--hf-darker-background-color);
					}

						li.clipDetails .titleBar > .handle ~ input[type=checkbox] {
							margin: 0 0.75em;
						}

					li.clipDetails .titleBar .jquery-tick-wrapper {
						margin: 5px 8px;
						background-color: #d2dada;

						float: none;
						display: inline-block;
						line-height: 21px;
						vertical-align: middle;
						position:relative;
						z-index:1;
					}

					li.clipDetails .titleBar > label {
						vertical-align: middle;
						line-height: 21px;
						cursor: pointer;
						max-width: 85px;
						display: inline-block;
						overflow: hidden;
						height: 22px;
					}

					li.clipDetails .titleBar .close {
						position: relative;
						float: right;
						font-size: 16px;
						margin: 6px 4px;
						box-shadow: none !important;
						color: var(--hf-input-divider-color);
					}
						li.clipDetails:hover .titleBar .close {
							color: var(--hf-faint-text-color);
						}

						li.clipDetails .titleBar .close:hover {
							color: var(--hf-text-color);
						}

							li.clipDetails .titleBar .close::after {
								content: '\f057';
								font-size: 1em;
							}

					li.clipDetails .tapeId {
						vertical-align: middle;
					}

					li.clipDetails .times {
						white-space: nowrap;
						font-size: 14px;
						text-align: right;
						color: var(--hf-faint-text-color);
					}					

						li.clipDetails .times .totalTime {
							color: var(--hf-text-color);
						}

				li.clipDetails div.thumb {
					position: relative;
					cursor: pointer;
					overflow: hidden;
					min-height: 256px;
					aspect-ratio: 4 / 3;
					background-color: var(--hf-input-hover-bg-color);
				}

					li.clipDetails:hover div.thumb { box-shadow: var(--hf-thumbnail-shadow); }

					li.clipDetails div.thumb img, 
					li.clipDetails div.thumb div.img {
						width: 100%;
						height: 256px;
						aspect-ratio: 4 / 3;
						display: block;
						background-size: 100%;
						transform: scale(var(--hf-overscan-amount));
						object-fit: cover;
					}					
/*

				li.clipDetails.aspect5625 div.thumb { height: 111px; }
					li.clipDetails.aspect5625 div.thumb img,
					li.clipDetails.aspect5625 div.thumb div.img { height: 90px; }

				li.clipDetails.aspect6666 div.thumb { height: 128px; }
					li.clipDetails.aspect6666 div.thumb img,
					li.clipDetails.aspect6666 div.thumb div.img { height: 107px; }

				li.clipDetails.aspect6864 div.thumb { height: 131px; }
					li.clipDetails.aspect6864 div.thumb img,
					li.clipDetails.aspect6864 div.thumb div.img { height: 110px; }

				li.clipDetails.aspect7272 div.thumb { height: 137px; }
					li.clipDetails.aspect7272 div.thumb img,
					li.clipDetails.aspect7272 div.thumb div.img { height: 116px; }

*/
					li.clipDetails div.thumb span.specialty {
						bottom: 26px;
					}

					li.clipDetails div.thumb div.positionBar {
						position: relative;
					}

				li.clipDetails div.positionBar {
					height: 30px;
					margin-top: -30px;
					background-color: rgba(0,0,0,0.33);
				}

				li.clipDetails .clipFlag {
					position: absolute;
					text-align: center;
					font-style: italic;
					font-size: 14px;
					box-sizing: border-box;
					border-radius: var(--hf-border-radius);
					background-color: var(--hf-panel-bg-color);
					color: var(--hf-link-color);
					display: block;
					left: 40%;
					line-height: 1.125;
					margin-top: -0.5em;
					padding: 0.25em;
					right: 0.75em;
					box-shadow: var(--hf-panel-shadow);
				}

					#mainArea:not(.writeable) li.clipDetails .clipFlag { top: 100%; }

					li.clipDetails:has(.clipFlag) { padding-bottom: 2.5em; }


				li.clipDetails .commentContainer {
					background-color: var(--hf-background-color);
					border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius);
					margin: 0;
					cursor: text;
					padding: 0 2px;
				}

					#mainArea:not(.writeableBin) li.clipDetails .commentContainer.empty { display: none; }


					li.clipDetails span.comment {
						color: var(--hf-text-color);
						padding: calc(var(--hf-standard-margin) / 3) calc(var(--hf-standard-margin) / 1.5);
						/* text-align: center; */
						height: 2.375em;
						line-height: 1.125em;
						overflow: hidden;
						display: block;
						border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius) !important;
						font-weight: 300;
					}

					li.clipDetails .comment { /* includes its EIP input */
						font-style: italic;
						line-height: 1.125em;
					}

						/*
						li.clipDetails:hover .comment {
							background-color: #c6d1d1;
						}
						*/

						li.clipDetails .comment.empty span {
							visibility: hidden;
						}

							li.clipDetails:hover .comment.empty span {
								visibility: visible;
							}

						li.clipDetails .eip_container textarea {
							font-style: italic;
							border-top-left-radius: 0;
							line-height: 1.125em;
						}

				li.clipDetails .editComment {
					font-size: 14px;
					font-weight: normal;
					line-height: 1em;
					margin-bottom: 0.5em;
					float: left;
				}

	
	@media screen and (max-width: 640px) {

		li.clipDetails .clipDetailsContents {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		li.clipDetails .titleBar {
			grid-row: 1;
			grid-column: 1 / 3;
			position: relative;
		}

		li.clipDetails .times {
			position: absolute;
			top: 0;
			right: 42px;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 5px 10px 10px;
			box-sizing: border-box;
			left: 30%;
			justify-content: center;
		}

		li.clipDetails .info {
		}

		#binContents .result .thumb {
			grid-row: 2;
			grid-column: 1;
		}

		#binContents .result .info {
			grid-row: 2;
			grid-column: 2;
			/* height: unset; */
		}

		#binContents .result .commentContainer {
			grid-row: 3;
			grid-column: 1 / 3;
		}

		#binContents .result {
			flex-basis: 100%;
			aspect-ratio: unset;
		}

	}





/** VIDEO PLAYER **/

.videoPlayer {
	width: 856px;
	/* height: 470px; */
	margin: auto;
	background-color: #ced7d7;
	padding: 0;
	position: relative;
}

.videoPlayer.standalone {
	-moz-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
	-webkit-box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
	box-shadow: 2px 2px 0 rgba(0,0,0,0.17);
	/* margin-top: 40px !important; */
}


	.videoPlayer .titleBar {
		font-size: 14px;
		padding: 4px 8px;
		margin: 5px 0;
		background-color: #b9c1c1;

		overflow: auto; /* float clearing, in case of an empty length field */

		/* disable mobile text inflation algorithms */
		-moz-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	}

		.videoPlayer h1 {
			display: inline-block;
			-webkit-box-sizing: border-box; /* for Android 2 */
			box-sizing: border-box;
			max-width: 100%;
			overflow: hidden;
			vertical-align: middle;
			font-size: 26px;
			line-height: 35px;
			padding: 0 15px;
			margin: 0;
		}

			.videoPlayer.standalone h1 {
				display: block;
				margin-top: 0.25em;
			}

		.videoPlayer .titleBar .timecodes { display: none; }

		.videoPlayer .titleBar .note {
			font-style: italic;
			margin-left: 15px;
			float: right;
		}

		.videoPlayer .titleBar #eipContainer-clipTitle {
			display: inline-block;
			/* top:-5px; */
			width: 350px;
			margin-left: 15px;
			float: right;
		}

			.videoPlayer .titleBar #eipContainer-clipTitle .eip_textbox {
				margin: 0;
				padding: 0 5px;
				text-align: right;
				font-size: 14px;
				border: none;
			}

			.videoPlayer .titleBar #eipContainer-clipTitle .eip_buttons {
				top: -8px;
			}

		.videoPlayer .titleBar .save {
			position: absolute !important;
			top: 5px;
			right: 48px;
		}

	.player-container button {
		border-radius: 0;
		box-shadow: none;
		font-family: inherit;
		font-weight: inherit;
		text-shadow: none;
		text-transform: inherit;
	}

	.videoPlayer .matches {
		font-style: italic;
		/* font-family: Georgia; */
		font-family: "Georgia W01";
		text-align: center;
		position: relative;
	}

		.videoPlayer .matches .matchInfo {
			display: block;
			font-size: 16px;
			font-size: 24px;
			padding: 7px;
			margin: 0 40px;
			min-height: 1.125em;
		}

			.videoPlayer .matches .matchInfo .searchText::before { content: '\0a0"'; }
			.videoPlayer .matches .matchInfo .searchText::after { content: '"'; }

		/*
		.videoPlayer .matches span {
			position: absolute;
			left: 35px;
			right: 35px;
		}
		*/

		.videoPlayer .matches em { font-style: italic; }

/*
		.videoPlayer .matches .navBtn {
			cursor: pointer;
			height: 2em;
			/* max-width: 6.5em; /* for "clip/reel", instead of "clip" *//*
			max-width: 5em;
			padding: 0.25em 0.5em;
			/* font-family: Calibri,Verdana,Arial,sans-serif; *//*
			font-family: "Calibri W01",sans-serif;
			font-style: normal;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1em;
			font-size: 14px;
			color: #787d7d;
		}

			.videoPlayer .matches .navBtn.disabled {
				opacity: 0.5;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE8 *//*
				cursor: default;
			}
		.videoPlayer .matches .tri {
			width: 0;
			height: 0;
			border-top: 0.5em solid transparent;
			border-bottom: 0.5em solid transparent;
			margin: 0.5em 0;
			vertical-align: middle;
		}

		.videoPlayer .matches .prev {
			float: left;
			text-align: left;
		}

			.videoPlayer .matches .prev .tri {
				border-right: 1em solid #787d7d;
				float: left;
				margin-right: 0.625em;
			}

				.videoPlayer .matches .prev .tri + .tri { margin-left: -1.125em; }

		.videoPlayer .matches .next {
			float: right;
			text-align: right;
		}

			.videoPlayer .matches .next .tri {
				border-left: 1em solid #787d7d;
				float: right;
				margin-left: 0.625em;
			}

				.videoPlayer .matches .next .tri + .tri { margin-right: -1.125em; }
*/

		.videoPlayer .matches .navBtn:hover:not(.disabled) { color: black; }
		.videoPlayer .matches .prev:hover:not(.disabled) .tri { border-right-color: black; }
		.videoPlayer .matches .next:hover:not(.disabled) .tri { border-left-color: black; }


	.videoPlayer .playerArea {
		position: relative;
	}

		.videoPlayer .player {
			width: 480px;
			height: 512px;
			/* position: absolute; */
			top: 0;
			margin-left: 12px !important;
			margin-top: 12px !important;
			margin-bottom: 5px !important;
			padding: 0;
		}

		.videoPlayer .info {
			display: block;
			margin: 12px 7px 0 12px;
		}

		.videoPlayer .player + .info {
			position: absolute;
			left: 504px;
			right: 7px;
			top: 0;
			margin: 0;
		}

			.videoPlayer .noTabs .player + .info { display: none; }		

			@media screen and (max-width: 960px) {
				.videoPlayer:not(.no-video) .info { margin: 0; }
				.videoPlayer .noTabs .player + .info { display: block; }		
			}

		.videoPlayer .tabs {
			font-size: 14px;

			/* font-family: Calibri,Verdana,Arial,sans-serif; */
			font-family: "Calibri W01",sans-serif;
			font-style: normal;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 0.8em;
			font-size: 16px;
			white-space: nowrap;

			min-height: 2.25em; /* for when tabs are absolutely positioned */
		}

			.videoPlayer .tabs a {
				display: inline-block;
				background-color: #eceeee;
				padding: 0.25em 0.5em 0.375em;
				text-decoration: none;
				margin: 0 0.3125em 0 0;
				position: absolute;
				cursor: default;
				color: #363736;
				text-align: center;
				min-height: 1.625em; /* to equal .tabs height regardless of how the typography renders */
			}

				.videoPlayer .tabs a:last-child { margin-right: 0; }

				/*
				.videoPlayer .tabs a.thumbnails ~ a.log { left: 625px; }
				.videoPlayer .tabs a.thumbnails ~ a.details { left: 80px; }
				.videoPlayer .tabs a.thumbnails { left: 350px; }

				.videoPlayer .tabs a.log { left: 575px; }
				.videoPlayer .tabs a.details { left: 150px; }
				*/

				.videoPlayer .tabs a.video { display: none; }
				.videoPlayer .player + .info .tabs a.video { display: flex; }

				.videoPlayer .tabs a.log {
					left: 75%;
					margin-left: -3.15625em;
				}

				.videoPlayer .tabs a.details {
					left: 25%;
					margin-left: -2.5em;
				}

				.videoPlayer .player + .info .tabs a {
					position: relative;
					left: auto !important;
					background-color: #c3cccc;
					cursor: pointer;
					margin-left: 0;
					text-align: left;
				}

				.videoPlayer .player + .info .tabs a:hover { background-color: #d7dddd !important; }
				.videoPlayer .player + .info .tabs a.active { background-color: #eceeee !important; }

				.videoPlayer div.info.log .tabs a.log,
				.videoPlayer div.info.details .tabs a.details,
				.videoPlayer div.info.thumbnails .tabs a.thumbnails,
				.videoPlayer div.info.video .tabs a.video { background-color: #eceeee !important; }



.videoPlayer.has-thumbnails.no-video .info.thumbnails .tabPanel .log,
.videoPlayer.has-thumbnails.no-video .info.log .tabPanel .thumbnails {
	display: none;
}

.videoPlayer.has-thumbnails.no-video .info.thumbnails .tabPanel .thumbnails, {
	display: block;
}





				@media screen and (min-width: 960px) {

					.videoPlayer .tabs a.video { display: none; }
					.videoPlayer .player + .info .tabs a.video { display: none; }

				}

		.videoPlayer .tabPanel {
			margin: 0 5px 10px 0;
			position: relative;
			height: 359px;
			z-index: 2;
			background-color: #eceeee;
		}

			.videoPlayer .tabs + .tabPanel { margin-top: 0; }

			.videoPlayer .info .tabPanel .log,
			.videoPlayer .info .tabPanel .details,
			.videoPlayer .info .tabPanel .thumbnails,
			.videoPlayer .info .tabPanel .transcript {
				display: block;
			}

				.videoPlayer .player + .info .tabPanel .log,
				.videoPlayer .player + .info .tabPanel .details,
				.videoPlayer .player + .info .tabPanel .thumbnails,
				.videoPlayer .player + .info .tabPanel .transcript {
					display: none;
				}

					.videoPlayer div.info.log .tabPanel .log { display: block; }
					.videoPlayer div.info.details .tabPanel .details { display: block; }
					.videoPlayer div.info.thumbnails .tabPanel .thumbnails { display: block; }
					.videoPlayer div.info.transcript .tabPanel .transcript { display: block; }

			.videoPlayer .tabPanel .log {
				font-size: 12px;
				padding: 0 5px 5px;
				position: absolute;
				left: 50%;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
				text-align: center;
			}

				.videoPlayer .player + .info .tabPanel .log { left: 0; }
				.videoPlayer:not(.has-thumbnails) .tabPanel .thumbnails ~ .log { left: 505px; }

				.videoPlayer .tabPanel .log input[type=checkbox],
				.videoPlayer .tabPanel .log .checkbox {
					margin-right: 5px;
					vertical-align: middle;
				}

				.videoPlayer .tabPanel .log input[type=checkbox] { visibility: hidden; }

				.videoPlayer .tabPanel .log .checkbox {
					display: inline-block;
					width: 1.5em;
					position: relative;
					margin-top: 5px;
					margin-bottom: 5px;
				}

					.videoPlayer .tabPanel .log .checkbox label {
						cursor: pointer;
						position: absolute;
						width: 1.5em;
						height: 1.5em;
						top: 0;
						left: 0;
						background: #b9c1c1;
					}

						.videoPlayer .tabPanel .log .checkbox label:after {
							opacity: 0.3;
							-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)"; /* IE8 */
							content: '';
							position: absolute;
							width: 0.75em;
							height: 0.35em;
							background: transparent;
							top: 0.3em;
							left: 0.3em;
							border: 0.25em solid black;
							border-top: none;
							border-right: none;

							-webkit-transform: rotate(-45deg);
							-moz-transform: rotate(-45deg);
							-o-transform: rotate(-45deg);
							-ms-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}

							.videoPlayer .tabPanel .log .checkbox label:hover::after {
								opacity: 0.8;
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"; /* IE8 */
							}

							.videoPlayer .tabPanel .log .checkbox input[type=checkbox]:checked + label:after {
								opacity: 1;
								-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; /* IE8 */
							}

				.videoPlayer .tabPanel .log label {
					color: #444;
				}

				/*
				.videoPlayer .tabPanel .log #clipStart {
					left: -99px;
					width: 104px;
					margin-top: 4px;
				}

				.videoPlayer .tabPanel .log #clipEnd {
					right: -90px;
					width: 95px;
					margin-top: 4px;
				}
				*/


				.videoPlayer .markerContainer {
					height: 100%;
					position: absolute;
					top: 0;
					width: 115px;
					display: none;
					z-index: 2;
				}

					.videoPlayer .tabPanel .log .logMarker {
						position: absolute;
						background-color: #ebc600;
						padding: 0.5em;
						/*margin-top: 1.25em;*/
						text-transform: uppercase;
						line-height: 1em;
						font-size: 13px;
						font-weight: bold;
						box-shadow: 0 0.375em 0 rgba(0,0,0,0.25);
					}

						.videoPlayer .tabPanel .log .logMarker:hover {
							background-color: #f1d74c;
						}

					.videoPlayer .markerContainer#markIn {
						width: 104px;
						right: 100%;
						margin-right: -0.75em;
						width: 5em;
					}

					.videoPlayer .markerContainer#markOut {
						left: 100%;
						margin-left: -0.75em;
						width: 95px;
						width: 5em;
					}

					/* arrow & shadow */
					.videoPlayer .tabPanel .log .markerContainer a:before,
					.videoPlayer .tabPanel .log .markerContainer a:after {
						content: '';
						border-bottom: 2em solid transparent;
						border-top: 2em solid transparent;
						height: 0;
						position: absolute;
						width: 0;
						transition: var(--hf-standard-transition);
					}

						/* arrow */
						.videoPlayer .tabPanel .log .markerContainer a:before {
							top: 0;
							z-index: 2;
						}

						/* arrow shadow */
						.videoPlayer .tabPanel .log .markerContainer a:after {
							top: 0.375em;
							/* margin-left: 0.25em; */
							z-index: 1;
						}

					.videoPlayer .tabPanel .log .markerContainer #clipStart {
						/* right: 0.75em; */
						right: 0;
						text-align: right;
						border-radius: 0.5em 0 0 0.5em;
						padding-right: 0.25em;
						cursor:  pointer;
					}

						.videoPlayer .tabPanel .log .markerContainer #clipStart:before {
							border-left: 1em solid #ebc600;
							left: 100%;
						}

							.videoPlayer .tabPanel .log .markerContainer #clipStart:hover:before {
								border-left-color: #f1d74c;
							}

						.videoPlayer .tabPanel .log .markerContainer #clipStart:after {
							border-left: 1em solid rgba(0,0,0,0.25);
							left: 100%;
						}

					.videoPlayer .tabPanel .log  .markerContainer #clipEnd {
						/* left: 0.75em; */
						left: 0;
						text-align: left;
						border-radius: 0 0.5em 0.5em 0;
						padding-left: 0.25em;
						cursor: pointer;
					}

						.videoPlayer .tabPanel .log .markerContainer #clipEnd:before {
							border-right: 1em solid #ebc600;
							right: 100%;
						}

							.videoPlayer .tabPanel .log .markerContainer #clipEnd:hover:before {
								border-right-color: #f1d74c;
							}

						.videoPlayer .tabPanel .log .markerContainer #clipEnd:after {
							border-right: 1em solid rgba(0,0,0,0.25);
							right: 100%;
						}

					.videoPlayer .tabPanel .log #jumpToPoint {
						position: absolute;
						display: none;
						margin-top: -4em;
						top: 0;
						z-index: 10;
						left: 50%;
						width: 10em;
						margin-left: -5em;
						border-radius: var(--hf-border-radius) var(--hf-border-radius) 0 0;
						background-color: var(--hf-accent-color);
						padding: 0.5em;
						line-height: 1em;
						font-size: 16px;
						box-shadow: 4px 4px 6px #00000080;
						cursor: pointer;
						color: white;
						text-align: center;	
						transition: var(--hf-standard-transition);			
					}

						.videoPlayer .tabPanel .log #jumpToPoint:hover {
							background-color: var(--hf-highlight-color);
							color: black;
						}

						/* arrow & shadow */
						.videoPlayer .tabPanel .log #jumpToPoint:before,
						.videoPlayer .tabPanel .log #jumpToPoint:after {
							content: '';
							border-left: 5.5em solid transparent;
							border-right: 5.5em solid transparent;
							height: 0;
							position: absolute;
							width: 0;
							transition: var(--hf-standard-transition);	
						}

							/* arrow */
							.videoPlayer .tabPanel .log #jumpToPoint:before {
								left: 0;
								z-index: 2;
								border-top: 1.375em solid var(--hf-accent-color);
								top: 100%;
							}

								.videoPlayer .tabPanel .log #jumpToPoint:hover:before {
									border-top-color: var(--hf-highlight-color);
								}

							/* arrow shadow */
							.videoPlayer .tabPanel .log #jumpToPoint:after {
								z-index: 1;
								border-top: 1.375em solid rgba(0,0,0,0.25);
								margin-top: 0.375em;
								top: 100%;
								left: 0;
							}

					.videoPlayer div.textLog,
					.videoPlayer div.transcriptS {
						margin: 0;
						overflow-y: scroll;
						background-color: white;
						text-align: left;
						position: absolute;
						left: 1em;
						right: 1em;
						top: 5px;
						bottom: 1em;
					}
						.videoPlayer div.textLog .logContents .thumbnail{ 
							background-size: cover;
							display: inline-block;
							width: 80px;
							height: 60px;
						}
						
						.videoPlayer div.textLog:first-child { top: 0; }

					.videoPlayer input[type=checkbox] ~ div.textLog,
					.videoPlayer .checkbox ~ div.textLog { top: 28px; }

			.videoPlayer .tabPanel .details {
				font-size: 14px;
				position: absolute;
				top: 12px;
				bottom: 12px;
				left: 12px;
				right: 50%;
				overflow-y: auto;
				padding: 0;
				text-align: left;
			}

				.videoPlayer .tabPanel .details a:hover {
					text-decoration:underline;
					color:black;
				}

				.videoPlayer .player + .info .tabPanel .details {
					right: 12px;
					left: 12px;
				}
				.videoPlayer:not(.has-thumbnails) .tabPanel .thumbnails ~ .details {
					left: 12px;
					right: 547px;
				}

				.videoPlayer .tabPanel .details div.specialty,
				.videoPlayer .tabPanel .details div.noVideo,
				.videoPlayer .tabPanel .details div.temporailyUnavailable,
				.videoPlayer .tabPanel .details div.restricted {
					background-color: white;
					padding: 2px;
					text-align: center;
					margin-bottom: 5px;
					font-style: italic;
					border-radius: 8px;
				}

				.videoPlayer .tabPanel .details div.temporailyUnavailable {
					color:red;
				}

				.videoPlayer .tabPanel .details div.specialty img {
					margin-right: 5px;
					vertical-align: middle;
				}

				.videoPlayer .specialtyIcon {
					width: 24px;
					height: 24px;
				}

			.videoPlayer .tabPanel .thumbnails {
				font-size: 9px;
				position: absolute;
				top: 12px;
				bottom: 12px;
				right: 305px;
				left: 275px;
				overflow-y: auto;
				padding: 0;

				text-align: center;
			}

				.videoPlayer .player + .info .tabPanel .thumbnails { left: 12px; right: 12px; }


		.videoPlayer .clipTools {
			padding-top: 150px;
		}

		.videoPlayer .tools {
			font-size: 12px;
			color: #444;
			/* margin: 0 auto; */
			/* width: 301px; */
			text-align: right;

			display: none;
		}

			/* .videoPlayer .player + .info .tools { margin: 0 0 0 20px; } */

			.videoPlayer .tools .saveToBin {
				background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 35%, rgba(0,0,0,0.1) 100%); /* FF3.6+ */
				background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(35%,rgba(0,0,0,0.1)), color-stop(100%,rgba(0,0,0,0.1))); /* Chrome,Safari4+ */
				background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0.1) 100%); /* Chrome10+,Safari5.1+ */
				background: -o-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0.1) 100%); /* Opera11.10+ */
				background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0.1) 100%); /* IE10+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-9 */
				background: linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 35%,rgba(0,0,0,0.1) 100%); /* W3C */
				padding: 2px 8px 2px 0;
			}

				.videoPlayer .tools .saveToBin img {
					margin: 0 4px 2px 0;
					vertical-align: middle;
				}

			.videoPlayer .tools select {
				width: 150px;
				margin-bottom: 1px;
				vertical-align: middle;
			}

			.videoPlayer .tools a {
				display: inline-block;
				white-space: nowrap;
				color: black;
				vertical-align: middle;
				margin: 3px 15px 3px 0;
			}

				.videoPlayer .tools a:hover span { text-decoration: underline; }
				.videoPlayer .tools a.disabled:hover span { text-decoration: none; }

				.videoPlayer .tools a img {
					vertical-align: middle;
					margin: 0 4px 2px 0;
				}

				.videoPlayer .tools a.button {
					margin: 1px 0 -1px;
				}

				.videoPlayer .tools .emailClip {
						margin-right: 10px;
				}

				.videoPlayer .tools .fb-like {
					/*
					position:absolute;
					right:78px;
					*/
				}

				.videoPlayer .tools .fb-feed {
					    background: url("buttonFacebook.png") no-repeat scroll left center transparent;
						height: 16px;
						line-height: 16px;
						padding-left: 21px;
						margin-right: 10px;
				}


					.videoPlayer .tools .fb-like.loading,
					.videoPlayer .tools a.twitter-share-button.loading {
						background: url('loading_small.gif') top center no-repeat;
					}

				.videoPlayer .tools .twitter-share-button {
					width: 58px;
					vertical-align: middle;
					margin: 3px 13px 3px 0;
				}

		.videoPlayer .mobileTools {
			text-align: center;
			display: block;
			margin: 0 25px 0 15px;
			padding-top: 6px;
		}

			.videoPlayer .player ~ .mobileTools {
				position: absolute;
				display: table-row;
				left: 510px;
				top: 405px;
				right: 25px;
				margin: 0;
				padding-top: 0;
			}

			/*
			.videoPlayer .mobileTools .buttonWrapper {
				display: table-row;
				max-width: 75%;
			}
			*/

				.videoPlayer .player ~ .mobileTools .buttonWrapper { max-width: 100%; }

			.videoPlayer .mobileTools div.saveToBin,
			.videoPlayer .mobileTools .buttonWrapper > * { text-align: left; }

			.videoPlayer .mobileTools .buttonWrapper > a,
			.videoPlayer .mobileTools div.saveToBin {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 13px;
				line-height: 1em;
				/* max-width: 25%; */
				-webkit-box-sizing: border-box; /* for Android 2 */

				padding: 0 15px 0 41px;
				margin: 0 0 20px;
				vertical-align: middle;
				height: 38px;
			}

				@media screen and (min-width: 799px) {
					.videoPlayer .mobileTools .buttonWrapper > a:last-child { padding-right: 0; }
				}

			.videoPlayer .mobileTools .buttonWrapper > a,
			.videoPlayer .mobileTools div.saveToBin:after {
				box-sizing: border-box;
				background-size: 38px 38px;
				background-position: left top;
				background-repeat: no-repeat;
				opacity: 0.75;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)"; /* IE8 */
			}

				.videoPlayer .mobileTools .buttonWrapper > a.disabled {
					opacity: 0.35;
				}

			.videoPlayer .mobileTools div.saveToBin {
				/* display: inline-block; */ /* for centring, but breaks max-width support */
				boz-sizing: border-box;
				position: relative;
				color: rgba(0,0,0,0.8);
				padding-right: 41px;
				white-space: nowrap;

				float: right;
				margin-left: 15px;
				margin-top: 0;
				margin-bottom: 14px;
				max-width: 40%;
			}

				.videoPlayer .player ~ .mobileTools div.saveToBin {
					float: none;
					margin-left: 0;
					margin-top: 0.5em;
					margin-bottom: 20px;
					max-width: 100%;
				}

				.videoPlayer .mobileTools div.saveToBin:after {
					position: absolute;
					top: 0;
					left: 0;
					width: 38px;
					height: 38px;
					content: '';
				}

				.videoPlayer .mobileTools div.saveToBin select,
				.ui-dialog select {
					font-size: 16px;
					font-weight: bold;
					border: none;
					margin: 3px 3px 0 0;
					color: rgba(0,0,0,0.8);
					max-width: 100%;
					text-overflow: ellipsis;
				}

				.videoPlayer .mobileTools div.saveToBin a.addToBin {
					padding: 0.5em;
					font-size: 16px;
					background-color: #ebc600;
					/* box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25), 0 0 0.5em rgba(0, 0, 0, 0.1) inset; */
					box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.25);
					vertical-align: top;
					border-radius: 4px;
					text-shadow: 0 0 0.25em rgba(255, 255, 255, 0.5);
				}

					.videoPlayer .mobileTools div.saveToBin a.addToBin:hover {
						background-color: #f1d74c;
						box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.15);
					}

					.videoPlayer .mobileTools div.saveToBin a.addToBin:active {
						box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
						margin-top: 0.1875em;
						margin-left: 0.1875em;
					}

			.videoPlayer .mobileTools .divider { display: none; }

			.videoPlayer .mobileTools .buttonWrapper > a { display: table-cell; }

				.videoPlayer .mobileTools .buttonWrapper > a br { display: inline; }
				.videoPlayer .player ~ .mobileTools .buttonWrapper > a br { display: none; }

				.videoPlayer .mobileTools a:hover {
					opacity: 1.0;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; /* IE8 */
				}

				.videoPlayer .mobileTools .buttonWrapper > a.share					{ background-image: url('icon_share.png'); }
				.videoPlayer .mobileTools .buttonWrapper > a.downloadClip			{ background-image: url('icon_downloadClip.png'); }
				.videoPlayer .mobileTools .buttonWrapper > a.contactResearcherClip	{ background-image: url('icon_askUs.png'); }
				.videoPlayer .mobileTools .buttonWrapper > a.addToBin,
				.videoPlayer .mobileTools > div.saveToBin:after						{ background-image: url('icon_saveACopy.png'); }


				/*
				@media screen and (min-width: 926px) {
					.videoPlayer.standalone .close { display: none; }
				}
				*/

				/* TEMP - FOR CLIENT DEMO ONLY */
				/*.videoPlayer.standalone .close { display: none; }*/

				@media screen and (min-width: 799px) and (max-width: 925px) {
					.videoPlayer #markIn.markerContainer { margin-right: -1em; }
					.videoPlayer #markOut.markerContainer { margin-left: -4em; }
					.videoPlayer div.textLog .desc { padding-right: 2.25em; }


					/*
					.videoPlayer .mobileTools span.divider { display: table-row; height: 10px; }
					.videoPlayer #markIn.markerContainer { margin-right: -4.5em; }
					.videoPlayer #markOut.markerContainer { margin-left: -4.5em; }
					.videoPlayer div.textLog { left: 4em; right: 4em; bottom: 1em; }
					.videoPlayer div.textLog .desc { padding-right: 3.5em; }
					.videoPlayer .log.tabPane { text-align: center; }
					*/
				}

				/* change .mobileTools to 2x2 layout (if there's a player) */
				@media screen and (max-width: 845px) {
					.videoPlayer .player ~ .mobileTools .buttonWrapper { display: table; }
					.videoPlayer .player ~ .mobileTools div.saveToBin { display: none; }
					.videoPlayer .player ~ .mobileTools .buttonWrapper > a.addToBin { display: table-cell; }
					.videoPlayer .player ~ .mobileTools .divider { display: table-row; height: 0.75em; }
					.videoPlayer .player ~ .mobileTools .buttonWrapper > a { width: 50% }
				}

				/* move tab panel below video, change .mobileTools to 1x4 vertical layout */
				@media screen and (max-width: 799px) {
					/* move tab panel below video */
					.videoPlayer .player + .info .tabPanel { margin-right: 0; }
					.videoPlayer div.textLog {
						left: 5em;
						right: 5em;
					}
					.videoPlayer #markIn.markerContainer { margin-right: -5em; }
					.videoPlayer #markOut.markerContainer { margin-left: -5em; }

					/* change .mobileTools to 1x4 vertical layout */
					.videoPlayer .mobileTools { top: 0 !important; }
					.videoPlayer .mobileTools .divider { display: none !important; }


					/* for instances without the player, tabs are now functional instead of just headings */
					.videoPlayer .info .tabs a {
						position: relative;
						left: auto !important;
						background-color: #c3cccc;
						cursor: pointer;
						margin-left: 0 !important;
						text-align: left;
					}
						.videoPlayer .info .tabs a:hover { background-color: #d7dddd !important; }
						.videoPlayer .info .tabs a.active { background-color: #eceeee !important; }


					/* for instances without the player, this is where the tabbed areas collapse into one */
					.videoPlayer .tabPanel .details { right: 12px; }
					.videoPlayer .tabPanel .log { left: 0; }

					.videoPlayer .info .tabPanel .log,
					.videoPlayer .info .tabPanel .details,
					.videoPlayer .info .tabPanel .thumbnails {
						display: none;
					}

				}

				@media screen and (min-width: 680px) and (max-width: 799px) {
					.videoPlayer .player { margin-left: 22px !important; }

					/* adjustments for when there's no video player */
					.videoPlayer .hiddenPlayer ~ .info .infoInner {
						width: 520px;
						margin-left: 10px;
						margin-top: 6px;
					}
					.videoPlayer .hiddenPlayer ~ .info .tabs {
						text-align: left;
					}
					.videoPlayer .hiddenPlayer ~ .info .tabPanel {
						border-bottom: 10px solid #ced7d7;
					}
					.videoPlayer .hiddenPlayer ~ .mobileTools {
						top: 32px !important;
						left: 540px !important;
					}
				}

table.textLog {
	font-size: 13px;
	font-weight: bold;
	line-height: 1.0em;
	background-color: #ededed;
	border-collapse: collapse;
	width: 100%;
	color: #363736;
}

	table.textLog tr { cursor: pointer; }

	table.textLog tr.outsideClip,
	table.textLog tr.outsideClip:hover {
		cursor:default;
		opacity: 0.5 !important;
	}

	table.textLog td {
		vertical-align: top;
		padding: 6px;
	}

		table.textLog td.timecode { width: 42px; }

			table.textLog td.timecode .offset { display:none;}

		table.textLog td div.logMusic {
			padding-left: 16px;
			background: url('logMusic.png') no-repeat 0 1px;
			font-style: italic;
		}

		table.textLog td div.logMusic em { font-style: italic; }

#shareDialog {
	text-align: center;
}

	#shareDialog a {
		margin: 0 0.5em 0.5em;
		padding: 0.5em;
		white-space: nowrap;
		text-decoration: none;
		display: inline-block;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 14px;
	}

		#shareDialog .emailClip::before {
			font-family: "Font Awesome 6 Pro";
			font-size: 20px;
			font-weight: 900;
			content: '\f0e0';
			margin-right: 0.5em;
			vertical-align: middle;
		}

		#shareDialog .copyLink::before {
			font-family: "Font Awesome 6 Pro";
			font-size: 20px;
			font-weight: 900;
			content: '\f0c1';
			margin-right: 0.5em;
			vertical-align: middle;
		}

	#shareDialog iframe {
		margin: 0.5em;
	}

	#shareDialog .icon {
		width: 38px;
		height: 38px;
		vertical-align: middle;
	}

	#shareDialog .extShare {
	}

		#shareDialog .extShare iframe {
			vertical-align: middle;
		}

.binPage #videoWindow .closeContainer,
.binPage #videoWindow .navBtn {
	display: unset !important;
}



/*======= publicUI-patch.css =======*/



@-ms-viewport {
   width: device-width;
}

@media screen and (max-width: 480px) {
  @-ms-viewport { width: 480px; }
}

#logOut {
	display: inline-block;
}


.videoPlayer .specialty a {
	text-decoration:underline;
}

#sideBar #clipBin .emptyNote {
	position:relative;

}

#sideBar ul.bin {
	min-height:68px;
}

#mailDialog label.error,
#mailDialog h3.error,{
	color:red;
}

#sideBar .signInLink {
	text-decoration:underline;
}

#videoWindow.popup {
	position:fixed;
}


#hiddenPlayerContent {
	display:none;
}

#selectedReel .videoPlayerBox {
	right: 15px;
	position: absolute;
	top: 15px;
	z-index: 2;
}

#binList div.reelDetails {
	height:58px;
	width:48px;
	float: left;
    margin: 9px 0 1px 10px;
    position: relative;
}

.pager-disabled {
	cursor: default !important;
    opacity: 0.5;
}

div.ui-dialog-content {
	overflow-y: display;
}

div.hiddenPlayer .player-container,
div.hiddenPlayer .controls {
	display:none;
}

#alertDialog .loading {
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.videoPlayer input[type="checkbox"] ~ .markerContainer {
	top:19px;
}



.compReels div.videoPlayerBox {
    height: 360px;
    position: absolute;
    right: 10px;
    width: 480px;
}

.videoPlayer.popup {
	margin:0;
}

#sideBar #proStatus .proMinutesCount, #sideBar #proStatus .proMinutesDate {
    font-weight: bold;
}

.videoPlayer .tabPanel .details div.restricted img {
    margin-right: 5px;
    vertical-align: middle;
}

.ui-dialog {
	z-index: 1004;
}

.ui-widget-overlay {
	z-index: 1003;
}




.binTrashcan {
	height:150px;
	width:150px;
	background-color: white;

}




.debug {
	bottom: 0;
    position: absolute;
    font-size: 14px;
}

#resultsList {
	padding-top: 200px;
}

#resultsList .topLoadingPage {
	position: absolute;
	top:0;
	height:280px;
	width:100%;
}

#resultsList.firstPage {
	padding-top:0;
}

#resultsList .placeHolder {
	position:absolute;
	top: -2000px;
	left:-2000px;
}

.resultPage {

}

.resultPageLoading {
	background-color: #C8CFCF;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.17);
    margin: 0 0 20px;
    height: 200px;
    padding: 0;
    position: relative;

}

/* for testing purposes */

.videoPlayer .topTabs,
.videoPlayer .topTabContent {
	display:none !important;
}

@media (min-width: 1025px) {

	/*
	.videoPlayer .player {
		display:block !important;
	}

	.videoPlayer .mobileTools {
		display:none;
	}
	*/
}



html.fullScreenDialogOpen,
html.fullScreenDialogOpen body{
	height: 100%;
	overflow: hidden;
	
	/*overflow-y: scroll;
	position: fixed;*/
}

.videoPlayer .textLog {
	z-index: -1;/*negative z-index fixes android 4 z-index issues */
}
/*
@media (max-width: 1024px) {
	/* the iphome won't allow a hidden video to play, so move it
	off screen instead *//*
	.videoPlayer .player.offScreen {
		position:absolute;
		left: 1000px;
	}

	.videoPlayer .topTabs {
		display:block !important;
	}

		.videoPlayer .topTabs a,
		.videoPlayer .mobileTools a{
			border: 1px solid black;
			margin: 2px;
			padding: 0 5px;
			text-transform: uppercase;
			font-weight: bold;
			font-size:13px;
			display: inline-block;
		}

	.videoPlayer .topTabContent {
		bottom: 60px;
		left: 0;
		position: absolute;
		right: 0;
		top: 120px;
	}

	.videoPlayer .topTabContent a:first-child {
		display:none;
	}

	.videoPlayer .topTabContent .lockLog {
		display:none;
	}

	.videoPlayer .topTabContent .thumbnails,
	.videoPlayer .topTabContent .details {
		position:absolute;
		top:5px;
		left:5px;
		bottom:5px;
		right:5px;
		overflow-y: scroll;
	}

	.videoPlayer .mobileTools {
		display:block;
		position:absolute;
		bottom:20px;
		left:0;
		right:0;
	}
}
*/

@media (max-width: 1024px) {



	html #headerTop .content {
		width: 100%;
		min-width:0;
	}



}





html #resultsList .searchResult .sceneMatches {
	/*
	position:relative;
	width: 100%;
	padding: 0 0 20px;
	*/
}

	html #resultsList .searchResult .sceneMatches ul {
		/* position: relative; */
	}



#layoutContainer > .spinner {
	position:fixed !important;
}

#alertDialog .loading {
	height: 120px;
	width:100%;

	position:relative;
}


	@keyframes tabBlink {
		0% {
			background-color: var(--hf-inner-panel-bg-color);

		} 50% {
			background-color: var(--hf-highlight-color);

		} 100% {
			background-position: var(--hf-inner-panel-bg-color);
		}
	}

	@-webkit-keyframes tabBlink {
		0% {
			background-color: var(--hf-inner-panel-bg-color);

		} 50% {
			background-color: var(--hf-highlight-color);

		} 100% {
			background-position: var(--hf-inner-panel-bg-color);
		}
	}

.blink {
	animation: tabBlink 1s linear;
	animation-iteration-count: 2;
	-webkit-animation: tabBlink 1s linear;
	-webkit-animation-iteration-count: 2;
}


.searchResult {
	transition: height 1s;
}

.notransition {
	transition:none !important;
}

.resultPage:last-child:not(.loadingPage) {
	padding-bottom:225px !important;
}


.binList .listBox.hasClips {
	min-height: 50px;
}
#headerTop .showBins .displayedBinIndicator,
#headerTop .binMenu .header .displayedBinIndicator {
	display: none !important;
}

.binHeader .displayedBinIndicator {
	font-style: normal;
	color: inherit;
	display: block;
	font-size: 0.75em;
	line-height: 1;
}

#.searchBox[placeholder]:focus::-webkit-input-placeholder {
	opacity: 0;
}

#searchBox[placeholder]:focus:-moz-placeholder { /* Firefox 18- */
	opacity: 0;
}

#searchBox[placeholder]:focus::-moz-placeholder {  /* Firefox 19+ */
	opacity: 0;
}

#searchBox[placeholder]:focus:-ms-input-placeholder {
	opacity: 0;
}


#videoPlayerContainer.iosPlay {
	position:fixed;
	top:0;
	height:100%;
	width:100%;
	left:0;
	right: 0;
	z-index:100;
	background-color:black;
	display:block !important;
	border-radius:0;

}

	#videoPlayerContainer.iosPlay .video-js {
		height:100% !important;
		width:100% !important;
	}

	
   .ui-dialog #videoPopup {
   	   text-align: center;
   	   display: flex;
   }
	
   .ui-dialog #videoPopup .videoPlayerBox {
		display:inline-block;
		text-align: left;
   }

   .ui-dialog #videoPopup .videoPlayerBox .video-js {
		height:100% !important;
		width:100% !important;
   }

#videoWindow.no-video #jumpToPoint {
	display: none !important;
}

.deletedTape {
  opacity: 0.35;
  font-style: italic;
}

#searchBar a.btnMusicSearch.cancel {
   padding-bottom: 1em;
   padding-top: 1em;
}

.no-close .ui-dialog-titlebar-close {
	display: none;
}


	.ui-dialog-titlebar .closeContainer {
		height:2.5em;
		top: -0.5em;
		right: -1.222em;
	}

	.clipDetails .closeContainer {
		width: 3.5em;
		height: 2em;
	}



/*
	.closeContainer:hover .close, .closeContainer:hover .ui-dialog-titlebar-close { background-color: #ebebeb; }
*/
/*
	.closeContainer:hover a.close:before,
	.closeContainer:hover a.close:after,
	.closeContainer:hover .ui-dialog-titlebar-close:before,
	.closeContainer:hover .ui-dialog-titlebar-close:after { background-color: #222424; }
*/
/*
	.closeContainer.touched .close, .closeContainer.touched .ui-dialog-titlebar-close { background-color: #ebebeb; }
	.closeContainer.touched a.close:before,
	.closeContainer.touched a.close:after,
	.closeContainer.touched .ui-dialog-titlebar-close:before,
	.closeContainer.touched .ui-dialog-titlebar-close:after { background-color: #222424; }

	#headerTop .menu .menuItem .closeContainer:hover .close { background-color: white; }
	#headerTop .menu .menuItem .closeContainer:hover a.close:before,
	#headerTop .menu .menuItem .closeContainer:hover a.close:after { background-color: #222424; }

	#headerTop .menu .menuItem .closeContainer:hover .close { background-color: white; }
	#headerTop .menu .menuItem .closeContainer:hover a.close:before,
	#headerTop .menu .menuItem .closeContainer:hover a.close:after { background-color: #222424; }
*/


#videoWindow.unlisted .navBtn {
	display:none !important;
}

.filterListContainer .wrapper {
	height: 3.75em;
    position: absolute;
    top: -3em;
    width: 3em;
}

	.filterListContainer .wrapper.next {
		 right: -1.25em;
	}

	.filterListContainer .wrapper.previous {
		 left: -0.75em;
		 width: 2.75em;
	}

	.videoPlayer.no-log  .tabs .log,
	.videoPlayer.no-log  .tabPane.log {
		display:none !important;
	}



.videoPlayer .tcOverlay .button {
	height: auto;
}

li.cipDetails .handle {
	width: 4px;
}

#onlineAvailabilityHelp .bold {
	font-weight: bold;
}

	

div.ui-dialog.fullScreen_fixFixed,
html.fixFixed #videoWindow.fullScreen_fixFixed {
	top: 0;
	right: 0 !important;
	left: 0 !important;
	bottom: 0;
}



.fixFixed .ui-widget-overlay {
	position:absolute;
}

.fixFixed #headerTop .ui-widget-overlay {
	position:fixed;
	top: -50%;
	height: 200%;
}


.fixFixed, .fixFixed body {
	/*height:100%;
	overflow:hidden;*/

}

#clipTitleTouchOverlay {
	position: absolute;
	right:0;
	height: 34px;
    top: 80px;
    min-width: 100px;
    cursor:pointer;
}

.ui-dialog-content {
	position: relative;
}

.videoPlayer .titleBar .save.hideForEdit {
	display: none !important;
}



.eip_saveButtonOverlay,
.eip_cancelButtonOverlay {
	position: absolute;
	height:40px;
	top: 1px;
	width:38px;
	cursor: pointer;
	z-index:2;
}

	.eip_saveButtonOverlay {
		right: 38px;
	}
	
	.eip_cancelButtonOverlay {
		right: -5px;
	}
.eip_saveButtonOverlay:hover + .eip_saveButton,
.eip_cancelButtonOverlay:hover + .eip_cancelButton { opacity: 1; }	

.clipDetails .handleTouchOverlay {
    height: 40px;
    position: absolute;
    top: 0;
    width: 40px;
    z-index: 4;
    cursor: move;
    left:-12px; 
}

.mainError {
	text-align: center;
	margin-top:10px;
}


.paidStamp {	
    position:absolute;
    top: 110px;
    right: 50%;
    z-index: 1;
    font-family: inherit,Ubuntu,sans-serif;
    font-weight: bold;
    transform: rotate(10deg);
    font-size:30px;
    color: #c00;
	background: rgba(255,255,255,0.1);
    border:solid 4px #c00;
    padding: 6px;
    border-radius: 5px;
    text-shadow: 0 0 2px #c00;
    box-shadow: 0 0 2px #c00;
    text-align: center;
	text-transform: uppercase;
}

.paidStamp .paymentDate ,
.paidStamp .paymentNote {
	font-size: 13px;
	margin-top: 4px;
	font-weight: normal;
}

.ui-button-icon-only {
    width: 2em;
    box-sizing: border-box;
    text-indent: -9999px;
    white-space: nowrap;
}