@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('fonts/fontawesomePro6/css/all.min.css');

/* body, #pageHeader { min-width: 1280px !important; } */

/*======= videoPlayer.css =======*/

:root {

	/* general, colours */
	--hf-text-color: #ccc;
	--hf-strong-color: white;
	--hf-secondary-text-color: #a4a4a4;
	--hf-background-color: #3a3a3a;
	--hf-darker-background-color: #303030;
	--hf-panel-bg-color: #4a4a4a;
	--hf-lighter-background-color: #393939;
	--hf-tab-bg-hover-color: #555;
	--hf-inner-panel-bg-color: #5c5c5c;
	--hf-faint-text-color: #808080;
	--hf-hover-color: #3d3d3d;
		/* --hf-accent-color: #00adef; */
		/* --hf-accent-color: #008da7; */
	--hf-accent-color: #52938d;
		/* --hf-accent-hover-color: #447a75; */
	--hf-accent-hover-color: #52938d;
		/* --hf-accent-color-darker: #005f70; */
		/* --hf-accent-color-darker: #2f4c51; */
		/* --hf-accent-color-darker: #394b4e; */
		/* --hf-accent-color-darker: #395451; */
		/* --hf-accent-color-darker: #52938d; */
	--hf-accent-color-darker: #447a75;
	/* --hf-accent-color-text: #7bddd4; */
	--hf-accent-color-text: #88cdc6;
		/* --hf-highlight-color: #ffcc33; */
		/* --hf-highlight-color: #ef6800; */
	--hf-highlight-color: #ff6f2c;
	--hf-highlight-hover-color: #ff6f2c;
	--hf-highlight-color-darker: #bb4821;
	--hf-input-hover-color: #fff;
	--hf-input-hover-bg-color: #434343;
	--hf-input-music-bg-color: #bb482130;
	--hf-input-music-hover-bg-color: #bb482140;
	--hf-input-divider-color: #545454;
	--hf-link-color: #ff6f2c;
	--hf-link-hover-color: #7ff3d6;


	/* general, edges and effects */
	--hf-border-radius: 5px;
	--hf-outer-border-radius: 10px;
	--hf-standard-transition: all 0.2s ease-in-out;
	--hf-modal-shadow: 2px 2px 4px #00000073, -1px -1px 4px #ffffff2a;
	--hf-modal-border: 1px solid rgba(41,41,41,0.1);
	--hf-overlay-shadow: 0 0 10px #00000073;
	--hf-overlay-edge-shadow: 0 0 5px #00000073;
	--hf-thumbnail-excerpt-bg: linear-gradient(rgba(24,24,24,1), rgba(24,24,24,0));
	--hf-panel-divider-color: #6d6d6d;
	--hf-panel-shadow: 4px 4px 6px #00000050;
	--hf-thumbnail-shadow: 4px 4px 10px #00000040;
	--hf-mobile-header-border: 1px solid #131313;


	/* sizes */
	--hf-bin-sidebar-width: 420px;
	--hf-standard-margin: 30px;
	--hf-pane-padding: 20px;
	--hf-header-breakpoint-1: 1850px;
	--hf-header-breakpoint-2: calc(var(--hf-header-breakpoint-1) - var(--hf-bin-sidebar-width) + 245px); /* 245px = width of closed bin menu */
	--hf-next-prev-width: 400px;
	--hf-overscan-amount: 104%;
	--hf-overscan-amount-alt: 1.04;
	--hf-thumb-zoom-amount: 1.05;
	--hf-home-page-banner-height: 65px;


	/* general, typography */
	--hf-body-font: Ubuntu, sans-serif;
	--hf-select-font: Ubuntu, sans-serif;
	--hf-icon-font: "Font Awesome 6 Pro";
	--hf-brands-font: "Font Awesome 6 Brands";
	--hf-strong-weight: 500;


	/* buttons */
	--hf-button-font-size: 14px;
	--hf-button-min-height: 46px;
	--hf-button-text-color: #ccc;
	--hf-button-text-hover-color: #fff;
	--hf-button-shadow: 2px 2px 3px #00000073, -2px -2px 3px #ffffff1a;
	/* --hf-button-hover-shadow: var(--hf-button-shadow), 0 0 10px var(--hf-accent-color), 0 0 4px #fff; */
	--hf-button-hover-shadow: var(--hf-button-shadow), 0 0 10px var(--hf-accent-color), 0 0 4px #fff7;
	--hf-button-active-shadow: -2px -2px 4px #00000044, 2px 2px 4px #ffffff1a, -1px -1px 6px var(--hf-accent-color), 0 0 2px #fff8;
	--hf-button-focus-shadow: var(--hf-button-shadow), 0 0 10px var(--hf-highlight-color);
	/* --hf-button-focus-shadow: var(--hf-button-shadow), 0 0 10px var(--hf-strong-color); */
	--hf-button-disabled-opacity: 0.5 !important;
	--hf-button-transition: all 0.2s ease;
	--hf-button-border: 1px solid rgba(41,41,41,0.1);
	--hf-button-flat-hover-shadow: 0 0 4px rgba(255,255,255,0.5);
	--hf-knob-shadow: 0 0 8px rgba(0,0,0,0.25);
	--hf-notice-background: #f2efe8;


	/* input fields */
	--hf-input-field-shadow: 0.7px 0.7px 2px 0px rgba(255, 255, 255, 0.1), -0.7px -0.7px 2px 0px rgba(0, 0, 0, 0.45);
	--hf-input-field-focus-shadow: 0 0 4px var(--hf-accent-color) inset;
	--hf-input-field-focus-shadow-alert: 0 0 4px var(--hf-highlight-color) inset;
	--hf-sidebar-input-width: 50px;
}

html {  /*?*/
	height: 100%; 
	margin: 0;
	padding: 0;
}

html.pageLoad * { transition: none !important; }

body {
	margin: 0;
	padding: 0;

	/*?*/
	min-height: 100%;
	height: auto !important;
	height: 100%;
	width: 100%;

	color: var(--hf-text-color);
	background-color: var(--hf-background-color);
	position: relative;
	font-family: var(--hf-body-font);
	font-size: 16px;
	text-size-adjust: none;

	-webkit-tap-highlight-color: transparent;
}

p strong { 
	color: white; 
	font-weight: 500;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea {
	background-color: var(--hf-background-color);
	border: 1px solid transparent;
	border-radius: var(--hf-border-radius);
	-webkit-appearance: none; /* for (sigh) Safari/iOS */
	box-shadow: var(--hf-input-field-shadow);
	color: var(--hf-button-text-color);
	font-family: inherit;
	font-weight: 300;
	height: unset;
	outline-color: transparent;
	outline: none;
	padding: 10px 20px;
	transition: var(--hf-standard-transition);
}

	textarea,
	textarea.eip_editObj { padding: 10px g20px; }

	input[type="number"] {
		-webkit-appearance: none; /* "appearance: textfield" won't render borders and shadows in Safari/iOS */
		appearance: none;
		-moz-appearance: textfield; /* "appearance: none" shows spin buttons on input[type="number"], which can    */
		                            /* only be disable with WebKit-specific properties (-webkit-inner-spin-button) */
		text-align: center;
		padding: 10px 0;
		margin-right: 0.5em;
		font-size: inherit;
	}

		input[type="number"]::-webkit-inner-spin-button, 
		input[type="number"]::-webkit-outer-spin-button { 
			-webkit-appearance: none; 
			margin: 0; 
		}

	input[type="email"],
	input[type="password"] {
		margin-left: 41px;
		border-radius: 0 var(--hf-border-radius) var(--hf-border-radius) 0;
		box-shadow: none;
		width: calc(100% - 43px);
		min-width: unset;
		box-sizing: border-box;
	}

		.emailInput,
		.emailInput + .eip_container .eip_editObjWrapper,
		.passwordInput {
			box-shadow: var(--hf-input-field-shadow);
			border-radius: var(--hf-border-radius);
			white-space: nowrap;
			overflow: hidden;
		}
		.emailInput + .eip_container .eip_editObjWrapper { margin-right: -4px; }
		.emailInput + .eip_container .eip_editObjWrapper input[type="email"] { margin-left: 39px; }

		.emailInput::before,
		.emailInput + .eip_container .eip_editObjWrapper::before,
		.passwordInput::before {
			font-family: var(--hf-icon-font);
			font-size: 18px;
			font-weight: 900;
			color: var(--hf-button-text-color);
			transition: var(--hf-button-transition);
			box-shadow: 1px 0px 1px #00000073, -1px -1px 1px #fff2;
			border-radius: var(--hf-border-radius) 0 0 var(--hf-border-radius);
			height: 2em;
			width: 40px;
			text-align: center;
			position: relative;
			vertical-align: top;
			border: var(--hf-button-border);
			line-height: 2em;
			margin-right: -40px;
			display: inline-block;
			background-color: #353535;
			transition: var(--hf-standard-transition);
		}

		.emailInput::before,
		.emailInput + .eip_container .eip_editObjWrapper::before { content: '\f0e0'; }
		.passwordInput::before { content: '\f023'; }

		.emailInput:hover::before,
		.emailInput + .eip_container .eip_editObjWrapper:hover::before,
		.passwordInput:hover::before { color: white; }

	input[type="text"]:hover,
	input[type="email"]:hover,
	input[type="password"]:hover,
	input[type="number"]:hover,
	input[type="tel"]:hover,
	textarea:hover {
		background-color: var(--hf-input-hover-bg-color);
		color: var(--hf-input-hover-color);
	}

	input[type="text"]:focus-visible,
	input[type="email"]:focus-visible,
	input[type="password"]:focus-visible,
	input[type="number"]:focus-visible,
	input[type="tel"]:focus-visible,
	textarea:focus-visible {
		border: 1px solid var(--hf-accent-color);
		outline-color: transparent;
		outline: none;
		box-shadow: var(--hf-input-field-focus-shadow);
	}

	input[type="text"]::placeholder,
	input[type="email"]::placeholder,
	input[type="password"]::placeholder,
	input[type="number"]::placeholder,
	input[type="tel"]::placeholder,
	textarea::placeholder {
	   	color: #b2b2b2;
	}

input[type="checkbox"],
input[type="radio"] {
	appearance: none;
	width: 1em;
	height: 1em;
	background: transparent;
	cursor: pointer;
	font-size: inherit;
	vertical-align: baseline;
	border: 3px solid var(--hf-background-color);
	box-shadow: var(--hf-button-shadow);
	transition: var(--hf-standard-transition);
}

	input[type="checkbox"] { 
		border-radius: var(--hf-border-radius); 
		margin: 0 1em 0 0;
	}
	input[type="radio"] {
		border-radius: 50%; 
		margin: 0 0.5em;
	}

	input[type="checkbox"]:not(:disabled):focus-visible,
	input[type="radio"]:not(:disabled):focus-visible {
		box-shadow: var(--hf-button-focus-shadow);
		outline: none;
	}
	input[type="checkbox"]:not(:disabled):hover,
	input[type="radio"]:not(:disabled):hover {
		box-shadow: var(--hf-button-hover-shadow);
	}
	input[type="checkbox"]:not(:disabled):active,
	input[type="radio"]:not(:disabled):active {
		box-shadow: var(--hf-button-active-shadow) !important;
	}
	input[type="checkbox"]:checked,
	input[type="radio"]:checked {
		background-color: var(--hf-accent-color);
	}

span.required { color: var(--hf-accent-color-text); }

.selectWrapper { position :relative; }

	.selectWrapper::after,
	.searchBar .searchSelect::after {
		font-family: var(--hf-icon-font);
		font-size: 15px;
		font-weight: 900;
		content: '\f107';
		transition: var(--hf-standard-transition);
		height: 100%;
		position: absolute;
		right: 1em;
		top: 0;
		color: var(--hf-button-text-color);
		display: inline-flex;
		align-items: center;
		pointer-events: none;
	}

	.selectWrapper select { 
		padding: 10px 35px 10px 20px; 
		font-size: 1rem;
	}

select {
	appearance: none;
	-webkit-appearance: none;
	border: none;
	background-color: var(--hf-background-color);
	color: var(--hf-button-text-color);
	font-family: var(--hf-select-font);
	font-size: var(--hf-button-font-size);
	font-weight: 500;
	padding: 0 35px 0 15px;
	transition: var(--hf-standard-transition);
	display: inline-block;
	box-shadow: var(--hf-input-field-shadow);
	height: 100%;
	border-radius: var(--hf-border-radius);
	transition: var(--hf-standard-transition);
	opacity: 1;
}

	select.disabled { opacity: 0.5; }

	select:not(.disabled):hover {
		background-color: var(--hf-input-hover-bg-color);
		color: var(--hf-strong-color);
		cursor: pointer;
	}

a.hasIcon {
	display: inline-block;
}

	a.hasIcon::before,
	a.hasIcon::after {
		font-family: var(--hf-icon-font);
		font-weight: 900;
		font-size: 24px;
		vertical-align: middle;
	}

		a.hasIcon::before { margin-right: 0.125em; }
		a.hasIcon::after { margin-left: 0.125em; }

		a.saveBinCopy.hasIcon::after { content: '\f217'; }


/* loading spinner */

.waitingBox {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 500;
	background-color: rgba(255,255,255,0.2);
	display: flex;
	box-sizing: border-box;
	justify-content: center;
	animation: flicker 2s infinite linear;
	/* outline: 5px solid rgba(255,255,255,0.2); */
	border-radius: var(--hf-border-radius);
	overflow: hidden;
	min-height: 98px;
}

	.cancellable .waitingBox {
		position: relative;
		margin-top: 1em;
	}

	#mainArea > .waitingBox,
	#binContents > .waitingBox {
		outline: 5px solid rgba(255,255,255,0.2); 
	}

	.waitingBox > img {
		width: 50px;
		height: 50px;
		filter: drop-shadow(0 0 5px rgba(0,0,0,0.25));
		animation: flickeryRotation 2s infinite linear;
		top: 1.5em;
		position: relative;
	}






.grecaptcha-badge { display: none; }



/* jQuery UI widgets */
.ui-widget {
	font-family: inherit;
}


.ui-dialog select {
	appearance: none;
	background-color: var(--hf-background-color);
	color: var(--hf-button-text-color);
	font-family: var(--hf-body-font);
	font-weight: normal;
	font-size: inherit;
	border: 1px solid transparent;
	border-radius: var(--hf-border-radius);
	box-shadow: var(--hf-button-shadow);
	padding: 10px 20px;
	outline: none;
	transition: var(--hf-standard-transition);
}
	.ui-dialog select:focus-visible {
		box-shadow: var(--hf-button-focus-shadow);
		outline: none;
	}
	.ui-dialog select:hover {
		box-shadow: var(--hf-button-hover-shadow);
	}
	.ui-dialog select:active {
		box-shadow: var(--hf-button-active-shadow) !important;
	}
	.ui-dialog select::after {
		font-family: var(--hf-icon-font);
		font-size: 15px;
		font-weight: 900;
		content: '\f107';
		transition: var(--hf-standard-transition);
		height: 100%;
		position: relative;
		left: -13px;
		color: var(--hf-button-text-color);
		display: inline-flex;
		align-items: center;
		pointer-events: none;
	}

#footer {
	box-shadow: 0 0 20px rgba(0,0,0,0.5);
	padding: var(--hf-standard-margin) 0 calc(var(--hf-standard-margin) * 2);
	background: linear-gradient( var(--hf-background-color), #282828);
	transition: var(--hf-standard-transition);
	position: relative;
	/* z-index: 3; */
}

	#footer .hfLogo {
		width: 290px;
		margin: 0 auto var(--hf-standard-margin);
	}

		#footer .hfLogo a {
			display: block;
		}

			#footer .hfLogo img {
				width: 100%;
			}

	#footer a {
		text-decoration: none;
	}

		#footer a:hover {
			text-decoration: underline;
		}

	#footer h3 {
		font-size: inherit;
		font-weight: 500;
		line-height: inherit;
		margin: 1em 0;
	}

	#footer a.button,
	#footer button { background-color: transparent; }

	#footer .pages {
		display: flex;
		margin: var(--hf-standard-margin) auto;
		justify-content: space-between;
		max-width: 990px;
		gap: var(--hf-standard-margin);
		padding: var(--hf-standard-margin);
		flex-wrap: wrap;
	}

		@media screen and (max-width: 960px) {
			#footer .pages { justify-content: center; }
		}

		#footer .pages .button {
			margin: 0;
		}

	#footer .contactInfo {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: start;
		margin: auto;
		max-width: 1580px;
		font-size: 18px;
		line-height: 1.5em;
		column-gap: 10%;
		padding: 0 calc(var(--hf-standard-margin) * 2);
	}

		#footer .contactInfo > div > *:first-child {
			margin-top: 0;
		}

		#footer .contactInfo .address {}

			#footer .youTube.button {
				border-radius: 50%;
				width: 80px;
				height: 80px;
				color: transparent !important;
				background-color: var(--hf-background-color);
			}

				#footer .youTube.button::before {
					content: '';
					background-color: white;
					position: absolute;
					left: 30px;
					top: 30px;
					right: 30px;
					bottom: 30px;
					border-radius: 50%;
					z-index: 1;
				}

				#footer .youTube.button::after {
					font-family: var(--hf-brands-font);
					content: '\f167';
					color: red;
					font-size: 45px;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					line-height: 80px;
					text-align: center;
					z-index: 2;
				}

		#footer .contactInfo .contactUs {
			text-align: center;
		}

			#footer .contactInfo .contactUs p {
				text-align: left;
			}

			#footer .contactInfo .contactUs button {
				margin-right: 0;
			}

		#footer .contactInfo .newsletter p {
			font-size: 16px;
		}

			#footer a.button.signup {
				margin-top: 1em;
			}


	@media screen and (max-width: 768px) {
		#footer .contactInfo {
			grid-template-columns: 1fr;
			row-gap: 3em;
			text-align: center;
		}
		#footer .contactInfo .contactUs { grid-row: 1; }
		#footer .contactInfo .address { grid-row: 3; }
	}

	#footer .smallPrint {
		font-size: 11px;
		text-align: center;
		padding: 2em;
		font-weight: 300;
	}



#copyright {
	border-top: var(--hf-mobile-header-border);
	text-align: center;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 0.625em;
	color: var(--hf-secondary-text-color);
	padding: 0.75em 0.5em 0.625em;
	right: 0;
	line-height: 1;		
}

	/* done as a pseudo-element with a negative z-index, to give a BG colour that's behind */
	/* the footer's drop shadow, while keeping #copyright's text above the drop shadow     */
	#copyright::after {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: var(--hf-darker-background-color);
		content: '';
		z-index: -1;
	}



.searchBar {
	/* reset old stuff */
	background-image: none;
	padding: 0;
	display: block;

	margin: 0 var(--hf-standard-margin);
}

	.searchBar .searchForm {
		display: grid;
		grid-template-columns: 1fr min-content auto;
		border-radius: var(--hf-border-radius);
		border: 1px solid #0003; /*?*/
		box-shadow: var(--hf-input-field-shadow);
		transition: var(--hf-standard-transition);
	}

		.searchBar .searchForm > * { grid-row: 1; }

		.searchBar .nonSearchTools { display: none; } /*?*/
		.searchBar .searchTools { display: block; } /*?*/

		.searchBar input.searchBox {
			background-color: var(--hf-background-color);
			border: 1px solid transparent;
			border-right-color: var(--hf-input-divider-color);
			border-radius: var(--hf-border-radius) 0 0 var(--hf-border-radius);
			box-shadow: none;
			color: var(--hf-button-text-color);
			font-family: inherit;
			font-weight: 300;
			height: unset;
			margin: 0;
			outline-color: transparent;
			outline: none;
			padding: 10px 55px 10px 20px;
			transition: var(--hf-standard-transition);
			width: auto;
			min-width: 10em;
			text-overflow: ellipsis;
			overflow: hidden;

			grid-column: 1;
		}

			.searchBar.music input.searchBox {
				padding-left: 2.5em;
				background-color: var(--hf-input-music-bg-color);
			}

			.searchBar.music .searchForm::before {
				content: '\f001';
				position: absolute;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: end;
				width: 2em;
				font-family: var(--hf-icon-font);
				font-weight: 900;
				color: var(--hf-highlight-color);
			}

			.searchBar input.searchBox:hover {
				background-color: var(--hf-input-hover-bg-color);
				color: var(--hf-input-hover-color);
			}

				.searchBar.music input.searchBox:hover {
					background-color: var(--hf-input-music-hover-bg-color);
				}

			.searchBar input.searchBox:focus-visible {
				border: 1px solid var(--hf-accent-color);
				outline-color: transparent;
				outline: none;
				box-shadow: var(--hf-input-field-focus-shadow);
			}

				.searchBar.music input.searchBox:focus-visible { 
					border: 1px solid var(--hf-highlight-color);
					box-shadow: var(--hf-input-field-focus-shadow-alert);
					outline-color: transparent;
					outline: none;
				}

			.searchBar .suggestions {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				border: var(--hf-modal-border);
				box-shadow: var(--hf-overlay-shadow);
				background-color: var(--hf-background-color);
				border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius);
				max-height: 50vh;
				overflow: auto;
				z-index: -1; /* to put its shadow beneath the search bar */
			}

				.searchBar .suggestions a {
					padding: 0.25em;
					text-decoration: none;
					display: block;
				}

					.searchBar .suggestions a:hover { background: var(--hf-panel-bg-color); }

					.searchBar .suggestions a.selected { background: var(--hf-panel-bg-color); }

					.searchBar .suggestions a.fuzzy {
						color: red;
					}

					.searchBar .suggestions a.infix {
						
					}

					.searchBar .suggestions a.fst {
						color: yellow;
					}


			

	.button.searchInput.clear {
		color: transparent !important;
		min-height: unset;
		padding: 0;
		grid-column: 1;
		grid-row: 1;
		justify-self: end;
		align-self: center;
		background: none;
		padding: 4px;
		box-sizing: content-box;
		margin-right: 0.25em !important;
		opacity: 1;
	}

		.button.searchInput.clear.hidden {
			opacity: 0;
			pointer-events: none;
		}
		.button.searchInput.clear::after {
			color: var(--hf-button-text-color);
		}
		.button.searchInput.clear:focus-visible::after,
		.button.searchInput.clear:hover::after {
			color: var(--hf-button-text-hover-color);
		}
		body .button.searchInput.clear:active { box-shadow: none !important; }

		.searchBar .searchSelect { 
			position: relative; 
			overflow: hidden; /* for border-radius on hover/focus */
			grid-column: 2;
		}

			/*
			.searchBar .searchSelect.focus {
				box-shadow: var(--hf-button-focus-shadow);
				border-radius: var(--hf-border-radius);
				border-color: transparent;
				z-index: 2;
				background-color: var(--hf-background-color);
			}
			*/
			.searchBar .searchSelect:hover {
				/*
				box-shadow: var(--hf-button-hover-shadow);
				border-radius: var(--hf-border-radius);
				*/
				border-color: transparent;
				z-index: 2;
				background-color: var(--hf-background-color);
			}

			.searchBar select {
				appearance: none;
				border: none;
				background-color: var(--hf-background-color);
				color: var(--hf-button-text-color);
				cursor: pointer;
				font-family: var(--hf-select-font);
				font-size: var(--hf-button-font-size);
				font-weight: 500;	
				height: 100%;
				padding: 0 35px 0 15px;
				text-transform: uppercase;
				transition: var(--hf-standard-transition);
				border-radius: 0;
				box-shadow: none;
			}

				.searchBar select.searchType_short { display: none; }

				.searchBar.music select { 
					color: var(--hf-highlight-color); 
					background-color: var(--hf-input-music-bg-color);
				}

				.searchBar select:focus-visible,
				.searchBar .searchSelect:hover select { 
					outline: none;
					color: var(--hf-button-text-hover-color);
					background-color: var(--hf-input-hover-bg-color);
				}

				.searchBar select:hover,
				.searchBar .searchSelect:hover::after { color: var(--hf-button-text-hover-color); }

					.searchBar.music select:focus-visible,
					.searchBar.music .searchSelect:hover select,
					.searchBar.music select:hover { 
						color: var(--hf-highlight-hover-color);
						background-color: var(--hf-input-music-hover-bg-color); 
					}

				.searchBar select option { color: var(--hf-faint-text-color); }

				.searchBar select option[value="music"] { color: var(--hf-highlight-color); }

.button.searchInput.searchGo:not(.disabled) {
	color: transparent;
	box-shadow: none;
	background-color: var(--hf-background-color);
	width: 18px;
	overflow: hidden;
	box-sizing: content-box;
	padding: 0 15px;
	transition: var(--hf-button-transition);
	border-left: 1px solid var(--hf-input-divider-color);
	margin: 0;
	border-radius: 0 var(--hf-border-radius) var(--hf-border-radius) 0;
	grid-column: 3;
}

	.searchBar.music .button.searchInput.searchGo:not(.disabled) { background-color: var(--hf-input-music-bg-color); }

	.searchBar .searchSelect.focus + .button.searchInput.searchGo { border-left-color: transparent; }

	.button.searchInput.searchGo::before {
		font-family: var(--hf-icon-font);
		font-size: 18px;
		font-weight: 900;
		color: var(--hf-button-text-color);
		content: '\f002';
		transition: var(--hf-button-transition);
	}

		.button.searchInput.searchGo:hover::before { color: var(--hf-button-text-hover-color); }

	.button.searchInput.searchGo:focus-visible {
		box-shadow: var(--hf-button-focus-shadow);
		outline: none;
	}
	.button.searchInput.searchGo:hover {
		box-shadow: var(--hf-button-hover-shadow);
		z-index: 3;
	}
	.button.searchInput.searchGo:active {
		box-shadow: var(--hf-button-active-shadow) !important;
		left: 1px;
		top: 1px;
		z-index: 3;
	}

.searchBar .smallPrint {
	font-size: 14px;
	font-style: italic;
	padding: 1em 1em 1em 2em;
	top: calc(100% + 10px);
	right: 0;
	position: absolute;
	color: var(--hf-highlight-color);
	display: none;
	transition: var(--hf-standard-transition);
	background-color: var(--hf-darker-background-color);
	box-shadow: var(--hf-panel-shadow);
	border-radius: var(--hf-border-radius);
	max-width: 500px;
	grid-template-columns: auto;
	grid-template-rows: auto min-content;
	justify-items: center;
	gap: 1.5em;
}

	.searchBar.music .smallPrint { display: grid; }
	.searchBar .smallPrint:hover { color: white; }
	.searchBar .smallPrint::before {
		content: '*';
		position: absolute;
		left: 1em;
		top: 1em;
	}


@media screen and (max-width: 1850px) { 
	html.binSidebarOpen .headerContainer.fixed .searchBar select.searchType { display: none; }
	html.binSidebarOpen .headerContainer.fixed .searchBar select.searchType_short { display: block; }

	html.binSidebarOpen .headerContainer.fixed #headerTop #contactAResearcher .long { display: none; }
}

@media screen and (max-width: 1650px) { 
	.headerContainer.fixed .searchBar select.searchType { display: none; }
	.headerContainer.fixed .searchBar select.searchType_short { display: block; }

	.headerContainer.fixed #headerTop #contactAResearcher .long { display: none; }

	html.binSidebarOpen .headerContainer.fixed .myLinks a.showAccount .userName,
	html.binSidebarOpen .headerContainer.fixed .myLinks a.signInLink .title { display: none; }
	html.binSidebarOpen .headerContainer.fixed .myLinks a.showMenu.showAccount::before,
	html.binSidebarOpen .headerContainer.fixed .myLinks a.signInLink::before { 
		content: "\f007";
		margin-right: 0; 
	}
	:root { --hf-bin-sidebar-width: 360px; }
}

@media screen and (max-width: 1525px) { 

	html.binSidebarOpen .headerContainer.fixed #headerTop #contactAResearcher span { display: none; } 
	html.binSidebarOpen .headerContainer.fixed #headerTop #contactAResearcher { margin: 0 25px 0 0; padding: 5px 24px; }
	html.binSidebarOpen .headerContainer.fixed #headerTop #contactAResearcher::before {
		left: 0;
		width: 100%;
	}
}

@media screen and (max-width: 1475px) { 
	html.binSidebarOpen .searchBar select.searchType { display: none; }
	html.binSidebarOpen .searchBar select.searchType_short { display: block; }
	/* .headerContainer.fixed .myLinks a.showAccount .userName { display: none; }
	.headerContainer.fixed .myLinks a.showMenu.showAccount::before { 
		content: "\f007";
		margin-right: 0;
	}
	*/

	.myLinks a.showMenu.showAccount::before { 
		content: "\f007";
		margin-right: 0; 
	}
	.myLinks a.showAccount .userName {
		display: block;
		font-weight: normal;
		font-size: 14px;
	}
	.myLinks .menuGroup a.showAccount {
		max-width: 100px;
		text-align: center;
		/*
		top: 12px;
		padding-top: 11px;
		padding-bottom: 11px;
		*/
		padding-top: 7px;
	}
	.headerContainer.fixed .myLinks .menuGroup a.showMenu,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.headerContainer.fixed .myLinks .menuGroup a.signInLink,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.signInLink {
		padding-top: 13px;
		padding-bottom: 13px;
	}
	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after { bottom: -14px; }


	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins {
		font-size: 14px;
		font-weight: normal;
		text-align: center;
		padding-left: 12px;
	}

	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name {
		max-width: 100px;
	}
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name::before, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name::before {
		display: block;
		margin-right: 0;
	}
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .count, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .count {
		margin-left: 0;
	}

	html.binSidebarOpen #headerTop { grid-template-columns: auto 258px auto; }
	html.binSidebarOpen #headerTop #contactAResearcher { white-space: nowrap; }
}

@media screen and (max-width: 1280px) { 
	.headerContainer.fixed #headerTop #contactAResearcher span { display: none; }
	.headerContainer.fixed #headerTop #contactAResearcher { margin: 0 25px 0 0; padding: 5px 24px; }
	.headerContainer.fixed #headerTop #contactAResearcher::before {
		left: 0;
		width: 100%;
	}
}

@media screen and (max-width: 1180px) { 
	/*
	body:not(.home) .headerContainer.fixed #headerTop #contactAResearcher, 
	#videoWindow .headerContainer.fixed #headerTop #contactAResearcher{
		display: none;
	}
	*/
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before {
		margin-right: 0.6em;
		margin-left: -0.8em;
	}
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name {
		max-width: 60px;
	}
	html.binSidebarOpen #headerTop #contactAResearcher { white-space: normal; }
}

/* @media screen and (max-width: 1024px) { */
@media screen and (max-width: 1150px) { 

	body.home #pageHeader.fixed #headerTop,
	body:not(.home) .headerContainer.fixed #headerTop, 
	#videoWindow .headerContainer.fixed #headerTop {
		grid-template-columns: auto 220px auto;
		grid-template-columns: min-content 1fr 220px 1fr min-content;
		grid-template-columns: auto 1fr 220px 1fr auto;
	}

	body.home #pageHeader.fixed #hfLogo,
	body:not(.home) .headerContainer.fixed #hfLogo, 
	#videoWindow .headerContainer.fixed #hfLogo {
		grid-column: 2;
		grid-column: 3;
	}

	.home #pageHeader #headerTop #hfLogo .logoSansReel { width: 100%; }
	body.home #pageHeader.fixed #hfLogo .reelWrapper { padding-left: 0; }

	body.home #pageHeader.fixed #headerTop #contactAResearcher, 
	body:not(.home) .headerContainer.fixed #headerTop #contactAResearcher, 
	#videoWindow .headerContainer.fixed #headerTop #contactAResearcher {
		grid-column: 1;
	}

	body.home #pageHeader.fixed #headerTop .myLinks, 
	body:not(.home) .headerContainer.fixed #headerTop .myLinks, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		grid-column: 3;
		grid-column: 5;
	}

	body.home #pageHeader.fixed #headerTop .homeSearchBar, 
	body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		grid-row: 2;
		grid-column: 1 / 6;
	}

	body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		margin: 0 10px;
	}

	html.binSidebarOpen body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	#html.binSidebarOpen videoWindow .headerContainer.fixed #headerTop .searchBar {
		margin-right: calc(var(--hf-bin-sidebar-width) + 10px);
	}

	.headerContainer.fixed #headerTop {
		row-gap: 13px;
	}

	body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		margin-left: 10px;
	}
	.headerContainer.fixed #headerTop #contactAResearcher span {
		display: inline;
	}
	.headerContainer.fixed #headerTop #contactAResearcher::before {
		left: 10px;
		width: unset;
	}
	.headerContainer.fixed #headerTop #contactAResearcher {
		margin: 0 25px 0 10px;
		padding: 5px 15px 5px 40px;

		align-self: center;
		justify-self: start;
	}

	.headerContainer.fixed #headerTop #contactAResearcher .long {
		display: inline;
	}

	.headerContainer.fixed #headerTop .myLinks, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		margin-left: 25px;
	}

}

@media screen and (max-width: 1080px) { 
	html.binSidebarOpen #headerTop #contactAResearcher { margin-right: 40px; }
	html.binSidebarOpen #headerTop .myLinks { margin-left: 20px; }

	html.binSidebarOpen #headerTop #contactAResearcher .long { display: none; }

	body:not(.home) .headerContainer.fixed #hfLogo a, 
	.home #pageHeader.fixed #headerTop #hfLogo a,
	#videoWindow .headerContainer.fixed #hfLogo a {
		padding-left: 20px;
	}
	body:not(.home) .headerContainer.fixed #hfLogo .logoSansReel, 
	.home #pageHeader.fixed #headerTop #hfLogo .logoSansReel,
	#videoWindow .headerContainer.fixed #hfLogo .logoSansReel {
		margin-left: 10px;
	}
	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins, 
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins {
		padding-right: 20px;
	}
	body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		/* margin-left: 25px; */
	}

	html.binSidebarOpen #headerTop #contactAResearcher span { display: none; } 
	html.binSidebarOpen #headerTop #contactAResearcher { padding: 5px 24px; }
	html.binSidebarOpen #headerTop #contactAResearcher::before {
		left: 0;
		width: 100%;
	}	
	html.binSidebarOpen body.search .searchBar input.searchBox { min-width: unset; }
}

@media screen and (max-width: 960px) { 
	.searchBar select.searchType { display: none; }
	.searchBar select.searchType_short { display: block; }
}

@media screen and (max-width: 800px) { 
	body:not(.home) .headerContainer.fixed #headerTop, 
	#videoWindow .headerContainer.fixed #headerTop {
		grid-template-columns: min-content 1fr 220px 1fr min-content;
	}
		
	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after, 
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after,
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before {
		color: transparent;
	}
}

@media screen and (max-width: 768px) { 

	html.binSidebarOpen .myLinks .menuGroup.binMenuGroup {
		position: fixed;
		left: 0;
		right: 0;
		margin-left: 0;
		top: 20px;
	}

	html.binSidebarOpen .myLinks .menuGroup a.showMenu.showBins {
		max-width: 100% !important;
	}

	html.binSidebarOpen #headerTop .menu.binMenu {
		width: 100%;
		top: 63px !important;
	}

	html.binSidebarOpen body:not(.home) .headerContainer.fixed #headerTop .myLinks, 
	html.binSidebarOpen #videoWindow .headerContainer.fixed #headerTop .myLinks {
		z-index: 1;
	}

}

@media screen and (max-width: 1150px) {
		body:not(.home) .headerContainer.fixed #headerTop .myLinks, #videoWindow .headerContainer.fixed #headerTop .myLinks {
				z-index: 1;
		}
}





@media screen and (max-width: 720px) {
	.headerContainer.fixed #headerTop #contactAResearcher span {
		display: none;
	}
	.headerContainer.fixed #headerTop #contactAResearcher {
		padding: 5px 24px;
	}
	.headerContainer.fixed #headerTop #contactAResearcher::before {
		left: 0;
		width: 100%;
	}
}

@media screen and (max-width: 640px) {
	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount, 
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount {
		max-width: 60px;
	}
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins .name {
		max-width: 50px;
	}
	.headerContainer.fixed #headerTop #contactAResearcher, 
	#videoWindow .headerContainer.fixed #headerTop #contactAResearcher {
		margin-right: 0;
	}
	.headerContainer.fixed #headerTop .myLinks, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		margin-left: 10px;
	}
}

@media screen and (max-width: 532px) {
	body:not(.home) .headerContainer.fixed #headerTop,
	body.home #pageHeader.fixed #headerTop, 
	#videoWindow .headerContainer.fixed #headerTop {
		grid-template-columns: 220px 1fr min-content;
		grid-template-columns: 1fr 220px 1fr;
	}
	.headerContainer.fixed #headerTop #contactAResearcher { display: none; }

	body:not(.home) .headerContainer.fixed #hfLogo, 
	body.home #pageHeader.fixed #hfLogo, 
	#videoWindow .headerContainer.fixed #hfLogo {
		grid-column: 1;
		grid-column: 2;
		grid-row: 1;
	}

	body .headerContainer.fixed #headerTop .myLinks, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		margin-left: 0;
	}

	body:not(.home) .headerContainer.fixed #headerTop .myLinks,
	body.home #pageHeader.fixed #headerTop .myLinks, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks {
		grid-column: 3;
		grid-column: 1 / 4;
		justify-content: space-between;
		width: 100%;
		pointer-events: none; /* because of overlap with the logo; its children get them back */
	}
	body:not(.home) .headerContainer.fixed #headerTop .myLinks > *,
	body.home #pageHeader.fixed #headerTop .myLinks > *, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks > * {
		pointer-events: all;
	}


	body:not(.home) .headerContainer.fixed #headerTop .searchBar, 
	body.home #pageHeader.fixed #headerTop .searchBar, 
	#videoWindow .headerContainer.fixed #headerTop .searchBar {
		grid-column: 1 / 4;
	}
	/*
	.headerContainer.fixed #headerTop .myLinks a.showAccount .userName,
	#videoWindow .headerContainer.fixed #headerTop .myLinks a.showAccount .userName {
		width: 0;
	}
	.headerContainer.fixed #headerTop .myLinks a.showAccount::before,
	#videoWindow .headerContainer.fixed #headerTop .myLinks a.showAccount::before {
		position: relative;
		top: 8px;
	}
	*/
	.headerContainer.fixed #headerTop .myLinks a.showAccount,
	#videoWindow .headerContainer.fixed #headerTop .myLinks a.showAccount,
	.headerContainer.fixed .myLinks .menuGroup a.signInLink,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.signInLink {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.myLinks .menuGroup a.signInLink .title {
		font-weight: normal;
		font-size: 14px;
		display: block;
	}
	html:not(.binSidebarOpen) .headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu {
		padding-left: 10px;
		padding-right: 10px;
	}
	.headerContainer.fixed .myLinks .menuGroup a.signInLink,
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.signInLink {
		padding: 5px 10px;
		text-align: center;
	}
	.myLinks a.signInLink::before { 
	 	margin-right: 0;
	}	
	.headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu.showBins .name .title { transition: none; }
	html:not(.binSidebarOpen) .headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu.showBins .name .title, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu.showBins .name .title {
		display: none;
	}
	.headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu.showBins .count, 
	#videoWindow .headerContainer.fixed #headerTop .myLinks .menuGroup a.showMenu.showBins .count {
		position: relative;
		top: -3px;
	}
	.headerContainer.fixed #hfLogo, 
	#videoWindow .headerContainer.fixed #hfLogo {
		/* margin-left: -32px; */
		/* left: -16px; */
	}
	.searchBar input.searchBox { min-width: unset; }
		
	.headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after, 
	#videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showAccount::after,
	html:not(.binSidebarOpen) .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before, 
	html:not(.binSidebarOpen) #videoWindow .headerContainer.fixed .myLinks .menuGroup a.showMenu.showBins::before {
		width: 0;
		margin: 0;
	}

	body:not(.home) .headerContainer.fixed #hfLogo a, 
	.home #pageHeader #headerTop #hfLogo a,
	#videoWindow .headerContainer.fixed #hfLogo a {
		padding: 0 10px;
	}
	body:not(.home) .headerContainer.fixed #hfLogo .logoSansReel, 
	.home #pageHeader.fixed #headerTop #hfLogo .logoSansReel,
	#videoWindow .headerContainer.fixed #hfLogo .logoSansReel {
		margin-left: 0;
	}	

	#footer .contactInfo {
		font-size: 16px;
		padding: 0 var(--hf-standard-margin);
	}

	#footer h3 {
		font-size: 18px;
	}


}



.closeContainer {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
	height: 2.75em;
	width: 2.75em;
	z-index: 2;
}

	.ui-dialog-titlebar > button.ui-dialog-titlebar-close:not(.disabled) {
	    position: absolute;
	    right: -0.875rem;
	    top: -0.625rem;
	}

	a.close,
	a.ui-dialog-titlebar-close,
	button.ui-dialog-titlebar-close:not(.disabled),
	.button.searchInput.clear:not(.disabled) {		
		display: inline-block;
		cursor: pointer;

		font-size: 24px;
		border-radius: 50%;
		height: 1.5em;
		width: 1.5em;
		transition: var(--hf-standard-transition);

		border: none;
		box-shadow: none;
		min-height: unset;
		margin: 0;
	}

		.button.searchInput.clear:not(.disabled) {	
			height: 1em;
			width: 1em;
		}

		/*
		a.close:focus-visible,
		a.ui-dialog-titlebar-close:focus-visible,
		button.ui-dialog-titlebar-close:not(.disabled):focus-visible { 
			box-shadow: none; 
			outline: none;
		}
		*/

		a.close:hover,
		a.ui-dialog-titlebar-close:hover,
		button.ui-dialog-titlebar-close:not(.disabled):hover,
		.button.searchInput.clear:hover { 
			box-shadow: none; 
			background-color: var(--hf-text-color) !important;
			color: var(--hf-background-color);
		}

		a.close:active,
		a.ui-dialog-titlebar-close:active,
		button.ui-dialog-titlebar-close:not(.disabled):active { 
			box-shadow: none !important; 
			background-color: white;
			color: var(--hf-background-color);
			left: 1px;
			top: 1px;
		}

			.ui-dialog-titlebar > button.ui-dialog-titlebar-close:not(.disabled):active {
			    right: -1px;
			    left: unset;
			    top: 1px;
			}

		a.close:focus-visible,
		a.ui-dialog-titlebar-close:focus-visible,
		button.ui-dialog-titlebar-close:not(.disabled):focus-visible,
		.button.searchInput.clear:focus-visible { 
			box-shadow: var(--hf-button-focus-shadow) !important;
		}

		a.close::after,
		a.ui-dialog-titlebar-close::after,
		button.ui-dialog-titlebar-close::after,
		.button.searchInput.clear::after {
			position: absolute;
			font-family: var(--hf-icon-font);
			font-size: 24px;
			font-weight: 900;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			right: 0;
			content: '\f00d';
			transition: var(--hf-standard-transition);

			text-indent: 0; /* override a jQuery UI default */
		}

		.button.searchInput.clear:hover::after { color: var(--hf-background-color); }


	.closeContainer a.close {
		position: absolute;
		display: block;
		bottom: 0;
	}

.player {
	padding: 0.3125em 0.3125em 0;
	width: 30em;
	height: 26.5625em;
}

.player-container {
	margin: 0;
	font-size: 16px;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;

	position: relative;
}

	.player.hideControls {
		padding:0;
	}

.player.fullScreen {
	width:100%;
	height: 100%;
	background-color: #ced7d7;
	position:absolute;
	top: 0;
	left:0;

	/*
	background-size: contain;
	background-position: center top;
	*/
}


.player.fullScreen .controls {
	/* height: 300px; */
	/* height: 4.0625em; */
}


.player .player-widget {
	position:relative;
	width:100%;
	margin-left: auto;
    margin-right: auto;
}
	.player.fullScreen .player {
		/*
		height: auto;
		bottom: 4.0625em;
		*/
		margin-top: 15px;
	}

	.hideControls .inClipIndicator {
		display:none !important;
	}

	.player.inClip .inClipIndicator {
		position: absolute;
		top: 0.75em;
		right: 0.75em;
		height: 1em;
		width: 1em;
		border-radius: 50%;
		background-color: #ebc500;
		z-index: 2;
	}

.player .contents  {
	position:  absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

@media screen and (max-width: 480px), screen and (max-height: 480px)  {
	.player .fullScreenButton {
		display:none;
	}
}

.player .fullScreenButton {
	background: url("../lib/newPlayer/images/fullscreen.png") no-repeat center center;
	background-size: 2.75em 2.75em;
	color: transparent;
	position: absolute;
	cursor: pointer;

	height: 2.75em;
	top: 0.25em;
	right: 0;
	width: 2.75em;
}

	.player.fullScreen .fullScreenButton {
		background: url("../lib/newPlayer/images/fullscreen-close.png") no-repeat center center;
	}

	.player .fullScreenButton:before {
		content: 'Full Screen';

		/* to keep invisible text from stretching the box */
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

		.player.fullScreen .fullScreenButton:before {
			content: 'Exit Full Screen';
		}


.player button {
	border: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	font-size: 1em; /* override client default */
	outline: 0;
}

	

.player .textProgress {
	position: absolute;
	left: 0.5em;
	top: 4.5em;

	/* font-family: Consolas, monospace; */
	font-family: "Consolas W01 Mono",monospace;
	font-weight: bold;
	font-size: 0.8125em;
	/* background-color: #e5e7ea; */
	padding: 0.091em 0.182em;
}

	.player .textProgress span {
		display: inline;
	}

	.player .textProgress .duration:before { 
		content: ' / ';
		color: #222424; 
	}

	.player .textProgress .duration,
	.player .textProgress .duration .tcField { color: #222; }

.player .tcField {
	/* font-family: Consolas, monospace; */
	font-family: "Consolas W01 Mono",monospace;
	font-weight: bold;
	font-size: 1em;
	background: transparent;
	width: 1.182em;
	padding: 0;
	border: 0;
	height: 1em;
	margin-right: -0.0625em; /* for better alignment with monospaced colons */
}

	.player .writable .tcField:hover { background-color: #b9c1c1; }

	.disableClipTrimming .player .tcField { cursor: default; }
	.disableClipTrimming .player .writable .tcField:hover { background-color: transparent; }

.player .pointField {
	position: absolute;
	top: 3.875em;
	height: 2.1875em;
	width: 100%;
	display: inline-block;
}

		.disableClipTrimming .player .pointField { padding-bottom: 0.125em; }

	.player .inPoint {
		right: 0;
	}

	.player .outPoint {
		text-align: right;
		left: 0;
	}


.player .pointField span.tcTextField {
	display: inline-block;
	position: relative;
	top: 0em;
	font-size: 0.8125em;
	background-color: #e5e7ea;
	padding: 0.231em 0.231em 0.308em;
	border: 0.154em solid #222424;
}


	/*.player .pointField span.tcTextField:before {
		font-weight: bold;
		
		font-family: "Consolas W01 Mono",monospace;
		position: absolute;
		text-transform: uppercase;
		background-color: #b9c1c1;
		padding: 0 0.5em;
		color: rgba(0,0,0,0.75);
		border-bottom: 0.4em solid transparent;
		border-top: 0.4em solid transparent;
		height: 0;
		line-height: 0;
		margin-top: 0.231em;
		cursor: default;
	}

		.player .pointField span.tcTextField.inPointPos:before {
			left: 100%;
			content: "Start";
			margin-left: 0.154em;
			border-right: 0.8em solid #a7a9a9;
		}
		.player .pointField span.tcTextField.outPointPos:before {
			right: 100%;
			text-align: right;
			content: "End";
			margin-right: 0.154em;
			border-left: 0.8em solid #a7a9a9;
		}*/

	/*.player .pointField:before {
		border-top: 2px solid #a7a9a9;
		content: "";
		position: absolute;
		top: 1.1875em;
	}

		.player .pointField.inPoint:before {
			left: 2.25em;
			right: 0;
		}
		.player .pointField.outPoint:before {
			right: 2.25em;
			left: 0;
		}
*/
	/*.player .pointField:after {
		border-top: 0.154em solid #222424;
		height: 0.875em;
		top: -0.5625em;
		position: absolute;
		content: "";
	}

		.player .pointField.inPoint:after {
			left: 2.25em;
			right: 0;
			border-left: 0.154em solid #222424;
		}
		.player .pointField.outPoint:after {
			right: 2.25em;
			left: 0;
			border-right: 0.154em solid #222424;
		}*/


.player .pointField span.setPointWrapper {
	background-color: #b9c1c1;
	padding: 0.25em 0.75em 0.25em 1em;
	position: absolute;
	top: 2.0625em;
	display: block;
}

		.player .pointField.inPoint span.setPointWrapper {
			left: 0;
			padding-left: 0.5em;
		}
		.player .pointField.outPoint span.setPointWrapper {
			right: 0;
			padding-right: 0.25em;
		}

		.disableClipTrimming .player .pointField span.setPointWrapper {
			display: none;
		}

.player .setPoint {
	display: block;
	background-color: #dcdede;
	font-size: 1em;
	line-height: 0.625em;
	width: 3.625em;
	padding: 0.0625em 0 0.25em;
	box-shadow: 0.1875em 0.1875em 0 rgba(0, 0, 0, 0.15);
	border-radius: 0.25em;
	margin: 0 0.25em 0.25em 0;
	/*
	position: absolute;
	border-color: #b9c1c1;
	border-style: solid;
	border-width: 0 1em 0.5em;
	top: 2.0625em;
	*/
	-webkit-box-sizing: content-box; /* for Android 2 */
	box-sizing: content-box;
}

	.player .setPoint:before {
		font-size: 0.8125em;
		/* font-family: Consolas, monospace; */
		font-family: "Consolas W01 Mono",monospace;
		font-weight: bold;
		content: 'Set to current';
		color: rgba(0,0,0,0.5);
		text-transform: uppercase;
		text-align: center;
	}

		.player .setPoint:hover:before { color: black; }

	.player .setPoint:active {
		margin: 0.125em;
		box-shadow: inset 0 0 0.125em rgba(0, 0, 0, 0.15);
	}


.player .progressBar {
    height: 1em;
    left: 0.5em;
    top: 0.625em;
    right: 0.5em;
    border: none;
    background-color: #a7a9a9;
    position: absolute;

}

	.player .progressBar .innerContainer {
		height:  100%;
		left: 0;
		top: 0;
		width: 100%;
		position: absolute;
		overflow-x: clip;
		
	}
	
	.player .progressBar .innerBar {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height:  100%;
	}

	:root {
		--hf-progressBar-bg-size: 15%;
	}

	.player .progressBar .innerBar .bg {
		background-image: url("../lib/newPlayer/images/fine_adjust_bar-handMade.svg");
		background-size: var(--hf-progressBar-bg-size) 40px;
		background-position-y: center;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height:  100%;
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

			.player .progressBar .innerBar .bg.x2 { background-size: calc(var(--hf-progressBar-bg-size) / 2) 40px; }
			.player .progressBar .innerBar .bg.x4 { background-size: calc(var(--hf-progressBar-bg-size) / 4) 40px; }
			.player .progressBar .innerBar .bg.x8 { background-size: calc(var(--hf-progressBar-bg-size) / 8) 40px; }
			.player .progressBar .innerBar .bg.x16 { background-size: calc(var(--hf-progressBar-bg-size) / 16) 40px; }
			.player .progressBar .innerBar .bg.x32 { background-size: calc(var(--hf-progressBar-bg-size) / 32) 40px; }
			.player .progressBar .innerBar .bg.x64 { background-size: calc(var(--hf-progressBar-bg-size) / 64) 40px; }

	.player .progressBar .innerBar .bg.visible {
		opacity: 1;
	}
	
	

	.player .progressBar .innerBar.animate,
	.player .progressBar .playHead.animate {
		transition: left 0.3s;
	}

.player .progressBarOverlay {
	height: 1.8em;
	left: 5.125em;
	top: 0.25em;
	right: 12.875em;
	position: absolute;
}


	.player .progressBar .clip .clipHandle {
		display: none;
		height: 7.25em;
		width: 1em;
		position:absolute;
		top: -5em;
		z-index: 1;
		cursor: col-resize;
	}

	.player .progressBar .clipHandle:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 50%;
		border-left: 0.125em solid #222424;
		transform: translate(-50%);
	}

	.player .progressBar .clip.hover .clipHandle {
		display: block;
	}

	.player .progressBar .clipHandle:hover:after,
	.player .progressBar .clipHandle:hover .tcTextField {
		/* border-color: red; */
	}

	.player .progressBar .clipHandle.inHandle {
		left:-0.5em;
	}

	.player .progressBar .clipHandle.outHandle {
		right: -0.5em;
	}

	.player .progressBar.draggingClip,
	.player .progressBar.draggingClip .playHead {
		cursor: col-resize;
	}

	.disableClipTrimming .player .progressBar .clipHandle {
		cursor: not-allowed;
	}

		.player .progressBar .clipHandle .pointField {
			top: 0em;
			width: 3.8em;
			cursor: default;
		}

		.player .progressBar .clipHandle .pointField.outPoint {
			left: 0.5em;
		}

		.player .progressBar .clipHandle .pointField.inPoint {
			right: 0.5em;
		}

	.player .progressBar .clip.flipLeft.narrow .outHandle{
			top: -6.75em;
			height: 8.875em;
		}

	.player .progressBar .clip.flipRight.narrow .inHandle {
		top: -6.75em;
		height: 8.875em;
	}

	.player .progressBar .clip.flipRight .outPoint {
		right: 0.5em;
		left: auto;
	} 

	.player .progressBar .clip.flipLeft .inPoint {
		left: 0.5em;
		right: auto;
	} 

	

			


	.player .progressBar .buffered {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 3;
	}

	.player .progressBar .buffered .bufferedSection {
		height: 100%;
		position: absolute;
		background-color: white;
		opacity: 0.3;
	}

	.player .progressBar .clip {
		height: 2em;
		top: -0.5em;
		z-index: 4;
		position: absolute;
		background-color: rgba(235,198,0,0.5);

	}

		.player.fullClip .progressBar .clip {
			background-color: transparent;
		}







	.player .progressBar .played {
		height: 100%;
		position:absolute;
		background-color: #333;
	}

	.player .progressBar .playHead {
		/*
		border-radius: 50%;
		width: 2em;
		height: 2em;
		background-color: red;
		margin-left: -1em;
		*/
		position: absolute;
		z-index: 4;
		/*
		margin-left: -0.5625em;
		width: 1.0625em;
		height: 1.0625em;
		background: url('images/playhead.png') transparent center center no-repeat;
		background-size: 1.0625em 1.0625em;
		*/

		/* top: -0.375em; *//*centered */
		top: -0.4375em; /* adjusted for shadow */
		cursor: pointer;
		background-color: #ebebeb;
		border-radius: 1.5em;
		margin-left: -0.75em;
		height: 1.5em;
		width: 1.5em;
		box-shadow: 0.125em 0.125em 0 rgba(0, 0, 0, 0.15);
	}
/*
.player .inPoint:before{
	content: 'In Point: ';
}

.player .outPoint:before{
	content: 'Out Point: ';
}
	*/

.player .statusText2 {
	display: block;
	z-index: 100000;
	color: yellow;
	font-size: 1.25em;
	position:absolute;
	top: 0.5em;
	left: 0.5em;

}

.player .bufferStatus {
	display: none;
	z-index: 100000;
	color: yellow;
	font-size: 1.25em;
	position:absolute;
	top: 0.5em;
	left: 0.5em;

}


.player-widget:before{
	content: "";
    display: block;
	padding-top: 75%;
	transition: padding-top 0.375s ease-in-out;
}

	.aspect5625 .player-widget:before{ padding-top: 56.25%; }
	.aspect6864 .player-widget:before{ padding-top: 68.64%; }
	.aspect6666 .player-widget:before{ padding-top: 66.66%; }
	.aspect7272 .player-widget:before{ padding-top: 72.72%; }


.player .playerDiv:before{
	content: "";
    display: block;
	padding-top: 75%;
	transition: padding-top 0.375s ease-in-out;
}

	.aspect5625 .player .playerDiv:before{ padding-top: 56.25%; }
	.aspect6864 .player .playerDiv:before{ padding-top: 68.64%; }
	.aspect6666 .player .playerDiv:before{ padding-top: 66.66%; }
	.aspect7272 .player .playerDiv:before{ padding-top: 72.72%; }


.hideControls .player-widget:before{
	padding-top: 90%;
}



.player .playerDiv {
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0;
	top: 0;
	float: none;
	background-color: black;
}
 	/* is this section garbage? */
	.player > .playerDiv {
		margin: -0.125em;
		border: 0.125em inset #ced7d7;
		background-color: black;
		cursor: pointer;
	}

	.hideControls .player > .playerDiv {
		margin: 0;
		border: 0;
	}

	.player .playerDiv > * {
		/* border-radius: 0.625em; */
		border-radius: 2.083% / 2.777%; /* scalable version of above; =10px @ 480x360 */
		overflow: hidden;
	}

	.player .playerDiv .playerElement,
	.player .playerDiv .flashContainer,
	.player .playerDiv .flashContainer object{
		position:absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		/* width and height attributes size the player coorectly on the iPad */
		width:100%;
		height:100%;
	}

	.player .playerDiv video.playerElement.loading,
	.player .playerDiv video.playerElement.hidden {
		-webkit-transform:translateX(-2048px);
	}


	.player .playerDiv .largePlayButton {
		background: url('../lib/newPlayer/images/largePlay.png');
		background-size: 5em 5em;
		background-repeat: no-repeat;
		background-position: center center;
		z-index: 2;
		position:absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0.65;
		cursor: pointer;
	}

	.player .playerDiv .spinnerContainer {
		z-index: 2;
		position:absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

.player .controls {
	position: relative;
	/* margin-top: 2.625em; *//* space for mini-log */
}



.player .zoomControl {
	width: 13.125em;
	height: 1.875em;
	background: url("../lib/newPlayer/images/fine_adjust_bar.png") no-repeat center top #b2b2b2;
	background-size: 13.125em 1.875em;
	position: absolute;
	top: 3.5em;
	right: 1em;
	margin-left: -6.5625em;
}

.player.disableZoom .zoomControl .pointer{
	cursor: not-allowed;
}

.hideFineTuning .player  .zoomControl {
	display:none;
}

	.player .zoomControl:after {
		content: "Zoom Control";
		/* font-family: Consolas, monospace; */
		font-family: "Consolas W01 Mono",monospace;
		font-size: 0.8125em;
		font-weight: bold;
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		text-transform: uppercase;
		top: 100%;
		opacity: 0.5;
	}

	.player .zoomControl .pointer {
		left: 0%;
		position:absolute;
		top: 0.1875em;
		cursor: pointer;
		background-color: #ebebeb;
		border-radius: 1.5em;
		margin-left: -0.75em;
		height: 1.5em;
		width: 1.5em;
		box-shadow: 0.125em 0.125em 0 rgba(0, 0, 0, 0.15);
	}

.player .debug {
	font-size: 1.5em;
	color: green;
}

.player .debug:before {
	content: 'Debug Info:';
	display:block;
}

.volumeControl {
	position:absolute;
	height: 2.75em;
	top: 0.25em;
	right: 3em;
	width: 2.75em;
}

	.volumeControl .volumeButton {
		background: url("../lib/newPlayer/images/volume.png") no-repeat center 0.25em;
		background-size: 2.75em 5.5em;
		color: transparent;
		height: 2.75em;
		position: absolute;
		width: 2.75em;
		top: -0.25em;
		padding-top: 0.25em;
		cursor: pointer;
	}

		.volumeControl.muted .volumeButton { background-position: center -2.5em; }

		.volumeControl.muted .volumeLevel { height: 0 !important; }

	.audioDisabled .volumeButton {
		opacity: 0.5;
	}

	.volumeControl .volumeButton:before {
		content: 'Volume';

		/* to keep invisible text from stretching the box */
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.volumeControl.muted .volumeButton:before {
		content: 'Volume (muted)';
	}


	.volumeControl .volumeBar {
		position: absolute;
		display: none;
		height: 6em;
		width: 2em;
		bottom: 3em;
		left: 0.375em;
		z-index: 2;
		background-color: #ced7d7;
		cursor: pointer;
	}

		.volumeControl .volumeBar .volumeLevel,
		.volumeControl .volumeBar .volumeLevelBG {
			position: absolute;
			bottom: 0;
			width: 50%;
			left: 0;
			margin: 0.5em 0.5em 0;
		}

		.volumeControl .volumeBar .volumeLevel {
			background-color: #ebc600;
			z-index: 2;
		}
		.volumeControl .volumeBar .volumeLevelBG {
			background-color: #a7a9a9;
			z-index: 1;
			height: 5.5em;
		}

.thumbnailPreview {
	 display: none;
	background-size: 100% auto;
	position: absolute;
    bottom:  0;
    left:  0;
    top: 0;
    right:  0;
    z-index: 2;
    background-color: black;
}

.thumbnailPreview img {

	width: 100%;
}

#callbacks {
	 border: 0.0625em solid #000000;
    float: right;
    height: 600px;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
}

.logDisplay {
	display: none;

	background-color: #ededed;
    height: 2em;
    border-style: solid;
    border-color: #ededed;
    border-width: 0.46em 0 0.54em;
    top: 10.5em;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 2147483647;
    margin-top: 0.25em;

	border-collapse: collapse;
    font-size: 0.8125em;
    font-weight: bold;
    line-height: 1em;

    border-radius: 0 !important;

}

	.player.fullScreen .logDisplay { display: block; }
	/*.player-container.fullScreen .controls { margin-top: 2.625em ;}*/

	.logDisplay span {
		padding: 0 0.5em;
		vertical-align: top;
	}

	.logDisplay span.timecode {
		width: 4em;
		height:100%;
		float:left;
	}

	.logDisplay span.desc {
		display: block;
		padding-left: 5em;
    }

	.logDisplay.overflowing td.desc {
		padding-right: 1.25em;
	}

		.logDisplay.overflowing td.desc:after {
			content:'...';
			position:absolute;
			bottom: 0;
			right: 0.5em;
			padding-left: 0.125em;
			background-color: #ededed;
		}

.tcOverlay{
    position: absolute;
    top: 50px;
    left: 50%;
    text-align: center;
    z-index: 2147483649;

	background-color: var(--hf-background-color);
	border-radius: var(--hf-border-radius);
	transform: translateX(-50%);
	padding: 1em 1em 2em;
	box-shadow: var(--hf-modal-shadow);
}

	.tcOverlay .tcSpinner {
		/* font-family: Consolas, monospace; */
		font-family: monospace;
		padding: 0 0 0.5em 0;
		white-space: nowrap;
	}

		.tcOverlay .ui-spinner {
			display: inline-block;
			padding: 3.5em 0.25em;
			position: relative;
		}

			.tcOverlay .tcOverlayField {
				width: 2em;
				font-size: 1.5em;
				/* font-family: monospace; */
				vertical-align: middle;
				text-align: center;
				margin: 0.25em 0;
				padding: 10px;
				background-color: var(--hf-input-hover-bg-color);
				color: var(--hf-input-hover-color);
				border-radius: var(--hf-border-radius);
				border: 1px solid transparent;
				-webkit-appearance: none;
				box-shadow: var(--hf-input-field-shadow);
			}

				.tcOverlay .tcOverlayField:focus-visible {
					border: 1px solid var(--hf-accent-color);
					outline-color: transparent;
					outline: none;
					box-shadow: var(--hf-input-field-focus-shadow);
				}			

			.tcOverlay .ui-spinner-button {
				left: 50%;
				margin-left: -1.5em;
				position: absolute;
			}

				.tcOverlay .ui-spinner-up { top: 0; }
				.tcOverlay .ui-spinner-down { bottom: 0; }

				.tcOverlay .ui-spinner-button .ui-icon {
					border-left: 0.375em solid transparent;
					border-right: 0.375em solid transparent;
					color: transparent;
					display: block;
					font-size: 3em;
					height: 0;
					width: 0;
					margin: 0.25em 0.125em;
				}

					.tcOverlay .ui-spinner-up .ui-icon { border-bottom: 0.75em solid var(--hf-button-text-color); }
					.tcOverlay .ui-spinner-down .ui-icon { border-top: 0.75em solid var(--hf-button-text-color); }

	#videoWindow .tcOverlay a.button {
		margin: 1em 0 0;
	}

	.underlay {
		position: fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index: 2147483648;
		background-color: black;
		opacity: 0.2;
	}

	.pointField .tcTextField {
		position:relative;
	}

	.textProgress {
		position:relative;
	}

	.touchShield {
		background-color: white;
		bottom: -0.5em;
		left: -0.5em;
		opacity: 0;
		position: absolute;
		right: -0.5em;
		top: -0.5em;
	}

.iosPlayerContainer {
	position:fixed;
	left: -1000px;
	top:0;
	background-color:#000;
	z-index: 300;
}

.iosPlayerContainer.playing {

}

.iosPlayerContainer video {
		height:100% !important;
		width:100% !important;
	}



.player .controls .playbackControls {
	position: absolute;
	bottom: -0.625em;
	color: white;
	height: 3em;
	background-color: rgba(255,255,255,0.5);
	width: 100%;
	z-index: 3;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s;
}

	.player.hover .controls .playbackControls {
		visibility: visible;
		opacity: 1;
	}

	

	.player .playButton {
		/*
		width: 2.5em;
		height: 1.375em;
		background: url('images/play.png') center top no-repeat;
		background-size: 1.375em 1.375em;
		*/
		background: transparent;
		border-bottom: 1.25em solid transparent;
		border-top: 1.25em solid transparent;
		border-left: 2.5em solid #222424;

		color: transparent;
		overflow: hidden;

		position: absolute;
		left: 0.5em;
		top: 0.625em;
		height: 0;
		width: 0;
		cursor: pointer;
	}

		.player .playButton:hover { border-left-color: #ebebeb; }

		/* .player .playButton.playing { background-image: url('images/pause.png'); } */
		/*
		.player .playButton.playing {
			height: 2em;
			width: 0.5em;
			margin: 0.25em 0 0 0;
			border-top: none !important;
			border-bottom: none !important;
			border-left: 0.75em solid #222424;
			border-right: 0.75em solid #222424;
		}

			.player .playButton.playing:hover {
				border-left-color: #ebebeb;
				border-right-color: #ebebeb;
			}
		*/

		.player .playButton.touched:hover,
		.player .playButton.touched.playing:hover{
			border-left-color: #222424;
			border-right-color: #222424;
		}

		.player .playButton:before { content: 'Play'; }
		.player .playButton.playing:before { content: 'Pause'; }

	.player .controls .speedControl {
		position: absolute;
		top: 0;
		right: 7em;
		width: 3em;
		margin-top: 0.75em;
	}
		.player .controls .speedControl select {
			width: 100%;
		}

		.player .controls .speedControl .options {
			background-color: rgba(0,0,0,0.5);
		}
		.player .controls .speedControl .options .option {
			cursor: pointer;
		}

		.player .controls .speedControl .options.hidden {
			display: none;
		}

		.player .controls .speedControl .options .selected {
			display: none;
		}

		.player .controls .speedControl a.selection {
			color: #fff;
		}

.player.fullClip .clipSelection{
	display: none;
}

/*
.player.fullClip .clip .pointField{
	display: none !important;
}
*/

.clipSelection {
	color: var(--hf-button-text-color);
	text-align: center;
}

.clipSelection::before {
		content: 'Clip selection:';
		margin-right: 0.75em;

}
	.clipSelection .pointField {
		display: inline;
		position: relative;
		top: 0;
	}

	.clipSelection .pointField.outPoint:before {
		content:  ' - ';
	}

	.clipSelection .clipDuration:before {
		content:  '(';
	}

	.clipSelection .clipDuration:after {
		content:  ')';
	}

.player .jumpToClip {
	top: 6.5em;
	left: 0;
	position: absolute;
}

	.player .jumpToClip button {
		display: block;
	}



/* 2.0 edits */





/* unset old */ /*?*/
table.textLog tr.current, 
table.textLog tr.current.even, 
table.textLog tr.current.subSelect.even, 
table.textLog tr.current.odd { background-color: unset !important; }


.videoWindow { overflow: auto; }

.videoPlayer {
	width: unset; /*?*/
	background-color: var(--hf-background-color);
	color: var(--hf-text-color);
	font-family: var(--hf-body-font);
}

	.videoPlayer .videoPlayerInner {
		padding: 0 var(--hf-standard-margin) 100px;
	}

	.videoPlayer #navigationControls {
		/* margin: 0 calc(var(--hf-standard-margin) * -1); */

		padding-top: 112px;
		z-index: 13;
		/* position: relative; */
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: var(--hf-background-color);

		/* margin: 0 calc(var(--hf-standard-margin) * -1) 25px; */
		margin: 0;
		align-items: center;
	}

		.videoPlayer #navigationControls::before { /* spacer for when next/prev buttons aren't there */
			content: '';
			display: block;
			height: 46px;
		}

		.videoPlayer #navigationControls::after {
			z-index: -1;
			content: '';
			background-color: var(--hf-input-hover-bg-color);
			position: absolute;
			top: 183px;
			bottom: 0;
			left: 0;
			right: 0;
		}		

		.videoPlayer #navigationControls .closeContainer { 
			/* top: 170px; */
			/*
			top: 188px;
			bottom: 30px;
			*/
			height: unset;
			display: flex;
			justify-content: center;
			align-items: center;

			margin-right: var(--hf-standard-margin);
			grid-row: 2;
			grid-column: 5;
			position: relative;
			right: unset;
			margin-left: calc((var(--hf-standard-margin) *-1) - 15px);
		}

			.videoPlayer .headerContainer.fixed + #navigationControls .closeContainer {
				top: 0;
				grid-row: 1;
				margin-left: 20px;
			}

			.videoPlayer #navigationControls .closeContainer a.close {
				position: relative;
				bottom: unset;
			}

		.videoPlayer #navigationControls h1 {
			grid-row: 2;
			/* position: absolute; */
			/* grid-column: 1 / 4; */
			/* top: 30px; */
			/* left: 790px; */
			/* min-width: 390px; */			
			/* right: 100px; */
			margin: 0 !important;

			font-family: var(--hf-body-font);
			font-weight: 700;
			font-style: normal;
			font-size: 30px;
			color: white;
			vertical-align: bottom;
			padding: 0;
			line-height: normal;
			margin-bottom: -3px; /* this font at a weight of 700 has vertical alignment issues */

			grid-column: 1;
			padding-left: calc(var(--hf-standard-margin) * 1.75);
			align-self: center;
			box-sizing: border-box;
			white-space: nowrap;
		}		

		.videoPlayer .matches {
			font-family: unset; /*?*/
			font-style: normal; /*?*/
			text-align: unset; /*?*/
			display: grid;
			grid-template-columns: 1fr auto auto;

			grid-template-columns: min-content 1fr min-content min-content min-content;
			row-gap: 25px;
			grid-template-rows: min-content min-content;
		}	

			.videoPlayer .matches .navBtn {
				align-items: center;
				background-color: var(--hf-background-color);
				border-radius: var(--hf-border-radius);
				border: 1px solid rgba(41,41,41,0.1); /*?*/
				box-shadow: var(--hf-button-shadow);
				box-sizing: border-box;
				color: var(--hf-button-text-color);
				cursor: pointer;
				display: flex;
				font-family: var(--hf-body-font);
				font-size: var(--hf-button-font-size);
				font-weight: 500;
				line-height: 1.125;
				margin-left: 15px;
				min-height: var(--hf-button-min-height);
				padding: 5px 15px;
				position: relative;		
				text-transform: uppercase;
				transition: box-shadow 0.2s ease, color 0.2s ease, left 0.2s ease, top 0.2s ease;

				white-space: nowrap;
			}

				.videoPlayer .matches .navBtn:hover:not(.disabled) { 
					color: var(--hf-button-text-hover-color); 
					box-shadow: var(--hf-button-hover-shadow);
				}

				.videoPlayer .matches .navBtn:focus-visible {
					box-shadow: var(--hf-button-focus-shadow);
					outline: none;
				}

				.videoPlayer .matches .navBtn:active {
					box-shadow: var(--hf-button-active-shadow) !important;
					left: 1px;
					top: 1px;
				}

				.videoPlayer .matches .navBtn.disabled { opacity: var(--hf-button-disabled-opacity); }

				.videoPlayer .matches .navBtn.prev { 
					/* grid-column: 2; */
					grid-column: 3; 
					padding-left: 36px; 
				}
				.videoPlayer .matches .navBtn.next { 
					/* grid-column: 3; */
					grid-column: 4; 
					padding-right: 36px; 
					/* margin-right: var(--hf-standard-margin); */
					/* margin-right: 56px; */
				}

				.videoPlayer .matches .navBtn::before {
					position: absolute;
					font-family: var(--hf-icon-font);
					font-size: 24px;
					font-weight: 900;
					top: 0;
					bottom: 0;
					display: flex;
					align-items: center;
				}

					.videoPlayer .matches .navBtn.prev::before {
						left: 13px;
						content: '\f0d9';
					}

					.videoPlayer .matches .navBtn.next::before {
						right: 13px;
						content: '\f0da';
					}

					.videoPlayer .matches .navBtn .objName::before { content: '\00a0'; /* nbsp */ } 

			.videoPlayer .matches .matchInfo {
				color: var(--hf-button-text-color);
				font-size: 20px;
				font-weight: 500;
				font-size: 18px;
				font-weight: 400;
				/* grid-column: 1 / 4; */
				/*
				margin: 20px 90px 40px 50px;
				padding: 0;
				*/

				padding: 12px 90px 12px 50px;
				/* margin: 25px 0 25px 0; */
				/* background-color: var(--hf-input-hover-bg-color); */
				min-height: 21px;

				grid-column: 2 / 5;
				margin: 0;
				grid-row: 2;
				overflow: hidden;

				padding: 12px 50px;
				margin: 0 40px 0 0;

				white-space: nowrap;
				text-overflow: ellipsis;
				padding: 12px 35px 12px 50px;
				margin: 0;

			}
				.videoPlayer .matches .matchInfo > span {
					/*
					display: flex;
					align-items: center;
					gap: 0.25em;
					white-space: nowrap;
					*/
					font-style: italic;
				}
				.videoPlayer .matches .matchInfo > span + span::before {
					content: ' ';
				}

			body.reel .videoPlayer .headerContainer:not(.fixed) + .matches { min-height: 82px; }
			body.reel .videoPlayer .matches > * { display: none; }

			/* condensed, fixed header */			

			.videoPlayer .headerContainer { 
				background-color: transparent; 
				pointer-events: none; /* so as not to block next/prev buttons */
			}
			.videoPlayer .headerContainer .headerTop > * { pointer-events: all; }
			.videoPlayer .headerContainer.fixed { 
				background-color: var(--hf-background-color);
				box-shadow: none; 
				padding-bottom: 0;
			}
			.videoPlayer .headerContainer.fixed + #navigationControls {
				/* grid-template-columns: 1fr auto auto auto; */
				grid-template-columns: min-content 1fr auto auto auto;
				grid-template-rows: auto;
				position: fixed;
				top: 77px;
				left: 0;
				right: 0;
				margin: 0;
				background-color: var(--hf-background-color);
				align-items: center;
				/* padding-top: 0; */
				padding-top: 10px;
				padding-bottom: 5px;
				box-shadow: var(--hf-overlay-edge-shadow);
				transition: none;
			}
				.videoPlayer .headerContainer.fixed + #navigationControls::before { /* spacer for when next/prev buttons aren't there */
					display: none;
				}			
			.videoPlayer .headerContainer.fixed + .matches .closeContainer {
				position: relative;
				bottom: unset;
				/* grid-column: 4; */
				grid-column: 5;
				height: 36px;
				right: 0;
			}
			.videoPlayer .headerContainer.fixed + .matches .matchInfo {
				/* grid-column: 1; */
				grid-column: 2;
				grid-row: 1;
				/*
				margin-top: 10px;
				margin-bottom: 10px;
				margin-top: 0;
				margin-bottom: 0;
				*/
			}
				.videoPlayer .headerContainer.fixed + .matches .matchInfo::before {
					position: absolute;
					top: 7px;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: var(--hf-input-hover-bg-color);
					content: '';
					z-index: -1;
					display: block;
				}			
			.videoPlayer .headerContainer.fixed + .matches .navBtn { 
				grid-row: 1; 
				min-height: 32px;
			}
			.videoPlayer .headerContainer.fixed + #navigationControls.matches h1.tapeId { 
				/*
				grid-column: 2;
				position: relative;
				left: unset;
				right: unset;
				top: unset;
				text-align: center;
				*/
				grid-row: 1; 
			}
			.videoPlayer .headerContainer.fixed + .matches .navBtn.prev { grid-column: 3; }
			.videoPlayer .headerContainer.fixed + .matches .navBtn.next { grid-column: 4; }
			.videoPlayer .headerContainer.fixed ~ .playerArea {
				padding-top: 253px;
				padding-top: 232px;
				padding-top: 168px;
				padding-top: 143px;
				padding-top: 25px;
			}



		.videoPlayer .reelInfo {
			display: flex;
			align-items: center;
			/* margin-bottom: 40px; */

			margin-bottom: 20px;
			grid-row: 1;
			grid-column: 2;
			flex-direction: column;
			/* padding: 0 35px 0 80px; */
			padding: 0 40px 0 80px;
		}

			.videoPlayer .headerContainer.fixed ~ .reelInfo { padding-top: 272px; } /* 242 for header, 30 for space */

					body.reel .videoPlayer .headerContainer.fixed ~ .reelInfo { padding-top: 180px; } /* 150 for header, 30 for space */

			.videoPlayer .reelInfo .specs { 
				display: flex; 
				flex-shrink: 0;

				width: 100%;
				padding-left: 40px;
				/* margin-bottom: var(--hf-standard-margin); */
				margin-bottom: calc(var(--hf-standard-margin) / 2);
			}

				.videoPlayer .reelInfo .specs .tapeId {
					font-family: var(--hf-body-font);
					font-weight: 700;
					font-style: normal;
					font-size: 30px;
					color: white;
					vertical-align: bottom;
					padding: 0;
					display: block !important;
					margin-right: 25px;
					flex-shrink: 0;
					align-content: end;
					line-height: normal;
					margin-bottom: -3px; /* this font at a weight of 700 has vertical alignment issues */
				}

				.videoPlayer .reelInfo .specs .tapeFields { 
					margin-right: 50px; 
					display: flex;
					align-items: end;
				}

					.videoPlayer .reelInfo .specs .tapeField {
						color: var(--hf-secondary-text-color);
						font-weight: 500;
						font-size: 18px;
						white-space: nowrap;
					}

					.videoPlayer .reelInfo .specs .tapeField:not(:empty) + .tapeField:not(:empty)::before {
						content: ', ';
					}


			.videoPlayer .reelInfo div.description {
				color: white;
				font-weight: 400;
				font-size: 18px;
				line-height: 1.333;
				position: relative;
				width: 100%;
				flex-shrink: 1;
			}

				.videoPlayer .reelInfo div.description.expandable { height: 72px; }

				.description.expandable .more {
					display: block;
					position: absolute;
					z-index: 10;
					text-transform: uppercase;
					font-size: 14px;
					width: 8em;
					text-align: center;
					bottom: 0;
					border-radius: 5px;
					left: 50%;
					transform: translateX(-50%);
					background-color: var(--hf-faint-text-color);
					color: black;
					opacity: 1;
					transition: var(--hf-standard-transition);
						
				}

					.description.expandable.hover .more { opacity: 0; }


				.videoPlayer .reelInfo div.description .description {
					display: block;
					max-height: 64px;
					transition: var(--hf-standard-transition);
					z-index: 10;
					border-radius: var(--hf-border-radius);
					box-shadow: 0 0 0 #0000;
					overflow-y: hidden;
					background-color: var(--hf-background-color);
					padding: 0 20px;
					left: 0;
					right: 0;
					font-size: 16px;
				}

					.videoPlayer .reelInfo div.description.expandable .description {
						position: absolute;
					}

						.videoPlayer .reelInfo div.description.expandable.hover .description {
							max-height: calc(100vh - 100px);
							box-shadow: 4px 4px 6px #00000080; /*?*/
							padding: 17px 20px;
							background-color: var(--hf-darker-background-color);
							overflow-y: auto;
						}


@media screen and (max-width: 1150px) {
	.videoPlayer .headerContainer.fixed { 
		/* max-height: 85px; */
		padding-bottom: 10px;
	}
	.videoPlayer .headerContainer.fixed .searchBar,
	.videoPlayer .headerContainer.fixed .homeSearchBar {
		display: none;
	}

	.videoPlayer .headerContainer.fixed + #navigationControls {
		top: 145px;
		top: 81px;
	}

	.videoPlayer .headerContainer.fixed + #navigationControls .closeContainer {
		margin-left: 20px;
		margin-right: calc(var(--hf-standard-margin) / 2);
	}

	.videoPlayer .headerContainer.fixed + #navigationControls h1 {
		padding-left: var(--hf-standard-margin);
		font-size: 24px;
	}	
	.home #videoWindow .headerContainer.fixed #headerTop .homeSearchBar {
		grid-column: 1 / 6;
		grid-row: 2;
	}
	.videoPlayer .headerContainer.fixed + #navigationControls .matchInfo {
		font-size: 16px;
		min-height: 18px;
	}

}

@media screen and (max-width: 960px) {
	.videoPlayer .headerContainer.fixed + .matches .navBtn .objName { display: none; }
}

@media screen and (max-width: 768px) {
	.videoPlayer .headerContainer.fixed + .matches .matchInfo .viewing { display: none; }
	.videoPlayer .headerContainer.fixed + .matches .matchInfo .verb,
	.videoPlayer .headerContainer.fixed + .matches .matchInfo .noun { text-transform: capitalize; }
}

@media screen and (max-width: 640px) {
	.videoPlayer .headerContainer.fixed + .matches .matchInfo { padding-left: 30px; padding-right: 15px; } 
	.videoPlayer .headerContainer.fixed + .matches .matchInfo .verb,
	.videoPlayer .headerContainer.fixed + .matches .matchInfo .noun { display: none; }
	.videoPlayer .headerContainer.fixed + .matches .navBtn .text { display: none; }
	.videoPlayer .headerContainer.fixed + .matches .navBtn.prev { padding-right: 0; }
	.videoPlayer .headerContainer.fixed + .matches .navBtn.next { padding-left: 0; }
}


		.videoPlayer .playerArea {
			padding-top: 25px;
		}

			.videoPlayer .playerAreaInner {
				display: grid;
				grid-template-columns: auto 1fr;
			}

				.videoPlayer.no-video .playerAreaInner {
					/* grid-template-rows: min-content 0 auto; */
					grid-template-rows: min-content min-content 1fr;
				}

				.videoPlayer .player {
					height: unset; /*?*/
					justify-self: center;
					margin: unset !important; /*?*/
					padding-bottom: 120px;
					top: unset; /*?*/
					width: 640px;
					transition: width 0.2s ease-in-out;
				}

					.videoPlayer .clipTools { 
						width: 640px; 
						box-sizing: border-box;
					}

					@media screen and (max-width: 1280px) {
						.videoPlayer .player,
						.videoPlayer .clipTools {
							max-width: 640px;
							width: 100%;
						}
					}

					.videoPlayer.no-video .player { padding-bottom: 40px; }

				.videoPlayer .player,
				.videoPlayer .hiddenPlayer {
					/* grid-row: 1; */
					position: relative;
					z-index: 2;
					grid-row: 1 / 3;
					grid-column: 1;
				}
				.videoPlayer .player ~ .mobileTools,
				.videoPlayer .hiddenPlayer ~ .mobileTools {
					grid-row: 2;
				}

					

					.videoPlayer .accessDetails {
						background-color: var(--hf-panel-bg-color);
						border: 1px solid transparent; /* to force BG color rendering in children's margins*/
						font-weight: 500;
						display: none;
						position: relative;
					}
						.videoPlayer .accessDetails .mos,
						.videoPlayer .accessDetails .info,
						.videoPlayer .accessDetails .specialAccess,
						.videoPlayer .accessDetails .specialAccessExpired,
						.videoPlayer .accessDetails .standardText
						{
							display: none;
						}

						.videoPlayer.mos .accessDetails .mos {
							display: inline;
						}


						.videoPlayer.specialAccess .accessDetails,
						.videoPlayer.specialAccessExpired .accessDetails {
							display: block;
						}

						.videoPlayer.specialAccess .accessDetails .specialAccess {
							display: inline;
						}

						.videoPlayer.standardAccess .accessDetails .standardText {
							display: inline;
						}

						.videoPlayer.specialAccessExpired .accessDetails .specialAccessExpired {
							display: inline;
						}

						.videoPlayer:not(.no-video) .accessDetails .thumb {
							display: none;
						}

						.videoPlayer.specialAccess.specialty .accessDetails .specialty {
							display: inherit;
						}

						.videoPlayer.restricted:not(.temporailyUnavailable) .accessDetails .restricted {
							display: inherit;
						}

						.videoPlayer.temporailyUnavailable .accessDetails .temporailyUnavailable {
							display: inherit;
						}

						.videoPlayer.specialty:not(.temporailyUnavailable) .accessDetails .specialty {
							display: block;
						}

						.videoPlayer.no-video.standardAccess:not(.specialty) .accessDetails .no-video {
							display: block;
						}
						.videoPlayer.no-video.standardAccess:not(.specialty) .accessDetails .temporailyUnavailable {
							display: none;
						}

						

						.videoPlayer.no-video .accessDetails { display: block; }

							/* .videoPlayer.no-video:not(.temporailyUnavailable.specialAccessExpired):not(.temporailyUnavailable.specialty) .accessDetails .thumb ~ div { */
							.videoPlayer.no-video .accessDetails .thumb ~ div {
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								background-color: #4a4a4aaa;
								left: 40px;
								right: 40px;
								max-height: 95%;
								overflow: auto;
							}	

							/* for compound messages */
							/*
							.videoPlayer.no-video.temporailyUnavailable.specialAccessExpired .accessDetails,
							.videoPlayer.no-video.temporailyUnavailable.specialty .accessDetails { display: grid; }
							.videoPlayer.no-video.temporailyUnavailable.specialAccessExpired .accessDetails .thumb,
							.videoPlayer.no-video.temporailyUnavailable.specialty .accessDetails .thumb { grid-row: 3; }
							.videoPlayer.no-video.temporailyUnavailable.specialAccessExpired .accessDetails .info,
							.videoPlayer.no-video.temporailyUnavailable.specialty .accessDetails .info { margin-top: 0; }
							.videoPlayer.no-video.temporailyUnavailable.specialAccessExpired .accessDetails .restricted > *:first-child,
							.videoPlayer.no-video.temporailyUnavailable.specialty .accessDetails .restricted > *:first-child { margin-top: 0; }
							*/


						.videoPlayer .accessDetails img {
							width: 100%;
							display: block;
						}


						.videoPlayer .accessDetails > div > p {
							padding: 0 45px;
						}

						.videoPlayer .accessDetails > div > p:nth-child(2) { margin-top: -1em; }
						.videoPlayer .accessDetails > div > p:last-child { margin-bottom: 40px; }

						.videoPlayer .accessDetails > div > p:first-child {
							background-color: var(--hf-inner-panel-bg-color);
							border-radius: var(--hf-border-radius);
							padding: 20px 40px 20px 100px;
							position: relative;
							margin: 40px 45px;
							min-height: 42px;
						}

							@media screen and (max-width: 960px) {
								.videoPlayer:not(.no-video) .accessDetails > div > p:first-child { margin: 0 0 var(--hf-standard-margin); }
							}

							.videoPlayer .accessDetails > div > p:first-child::before {
								background-color: var(--hf-notice-background);
								border-radius: var(--hf-border-radius);
								color: var(--hf-background-color);
								font-size: 20px;
								line-height: 1.125em;
								box-sizing: border-box;
								width: 2.75rem;
								padding: 0.75rem 0.25rem;
								transition: var(--hf-standard-transition);
								height: 2.625rem;
								display: flex;
								align-items: center;
								font-weight: 900;
								font-family: var(--hf-icon-font);
								justify-content: center;
								position: absolute;
								left: 1.75em;
							}

							.videoPlayer .accessDetails > div.specialty > p:first-child::before {
								color: var(--hf-highlight-color-darker);
								content: "\e235";
							}

							.videoPlayer .accessDetails > div.noVideo > p:first-child::before,
							.videoPlayer .accessDetails > div.no-video > p:first-child::before {
								content: "\f4e2"
							}

							.videoPlayer .accessDetails > div.temporailyUnavailable > p:first-child::before {
								content: "\f4e2"
							}

							.videoPlayer .accessDetails > div.restricted > p:first-child::before {
								content: "\f4e2"
							}

							.videoPlayer .accessDetails .remaining { color: var(--hf-highlight-color); }


							@media screen and (max-width: 1100px) and (min-width: 960px), (max-width: 640px) {
								.videoPlayer .accessDetails > div > p:first-child { margin: 20px; padding: 20px; }
								.videoPlayer .accessDetails > div > p { padding: 0 20px; }
								.videoPlayer .accessDetails > div > p:nth-child(2) { margin-top: 1em; }
								.videoPlayer .accessDetails > div > p:last-child { margin-bottom: 20px; }
								.videoPlayer.no-video .accessDetails .thumb ~ div { left: 10px; right: 10px; }
								.videoPlayer .accessDetails > div > p:first-child::before {
									position: relative;
									float: left;
									left: 0;
									margin: 0 1em 0.5em 0;
								}							
							}




					.videoPlayer .player .playerDiv { border-radius: unset; }

				.videoPlayer .player + .info {
					flex-grow: 1;
					/* 
					grid-row: 1 / 3;
					padding-left: 120px;
					 */
					left: unset;
					/* padding-right: 55px; */
					padding-right: 60px;
					position: relative;
					right: unset;

					min-width: 360px;

					grid-row: 2 / 4;
					padding-left: 100px;
					grid-column: 2;
				}

					.videoPlayer.no-video .player + .info { 
						min-height: calc(100vh - 550px); /* leave enough room for the content below to appear just above the old */
					}

						/* to make the Video tab smaller when there's no video
						@media screen and (max-width: 960px) {
							.videoPlayer.no-video .player + .info.video { min-height: 19rem; }	
							.videoPlayer.no-video .player + .info.video .playerPlaceholder { display: none; }
						}
						*/

					.videoPlayer .info .infoInner {
						margin-bottom: 1em;
						height: 100%;
						display: grid;
						grid-template-rows: auto 1fr;
						box-shadow: var(--hf-panel-shadow);
						border-radius: var(--hf-outer-border-radius);
					}

						.videoPlayer .tabs {
							font-family: unset;
							font-weight: 500;
							font-size: 18px;
							line-height: 1;
							display: flex;
						}

							.videoPlayer .tabs a {
								padding: 15px 25px;
								margin: 0;
								display: flex;
								align-items: center;
								flex-grow: 1;
								justify-content: center;
							}


								.videoPlayer .player + .info .tabs a {
									background-color: var(--hf-darker-background-color);
									color: var(--hf-button-text-color);
								}

									.videoPlayer .player + .info .tabs a:hover { background-color: var(--hf-tab-bg-hover-color) !important; }
									.videoPlayer .player + .info .tabs a.active { 
										background-color: var(--hf-panel-bg-color) !important; 
										color: white;
									}

									.videoPlayer div.info.log .tabs a.log,
									.videoPlayer div.info.details .tabs a.details,
									.videoPlayer div.info.thumbnails .tabs a.thumbnails,
									.videoPlayer div.info.video .tabs a.video { 
										background-color: var(--hf-panel-bg-color) !important; 
										color: white;
									}

									.videoPlayer div.info .tabs a .short { display: none; }

						.videoPlayer .tabPanel {
							background-color: var(--hf-panel-bg-color);
							margin: 0 0 1em;
						}




				.videoPlayer .player ~ .mobileTools { /*?*/
					position: unset;
					display: unset;
					left: unset;
					right: unset;
					top: unset;
				}




.videoPlayer .tabPanel .thumbnails span.thumbBtn {
	display: inline-block;
	height: 60px;
	width: 80px;
	overflow: hidden;
	margin: 5px;
	transition: var(--hf-standard-transition);
	cursor: pointer;
}

.videoPlayer .tabPanel .thumbnails img {
	height: 60px;
	border-radius: unset;
	border: none;
	transform: scale(var(--hf-overscan-amount));
	object-fit: cover;
}


.videoPlayer .player + .info .tabPanel .thumbnails {
		left: 0;
		right: 0;
}

.videoPlayer .tabPanel .thumbnails {
		top: 0;
		bottom: 0;
		padding: 5px;
}
.videoPlayer div.textLog {
		left: 0;
		right: 0;
		bottom: 0;
}

.videoPlayer div.textLog {
		background-color: var(--hf-panel-background-color);
		left: 0;
		right: 0;
		bottom: 0;
}

.videoPlayer div.transcript {
		background-color: var(--hf-panel-background-color);
		left: 0;
		right: 0;
		bottom: 0;
}

table.textLog {
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
		background-color: var(--hf-background-color);
		color: #e0e0e0;
}

table.textLog td.timecode {
		font-weight: 500;
}



.videoPlayer .tabs {
		border-radius: var(--hf-border-radius) var(--hf-border-radius) 0 0;
		overflow: hidden;
}

.videoPlayer .tabPanel {
		margin: 0;
		border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius);
}


.videoPlayer div.textLog {
		border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius);
		/* overflow-y: unset; */
}

.videoPlayer div.textLog .logWrapper {
		/* overflow: auto; */
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transition: var(--hf-standard-transition);
}

.videoPlayer .tabPanel .log { 
	text-align: left; 
	padding: 16px 24px;
}

.videoPlayer input[type=checkbox] ~ div.textLog,
.videoPlayer .checkbox ~ div.textLog,
.videoPlayer .lockWithLog ~ div.textLog { top: 60px; }

.videoPlayer .tabPanel .log .logMarker {
		background-color: var(--hf-accent-color);
		text-transform: none;
		font-size: 16px;
		font-weight: 400;
		color: white;
		white-space: nowrap;
		padding: 1em 0.5em;
		line-height: 1.1875em;
		box-shadow: 4px 4px 6px #00000080;
		width: 60px; /* to make them even with each other */
		overflow: visible;
}

.videoPlayer .tabPanel .log .markerContainer #clipStart { text-align: left; }
.videoPlayer .tabPanel .log .markerContainer #clipEnd { text-align: right; }

.videoPlayer .tabPanel .log .markerContainer #clipStart::before {
		border-left: 1.75em solid var(--hf-accent-color);
}

.videoPlayer .tabPanel .log .markerContainer #clipEnd::before {
		border-right: 1.75em solid var(--hf-accent-color);
}

.videoPlayer .tabPanel .log .markerContainer a::before, 
.videoPlayer .tabPanel .log .markerContainer a::after {
		border-bottom: 2.2em solid transparent;
		border-top: 2.2em solid transparent;
}
.videoPlayer .tabPanel .log .logMarker:hover { 
	color: black; 
	background-color: var(--hf-highlight-color);
}

.videoPlayer .tabPanel .log .logMarker.notransition:hover { 
	background-color: var(--hf-accent-color);
	color: white;
}

.videoPlayer .tabs a::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;	
	font-family: "Font Awesome 6 Pro";
	text-transform: none;
	margin-right: 0.5em;
	font-size: 28px;
}

.videoPlayer .tabs a.thumbnails::before {
	/* content: '\e195'; */
	content: '\f03e';
	font-weight: 900;
}

.videoPlayer .tabs a.log::before {
	content: '\f03a'; /* FA 6.0 alpha */
	content: '\f00b'; /* FA 6.0 alpha */
	font-weight: 900;
}
.videoPlayer .player + .info .tabs a:hover { color: white; }


.videoPlayer .tabs a.video::before {
	content: '\f008';
	font-weight: 900;
}




#videoWindow.popup {
	left: 0 !important;
	top: 228px !important;
	right: 0 !important;
	bottom: 0 !important;
	/* overflow: auto; *//* moved to .videoWindowContents because overflow and fixed pos in Safari/iOS */
	                     /* hides anything that has fixed pos outside this box (like the header)       */
}
	html.fixedHeader #videoWindow.popup { top: 137px !important; }

	#videoWindow.popup .videoWindowContents {
		max-height: 100%;
		overflow: auto;
	}

table.textLog td:first-child {
		padding-left: 18px;
		border-left: 6px solid transparent;
}

table.textLog td:last-child {
		padding-right: 18px;
		border-right: 6px solid transparent;
}

table.textLog td.timecode {
		font-size: 18px;
}

.videoPlayer .tabPanel .log label { 
	color: #ccccccb3; 
	font-size: 18px;
}

.videoPlayer .tabPanel .log .checkbox {
		margin-right: 21px;
}


.videoPlayer .tabPanel .log input[type="checkbox"], 
.videoPlayer .tabPanel .log .checkbox {
		vertical-align: baseline;
}

.videoPlayer .tabPanel .log .checkbox label {
		width: 1em;
		height: 1em;
		top: -0.5em;
		left: -0.5em;
		border: 0.5em solid #4a4a4a;
}

.videoPlayer .tabPanel .log .checkbox label::after {
		width: 0.5em;
		height: 0.23em;
		top: 0.25em;
		left: 0.2em;
}

/*
table.textLog tr.current.even,
table.textLog tr.current.subSelect.even, 
table.textLog tr.current.odd, 
html table.textLog tr.current:hover,
table.textLog tr.current, 
table.textLog tr.current.subSelect {
	background-color: #686868 !important;
		background-color: #303030 !important;
}
table.textLog tr.current.even td,
table.textLog tr.current.odd td, 
html table.textLog tr.current:hover td,
table.textLog tr.current td {
	border-color: #303030 !important;
}
*/

table.textLog tr.subSelect {
	font-weight: bold;
	background-color: #42545b !important;
}
table.textLog tr.subSelect.even {
	background-color: #495a61 !important;
}
table.textLog tr.subSelect td:first-child,
table.textLog tr.subSelect td:last-child {
	border-color: var(--hf-accent-color) !important;
}
table.textLog tr.current { font-weight: bold; }


/*
table.textLog tr.current.even, 
table.textLog tr.current.subSelect.even{
	background-color: #747474 !important;
		background-color: #303030 !important;
}
*/

table.textLog tr:hover, 
table.textLog tr.subSelect.even:hover {
		background-color: #303030 !important;
	background-color: #747474 !important;
		color: white;
}

table.textLog tr:hover td {
		border-color: #303030 !important;
	border-color: #747474 !important;
}

table.textLog tr.subSelect:hover td,
table.textLog tr.subSelect.even:hover td {
		border-color: var(--hf-accent-color) !important;
}

table.textLog .smallThumb { 
	width: 80px; 
	float: left;
	margin-right: 12px;
}



.videoPlayer .tabs {
		white-space: normal;
}

.videoPlayer .tabPanel .log label {
		cursor: pointer;
		/* transition: color 0.2s ease-in-out; */
}

.videoPlayer .player + .info .tabs a {
		transition: var(--hf-standard-transition);
}

.videoPlayer .tabPanel .log label:hover,
.videoPlayer .tabPanel .lockLog:hover + label {
		color: white;
}




.videoPlayer .markerContainer#markIn { 
	margin-right: -0.25em;
}

.videoPlayer .markerContainer#markOut { 
	/* left: calc(100% - 1rem); /* scrollbar accommodation */
	margin-left: -0.25em;
}

.videoPlayer em {
		color: black;
		border-radius: 5px;
		padding: 1px 3px;
		background-color: var(--hf-highlight-color);
}

.videoPlayer .clipTools {
		/* grid-row: 2; */
		grid-row: 3;
		grid-column: 1;
		padding-top: 0;
}

.videoPlayer .tabPanel {
		height: unset;
}





.videoPlayer .clipTools {
		border-radius: var(--hf-border-radius);
		box-shadow: 2px 2px 6px rgba(0,0,0,0.45), -2px -2px 6px rgba(255,255,255,0.1);
		padding: 20px 24px;
		position: relative;
		overflow: hidden;
}

.videoPlayer .clipTools::before,
.videoPlayer .clipTools::after {
		content: '';
		position: absolute;
		background-color: var(--hf-accent-color);
		top: 0;
		width: 7px;
		bottom: 0;
}
	.videoPlayer .clipTools::before { left: 0; }
	.videoPlayer .clipTools::after { right: 0; }


	.videoPlayer .tabPanel .log .markerContainer #clipStart:hover::before { border-left-color: var(--hf-highlight-color); }
	.videoPlayer .tabPanel .log .markerContainer #clipEnd:hover::before { border-right-color: var(--hf-highlight-color); }



.pointField input[type="text"] {
		background: transparent;
		color: white;
		border: 1px solid transparent;
		padding: 0 2px;
		width: 1.15em;
		font-family: Ubuntu, sans-serif;
		text-align: center;
		box-shadow: none;
}
	.pointField input[type="text"]:hover {
		background-color: var(--hf-accent-color-darker);
		color: var(--hf-input-hover-color);
	}

	.pointField input[type="text"]:focus-visible {
		border-color: var(--hf-accent-color);
		outline-color: transparent;
		outline: none;
		box-shadow: var(--hf-input-field-focus-shadow);
	}

.videoPlayer .clipDuration {
		margin-left: 0.75em;
}

.videoPlayer #clipTitle, 
.videoPlayer #eipContainer-clipTitle {
		border: 1px solid rgba(0,0,0,0.2);
		margin: 15px 0;
		border-radius: var(--hf-border-radius);
		transition: var(--hf-standard-transition);
}

.videoPlayer #clipTitle {
		padding: 10px;
}
.videoPlayer #clipTitle span {
		transition: var(--hf-standard-transition);
}


.videoPlayer #clipTitleTouchOverlay:hover + #clipTitle {
		background-color: var(--hf-input-hover-bg-color);
}

.videoPlayer #clipTitleTouchOverlay:hover + #clipTitle span {
		color: var(--hf-input-hover-color);
}
.videoPlayer #clipTitleTouchOverlay:hover + #clipTitle span.empty {
		color: var(--hf-text-color);
}
#clipTitleTouchOverlay {
	right: unset;
	top:  unset;
	width: calc(100% - 40px);
	margin-top: 20px;
	/* cursor: text; */
	cursor: pointer;
}


#editObjclipTitle {
		height: 100%;
		padding: 10px;
		box-sizing: border-box;
}



#eip_buttonsclipTitle .eip_saveButton,
#eip_buttonsclipTitle .eip_cancelButton {
	display: inline-flex;
	width: 45px;
	height: 100%;
	margin-left: 0;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	padding: 0;
	background-image: none;
	opacity: 1;
	transition: var(--hf-standard-transition);
	color: var(--hf-button-text-color);
	position: relative;
	border-left: 1px solid var(--hf-input-divider-color);
}

#eip_buttonsclipTitle .eip_saveButton:focus-visible,
#eip_buttonsclipTitle .eip_cancelButton:focus-visible {
	box-shadow: var(--hf-button-focus-shadow);
	outline: none;
}
#eip_buttonsclipTitle .eip_saveButtonOverlay:hover + .eip_saveButton, 
#eip_buttonsclipTitle .eip_cancelButtonOverlay:hover + .eip_cancelButton {
	box-shadow: var(--hf-button-hover-shadow);
}
#eip_buttonsclipTitle .eip_saveButton:active, 
#eip_buttonsclipTitle .eip_cancelButton:active {
	box-shadow: var(--hf-button-active-shadow) !important;
	left: 1px;
	top: 1px;
	z-index: 3;
}

#eip_buttonsclipTitle .eip_saveButtonOverlay, 
#eip_buttonsclipTitle .eip_cancelButtonOverlay {
		height: 100%;
		top: 0;
		width: 46px;
		right: unset;
}
#eip_buttonsclipTitle .eip_cancelButtonOverlay { right: 0; }


#eip_buttonsclipTitle .eip_saveButton::before,
#eip_buttonsclipTitle .eip_cancelButton::before {
		font-family: var(--hf-icon-font);
		font-weight: 700;
		font-size: 18px;
		transition: var(--hf-button-transition);
}
#eip_buttonsclipTitle .eip_saveButton::before {
		content: '\f00c';
}
#eip_buttonsclipTitle .eip_cancelButton::before {
		content: '\f00d';
}
#eip_buttonsclipTitle .eip_saveButtonOverlay:hover + .eip_saveButton::before, 
#eip_buttonsclipTitle .eip_cancelButtonOverlay:hover + .eip_cancelButton::before { color: var(--hf-button-text-hover-color); }



#editObjclipTitle {
		border-radius: var(--hf-border-radius) 0 0 var(--hf-border-radius);
}

#cancelButton-clipTitle {
		border-radius: 0 var(--hf-border-radius) var(--hf-border-radius) 0;
}



.videoPlayer .clipTools {
		box-shadow: var(--hf-button-shadow);
}

.player .controls .playbackControls {
		bottom: 0;
		background-color: rgba(0,0,0,0.5);
		visibility: unset;
		height: 50px;
		/* z-index: 5; */
}

.videoPlayer .mobileTools .buttonWrapper > a {
		box-shadow: var(--hf-button-shadow);
		border-radius: var(--hf-border-radius);
}

.videoPlayer .mobileTools .buttonWrapper > a, 
.videoPlayer .mobileTools div.saveToBin {
		padding: 5px 15px 5px 41px;
}






.player .controls .playButton,
.player .controls .volumeControl,
.player .controls .volumeButton,
.player .controls .speedControl,
.player .controls .fullScreenButton {
	color: unset;
	border: none;
	width: 44px;
	height: 100%;
	top: 0;
	background: none;
}

	.player .controls .playButton::before,
	.player .controls .volumeButton::before,
	.player .controls .fullScreenButton::before,
	.player .controls .speedControl::before {
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		width: 100%;
		height: 100%;
		display: inline-flex;
		line-height: 50px;
		justify-content: center;
		font-size: 20px;
		color: var(--hf-button-text-color);
	}

	.player .controls .playButton:hover::before,
	.player .controls .volumeButton:hover::before,
	.player .controls .fullScreenButton:hover::before,
	.player .controls .speedControl:hover::before {
		color: white;
	}	

	.player .controls .playButton::before { content: '\f04b'; }
	.player .controls .volumeButton::before { content: '\f6a8'; }
	.player .controls .volumeControl.high .volumeButton::before { content: '\f028'; }
	.player .controls .volumeControl.low .volumeButton::before { content: '\f027'; }
	.player .controls .volumeControl.muted .volumeButton::before { content: '\f6a9'; }
	.player .controls .fullScreenButton::before { content: '\f065'; }
	.player .controls .playButton.playing::before { content: '\f04c'; }
	.player .controls .speedControl::before { content: '\f624'; }
	.player .controls .speedControl.min::before { content: '\f628'; }
	.player .controls .speedControl.low::before { content: '\f627'; }
	.player .controls .speedControl.high::before { content: '\f625'; }
	.player .controls .speedControl.max::before { content: '\f626'; }


	.player .controls .playButton {
		left: 8px;
	}

	.player .controls .volumeControl {
		left: 56px;
	}

		.player .controls .volumeControl .volumeBar {
			height: 7.5em;
			width: 3em;
			left: -0.125em;
			bottom: 45px;
			border-radius: var(--hf-border-radius);
			box-shadow: 4px 4px 6px #00000080;
			background-color: #565656;
		}

			.player .controls .volumeControl .volumeBar .volumeLevel, 
			.player .controls .volumeControl .volumeBar .volumeLevelBG {
				width: 33.33%;
				margin: 1em;
			}

			.player .controls .volumeControl .volumeBar .volumeLevelBG {
				background-color: #303030;
			}

			.player .controls .volumeControl .volumeBar .volumeLevel {
				background-color: #ffcc33;
			}


	.player .controls .speedControl {
		right: 56px;
		margin-top: 0;
	}

		.player .controls .speedControl::before {
			line-height: 1.8;
			height: 28px;
			position: absolute;
		}

		.player .controls .speedControl a.selection {
			display: block;
			text-align: center;
			font-size: 12px;
			padding-top: 28px;
			position: relative;
		}

		.player .controls .speedControl .options {
			border-radius: var(--hf-border-radius);
			box-shadow: 4px 4px 6px #00000080;
			background-color: #565656;
			width: 4.5em;
			left: 50%;
			position: absolute;
			margin-left: -2.25em;
			text-align: center;
			bottom: 45px;
		}

			.player .controls .speedControl .options::before {
				content: 'Speed:';
				padding: 0.5em;
				display: block;
				color: #a4a4a4;
			}

			.player .controls .speedControl .options .option {
				padding: 0.5em 1em;
			}

				.player .controls .speedControl .options .option.selected {
					background-color: #747474;
					font-weight: bold;
					display: block;
				}

				.player .controls .speedControl .options .option:hover {
					background-color: var(--hf-highlight-color);
					color: black;
					font-weight: bold;
					display: block;
				}


	.player .controls .fullScreenButton {
		right: 8px;
	}


.player .progressBar {
		height: 40px;
		left: 0;
		top: 0;
		right: 0;
		background-color: #f2efe8;
}

.player .progressBar .clip {
		height: unset;
		bottom: -4px;
		top: -4px;
		background-color: transparent;
		border: 4px solid var(--hf-accent-color);
		border-radius: 2px;
		margin: 0 -4px;
}

/*
.player .progressBar .innerContainer {
		overflow-x: unset;
}
*/

.player .progressBar .clip::before,
.player .progressBar .clip::after {
		content: '';
		position: absolute;
		height: 10px;
		width: 0;
		border: 4px solid var(--hf-accent-color);
		border-radius: 2px;
		top: 50%;
		margin-top: -9px;
}

.player .progressBar .clip::before { left: -6px; }
.player .progressBar .clip::after { right: -6px; }


.player .progressBar .clipHandle::after {
		border-left: 4px solid var(--hf-accent-color);
		transition: border-color 0.2s ease-in-out;
}

.player .progressBar .clipHandle:hover::after {
		border-color: var(--hf-highlight-color);
}

.player .progressBar .clipHandle.inHandle {
		left: -10px;
		z-index: 2;
}

.player .progressBar .clipHandle.outHandle {
		right: -10px;
}

.player .pointField span.tcTextField {
		background-color: var(--hf-accent-color);
		transition: background-color 0.2s ease-in-out;
		border: none;
		border-radius: 5px;
}

.player .clipHandle:hover .pointField span.tcTextField {
		background-color: var(--hf-highlight-color);
}

.player .clip.pointField.outPoint span.tcTextField, 
.player .clip.flipLeft .pointField.inPoint span.tcTextField {
		box-shadow: 5px 4px 6px #00000080;
}


.player .clip.flipRight .pointField.outPoint span.tcTextField, 
.player .clip:not(.flipLeft) .pointField.inPoint span.tcTextField {
		box-shadow: -5px 4px 6px #00000080;
}


.player .progressBar .clipHandle .pointField {
		width: unset;
		white-space: nowrap;
}


.player .tcField {
		font-size: 16px;
		font-weight: normal;
}


.player .pointField span.tcTextField {
		padding: 6px;
		cursor: col-resize;
}

.player .progressBar .clipHandle .pointField.outPoint {
		left: 6px;
}

.player .progressBar .clipHandle .pointField.inPoint {
		right: 6px;
}

.player .progressBar .clip.flipLeft .inPoint {
		left: 6px;
		right: auto;
}

.player .progressBar .clip.flipRight .outPoint {
		right: 6px;
		left: auto;
}

.player .progressBar .clip.flipRight.narrow .inHandle { height: 9.25em; }
.player .progressBar .clip.flipLeft.narrow .outHandle { height: 9.25em; }

.player .progressBar .clip.flipRight.narrow .outHandle { z-index: 3; }

.player .progressBar .playHead {
		top: -10px;
		background: none;
		border-radius: 2px;
		margin-left: -22px;
		height: calc(100% + 20px);
		width: 44px;
		box-shadow: none;
		mix-blend-mode: difference;
		cursor: col-resize;
}


.player .progressBar .playHead::after {
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - 2px);
		width: 4px;
		background-color: #d2d6cb;
		bottom: 0;
}

.player .progressBar .playHead:hover::after {
	box-shadow: 0 0 5px #ff7258; /* difference neg of --hf-accent-color */
}

.player.fullClip .progressBar .clip {
		border-color: transparent;
}



.player .playButton.playing {
		border: none;
}


#editObjclipTitle,
.videoPlayer #clipTitle {
		font-size: 18px;
		font-family: Ubuntu, sans-serif;
}


.videoPlayer .mobileTools {
		margin: 24px 0 0;
		padding-top: 0;
}

.videoPlayer .mobileTools .buttonWrapper {
	gap: 15px;
	display: flex;
	align-items: start;
	flex-wrap: wrap;
	justify-content: center;
}

.videoPlayer .mobileTools .buttonWrapper > a {
		display: inline-block;
		border: 1px solid #0003;
		color: white;
}

.videoPlayer .mobileTools .buttonWrapper > a, 
.videoPlayer .mobileTools div.saveToBin {
		font-weight: 500;
		font-size: 15px;
		line-height: 1.25;
		height: unset;
}


.player .progressBar .clip .clipHandle {
		display: block;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s ease-in-out;
		top: -4.75em;
}

.player .progressBar .clip.hover .clipHandle {
		opacity: 1;
		pointer-events: all;
}



/* Element | https://demo.badmath.com/HistoricFilms/app/tablet/ */

.player .progressBar .clip.overlap .clipHandle.inHandle {
		left: -15px;
}
.player .progressBar .clip.overlap .clipHandle .pointField.inPoint {
		right: 1px;
}
.player .progressBar .clip.flipLeft.overlap .clipHandle .pointField.inPoint {
		right: auto;
		left: 11px;
}
.player .progressBar .clip.overlap .clipHandle.inHandle::after {
		transform: translate(calc(-50% + 5px));
}


.player .progressBar .clip.overlap .clipHandle.outHandle {
		right: -15px;
}
.player .progressBar .clip.overlap .clipHandle .pointField.outPoint {
		left: 1px;
}
.player .progressBar .clip.flipRight.overlap .clipHandle .pointField.outPoint {
		left: auto;
		right: 11px;
}
.player .progressBar .clip.overlap .clipHandle.outHandle::after {
		transform: translate(calc(-50% - 5px));
}





.player .zoomControl {
	width: 30%;
	height: 12px;
	background: none;
	top: 75px;
	right: unset;
	margin-left: 0;
	box-shadow: -2px -2px 2px #0000006e, 1px 1px 1px #ffffff20;
	border-radius: var(--hf-border-radius);
	left: 55%;
	margin-left: -20%;
}

	.player .zoomControl::after { content: unset; }

	.player .zoomControl .button {
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		width: 50px;
		height: 50px;
		display: block;
		line-height: 50px;
		text-align: center;
		font-size: 20px;
		color: var(--hf-button-text-color);
		opacity: 1;
		position: absolute;
		top: 50%;
		margin-top: -25px;
		text-transform: none;
		cursor: pointer;
	}

	.player .zoomControl .button.zoomOut { 
		left: -55px; 
		right: unset; 
	}

		.player .zoomControl .button.zoomOut::before { content: '\f010'; }

	.player .zoomControl .button.zoomIn { 
		right: -55px; 
		left: unset; 
	}
	
		.player .zoomControl .button.zoomIn::before { content: '\f00e'; }

		.player .zoomControl .button:hover {
			color: white;
		}

	.player .zoomControl .bg {
		width: 50%;
		height: 100%;
		margin: auto;
		display: block;
		border: 1px solid #8a8682;
		border-width: 0 1px;
		z-index: 2;
		position: relative;
	}

		.player .zoomControl .bg::before {
			content: '';
			width: 50%;
			height: 100%;
			border: 1px solid #8a8682;
			border-width: 0 1px 0 0;
			position: absolute;
			left: 0;
			display: block;
		}

	.player .zoomControl .bar {
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		width: 100%;
		border-radius: var(--hf-border-radius);
		z-index: 1;
		overflow: hidden;
		box-sizing: border-box;
		border: 1px solid transparent;
	}

		.player .zoomControl .barFill {
			position: absolute;
			left: 0px;
			top: 0px;
			height: 100%;
			background-color: var(--hf-accent-color);
			opacity: 0.7;
		}




	.player .zoomControl .pointer {
		top: -6px;
		background-color: var(--hf-accent-color);
		height: 24px;
		width: 24px;
		z-index: 3;
		box-shadow: 0.125em 0.125em 0 #00000026, 1px 1px 0px rgba(255,255,255,0.25) inset;
	}


.player .textProgress {
		font-family: inherit;
		font-weight: 500;
		font-size: 16px;
		left: 0;
		top: 70px;
		padding: 0;
}

.player .tcField {
		font-family: inherit;
}

.player .writable .tcField:hover {
		color: black !important;
}


.player .textProgress .duration, 
.player .textProgress .duration .tcField {
		color: #747474;
}

.player .textProgress .duration::before {
		color: unset;
		margin: 0 0.25em;
}

.player .textProgress .currentPos, 
.player .textProgress .currentPos .tcField {
		color: white;
}


.player .textProgress .currentPos {
		background-color: #232323;
		border-radius: var(--hf-border-radius);
		padding: 10px 7px;
		box-shadow: 0 0 10px rgba(0,0,0,0.5) inset;
}

.tcTextField input[type="text"] {
		background: transparent;
		color: white;
		border: 1px solid transparent;
		font-family: Ubuntu, sans-serif;
		text-align: center;
		box-shadow: none;
}
	.tcTextField input[type="text"]:not(.disabled):hover {
		background-color: var(--hf-accent-color-darker);
		color: var(--hf-input-hover-color) !important;
	}

	.tcTextField input[type="text"]:focus-visible {
		border-color: var(--hf-accent-color);
		outline-color: transparent;
		outline: none;
		box-shadow: var(--hf-input-field-focus-shadow);
	}

.player .jumpToClip {
	top: 0;
	left: -32px;
	right: -32px;
}

	.player .jumpToClip button {
		position: absolute;
		height: 40px;
		width: 30px;
		color: var(--hf-accent-color);
		background: none;
		transition: var(--hf-standard-transition);
		opacity: 0;
		border-radius: unset;
		box-shadow: unset !important;
		min-height: unset;
		line-height: unset;
		left: unset;
		top: unset;
	}

		/* overriding default <button> behaviours */
		.player .jumpToClip button:hover {
			color: var(--hf-accent-color);
			box-shadow: none;
			text-decoration: none;
		}

		.player .jumpToClip button:active {
			box-shadow: unset !important;
			left: unset;
			top: unset;
		}

		.player .jumpToClip button:focus-visible {
			box-shadow: unset !important;
			outline: none;
		}	

		.player .jumpToClip button.offscreen { opacity: 1; }

		.player .jumpToClip button::before {
			font-family: "Font Awesome 6 Pro";
			font-weight: 700;
			width: 30px;
			height: 40px;
			display: block;
			line-height: 40px;
			text-align: center;
			font-size: 20px;
			color: var(--hf-button-text-color);
			opacity: 1;
			position: absolute;
			top: 50%;
			margin-top: -20px;
			text-transform: none;
			cursor: pointer;
		}

			.player .jumpToClip button:hover::before { color: white; }		

	.player .jumpToClip button.jumpToClipIn { left: 0; }
	.player .jumpToClip button.jumpToClipOut { right: 0; }

	.player .jumpToClip button.jumpToClipIn::before { content: '\f33e'; }
	.player .jumpToClip button.jumpToClipOut::before { content: '\f340'; }






.player .outsideClip {
		height: 40px;
		position: absolute;
		background-color: rgba(0,0,0,0.2);
}

.player .outsideClip.before {
		left: 0;
}


.player .outsideClip.after {
		right: 0;
}


.videoPlayer .mobileTools .buttonWrapper > a::before,
.videoPlayer .mobileTools div.saveToBin::before,
.result .saveClip::before {
	position: absolute;
	font-family: "Font Awesome 6 Pro";
	font-size: 20px;
	left: 10px;
	font-weight: 900;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}


.videoPlayer .mobileTools .buttonWrapper > a {
	background-image: none !important;
	position: relative;
	color: var(--hf-button-text-color);
	opacity: 1;
	transition: var(--hf-button-transition);
	display: flex;
	align-items: center;
}

.videoPlayer .mobileTools .buttonWrapper > a,
.videoPlayer .mobileTools div.saveToBin,
#mainArea .result .saveClip,
#videoWindow .result .saveClip {
	padding: 5px 15px 5px 44px;
	font-size: 16px;
	line-height: 1.125;
	margin-bottom: 0;
}

.videoPlayer .mobileTools .buttonWrapper > a:not(.disabled):hover { color: white; }

.videoPlayer .mobileTools .buttonWrapper > a::before { left: 11px; }

.videoPlayer .mobileTools .buttonWrapper > a.downloadClip::before { content: '\f019'; }
.videoPlayer .mobileTools .buttonWrapper > a.share::before { content: '\f1e0'; }
.videoPlayer .mobileTools .buttonWrapper > a.contactResearcherClip::before { content: '\f059'; }

.videoPlayer .mobileTools div.saveToBin {
	color: var(--hf-button-text-color);
	float: left;
	box-shadow: var(--hf-button-shadow);
	border-radius: var(--hf-border-radius);
	transition: var(--hf-button-transition);
	border: 1px solid #0003;
	margin-left: 0;
	margin-right: 0;
	cursor: pointer;
}

	.videoPlayer .mobileTools div.saveToBin:hover { color: white; }

	.videoPlayer .mobileTools div.saveToBin::before { 
		left: 9px;
		content: '\f217';
	}
	.videoPlayer .mobileTools div.saveToBin::after { content: unset; }

	.videoPlayer .mobileTools div.saveToBin label { cursor: pointer; }

	.videoPlayer .mobileTools div.saveToBin #playerSelectBin {
		padding: 0;
		margin: 2px 0 0 -2px;
		font-family: inherit;
		font-weight: 500;
		color: white;
		background-color: var(--hf-background-color);
		cursor: pointer;
		transition: var(--hf-standard-transition);
	}

	.videoPlayer .mobileTools div.saveToBin #playerSelectBin:hover {
		background-color: #565656;
	}



.videoPlayer .addToBin {
		display: none;
}


#videoWindow .button.save {
	position: absolute;
	top: 52px;
	left: 50%;
	transform: translateX(-50%);
}



/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */

.videoPlayer .clipTools {
		/* box-shadow: 4px 4px 6px #00000060; */
		box-shadow: 4px 4px 6px #00000050;
		background-color: var(--hf-input-hover-bg-color);
}


.videoPlayer .mobileTools div.saveToBin #playerSelectBin {
		/* font-weight: 500; */
		font-weight: 400;
		appearance: auto;
		border-radius: 0;
		box-shadow: none;
		padding: 0 2px;
}
.videoPlayer #clipTitle {
	box-shadow: none;
	border-color: transparent;
}
.videoPlayer #eipContainer-clipTitle {
		box-shadow: var(--hf-input-field-shadow);
		background-color: var(--hf-background-color);
}
.videoPlayer .mobileTools .buttonWrapper > a {
		/* background-color: var(--hf-background-color); */
}

.videoPlayer .mobileTools div.saveToBin {
		/* background-color: var(--hf-background-color); */
		max-width: 160px;
		width: 160px;
		width: 134px;
}


.videoPlayer .mobileTools .buttonWrapper > a::before,
 .videoPlayer .mobileTools div.saveToBin::before,
 #mainArea .result .saveClip::before,
 #videoWindow .result .saveClip::before {
		/* font-size: 20px; */
		font-size: 18px;
		transition: var(--hf-button-transition);
}

.videoPlayer .mobileTools .buttonWrapper > a::before {
		/* left: 11px; */
		left: 13px;
}

.videoPlayer .mobileTools div.saveToBin::beforee,
 #mainArea .result .saveClip::before {
		/* left: 9px; */
		left: 10px;
}



.videoPlayer .mobileTools div.saveToBin select, .ui-dialog select {
		/* font-size: 16px; */
		font-size: 14px;
}


.videoPlayer .mobileTools .buttonWrapper > a:last-child {
	padding-right: 15px;
}



.videoPlayer .mobileTools .buttonWrapper > a, 
.videoPlayer .mobileTools div.saveToBin {
		/* font-size: 16px; */
		font-size: var(--hf-button-font-size);
		min-height: var(--hf-button-min-height);
}

.videoPlayer .tabs {
		/* font-size: 18px; */
		font-size: 16px;
}


/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */


.videoPlayer .mobileTools .buttonWrapper > a:focus-visible {
	box-shadow: var(--hf-button-focus-shadow);
}

.videoPlayer .mobileTools .buttonWrapper > a:not(.disabled):hover,
.videoPlayer .mobileTools div.saveToBin:not(.disabled):hover {
		box-shadow: var(--hf-button-hover-shadow);
}

.videoPlayer .mobileTools .buttonWrapper > a:active {
	box-shadow: var(--hf-button-active-shadow) !important;
	left: 1px;
	top: 1px;
	background-color: rgba(0, 0, 0, 0.1);
}

.videoPlayer .mobileTools .buttonWrapper > a.disabled { opacity: var(--hf-button-disabled-opacity); }

.videoPlayer .tabPanel .log .checkbox label::after {
	opacity: 0;
}
.videoPlayer .tabPanel .log .lockWithLog:hover label::after,
.videoPlayer .tabPanel .log .checkbox label:hover::after {
	opacity: 0.5;
}
.videoPlayer .tabPanel .log .checkbox input[type="checkbox"]:checked + label::after {
	opacity: 1;
}



#videoWindow .clipTools a.save {
		display: flex;
		font-weight: 500;
		padding: 5px 15px;
		font-size: 14px;
		line-height: 1.125;
		background-color: var(--hf-highlight-color);
		align-items: center;
		box-shadow: 4px 4px 6px #00000050;
		text-shadow: none;
		color: black;
}

#videoWindow .clipTools a.save:hover {
		box-shadow: 4px 4px 6px #00000050;
}

#videoWindow .clipTools a.save:active {
		box-shadow: 1px 1px 2px #00000050 !important;
	left: calc(50% + 1px);
	top: 53px;
}




.videoPlayer .matches .searchBar.music ~ .matchInfo { margin-top: 60px; }



/* .videoPlayer .closeContainer {
		display: none;
} */

/*
#videoWindow.popup {
	min-width: 1280px;
}







/* videoPlayer.0cc1fc0d.css | https://library.historicfilms.com/public/lib/newPlayer/videoPlayer.0cc1fc0d.css */


table.textLog tr.even td {
		border-color: #4a4a4a;
}

table.textLog {
		line-height: 1.4;
}

table.textLog tr.subSelect {
		font-weight: unset;
		background-color: #00415b !important;
		background-color: #005171 !important;
}

table.textLog tr.current {
		font-weight: 500;
}

table.textLog tr.subSelect.even {
		background-color: #00415b !important;
		background-color: #005171 !important;
}
table.textLog tr.subSelect {
		/* background-color: #005171 !important; */
		background-color: var(--hf-accent-color-darker) !important;
}

table.textLog tr.subSelect.even,
table.textLog tr.current.subSelect.even {
		/* background-color: #005171 !important; */
		background-color: var(--hf-accent-color-darker) !important;
}

table.textLog td:first-child {
		border-left: 6px solid #4a4a4a;
}

table.textLog td:last-child {
		border-right: 6px solid #4a4a4a;
}

table.textLog tr:hover {
		background-color: var(--hf-hover-color) !important;
		/* color: #121212; */
}

table.textLog tr.subSelect.:hover, 
table.textLog tr.subSelect.even:hover {
		background-color: var(--hf-accent-hover-color) !important;
}

.videoPlayer .tabPanel .log .markerContainer a::before, 
.videoPlayer .tabPanel .log .markerContainer a::after {
		border-bottom: 2.1875em solid transparent;
		border-top: 2.1875em solid transparent;
}

table.textLog td {
		padding: 10px 6px;
}

table.textLog tr {
		background-color: var(--hf-panel-bg-color);
}

table.textLog tr:not(:first-child) {
		border-top: 1px solid #121212;
}


.videoPlayer .tabPanel .log .markerContainer #clipStart::after,
.videoPlayer .tabPanel .log .markerContainer #clipEnd::after {
		right: unset;
		border: none;
		height: 3.0936em;
		width: 3.0936em;
		transform: translateX(-50%) scaleX(80%) rotate(45deg);
		top: 0.62em;
		box-shadow: 4px 4px 10px #00000080;
		z-index: -1;
}

.videoPlayer .tabPanel .log .markerContainer #clipEnd::after { left: 0; }
.videoPlayer .tabPanel .log .markerContainer #clipStart::after { left: 100%; }

/*
table.textLog tr::after {
		content: '';
		position: absolute;
		top: 0;
		height: 1px;
		left: 0;
		right: 0;
		background: linear-gradient(90deg, #12121200 0%, #121212 100%);
}
*/

table.textLog tr:hover td {
		/* border-color: #747474 !important; */
		border-color: var(--hf-hover-color) !important;
}


table.textLog tr.subSelect td:first-child,
table.textLog tr.subSelect.current:hover td:first-child {
		border-left-color: var(--hf-accent-color) !important;
		padding-left: 18px;
}

table.textLog tr.subSelect td:last-child,
table.textLog tr.subSelect.current:hover td:last-child {
		border-right-color: var(--hf-accent-color) !important;
		padding-right: 18px;
}


.videoPlayer div.textLog {
		border-top: 1px solid #121212;
}

.videoPlayer .reelInfo div.description .description:empty { display: none; }




.videoPlayer .hiddenPlayer {
		height: unset;
}

.player.fullScreen {
		background-color: #121212;
}

.player .zoomControl {
		max-width: 320px;
}

table.textLog tr:hover,
html table.textLog tr.current:hover {
		border-top-color: var(--hf-hover-color) !important;
		background-color: var(--hf-hover-color) !important;
}

table.textLog tr.subSelect:hover, 
table.textLog tr.subSelect.even:hover {
		background-color: var(--hf-accent-hover-color) !important;
}

/*
table.textLog tr.current.even, 
table.textLog tr.current.subSelect.even, 
table.textLog tr.current.odd, 
html table.textLog tr.current:hover, 
table.textLog tr.current, 
table.textLog tr.current.subSelect {
		border-top-color: #303030 !important;
}
*/

.player.fullScreen .controls .textProgress {
		margin-left: 15px;
}

#videoWindow .player.fullScreen .clipTools .save {
		margin-right: 15px;
}

/*
table.textLog tr.current + tr {
		border-top-color: #4a4a4a;
}
*/

.player .zoomControl {
		left: 50%;
		margin-left: 0;
		transform: translateX(-50%);
}
.player .zoomControl.disabled {
	pointer-events: none;
	opacity: 0.25;
}
@media screen and (max-width: 1280px) {
	.player .zoomControl {
		transform: none;
		left: unset;
		right: 50px;
	}
}

.logDisplay {
		background-color: #303030;
		border-color: #303030;
		top: 120px;
		margin-top: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.4;
		height: 80px;
}

.logDisplay span.timecode {
		width: auto;
		font-size: 18px;
		font-weight: 500;
}

.logDisplay span.desc {
		padding-left: 6em;
		padding-top: 2px;
}

.player.fullScreen {
  --hf-progressBar-bg-size: 5%;
}

.player.fullScreen .logDisplay .smallThumb {
		float: left;
		margin-right: 1em;
		display: none;
}



/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */

.player .playerDiv video.playerElement.loading, .player .playerDiv video.playerElement.hidden {
		/* -webkit-transform: translateX(-2048px); */
		-webkit-transform: translateX(-10000px);
}


/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */

.player.inClip .inClipIndicator {
		/* background-color: #ebc500; */
		background-color: var(--hf-accent-color);
}

.player.fullScreen .controls .fullScreenButton::before {
		/* content: '\f065'; */
		content: '\f066';
}



/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */


.videoPlayer #clipTitle {
	box-shadow: none;
	border-color: transparent;
	display: block;
	cursor: pointer;
}
.videoPlayer #eipContainer-clipTitle {
		box-shadow: var(--hf-input-field-shadow);
}

.videoPlayer .clipTools {
		/* border-radius: var(--hf-border-radius); */
		border-radius: var(--hf-outer-border-radius);
}
.videoPlayer.no-video .clipTools {
		/* margin-top: 40px; */
		margin-bottom: auto;
}

.videoPlayer div.textLog {
		/* border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius); */
		border-radius: 0 0 var(--hf-outer-border-radius) var(--hf-outer-border-radius);
}

.videoPlayer .tabPanel {
		/* border-radius: 0 0 var(--hf-border-radius) var(--hf-border-radius); */
		border-radius: 0 0 var(--hf-outer-border-radius) var(--hf-outer-border-radius);
}

.videoPlayer .tabs {
		/* border-radius: var(--hf-border-radius) var(--hf-border-radius) 0 0; */
		border-radius: var(--hf-outer-border-radius) var(--hf-outer-border-radius) 0 0;
}

/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */




/* videoPlayer.css | https://demo.badmath.com/HistoricFilms/app/tablet/lib/newPlayer/videoPlayer.css */

/*
#editObjclipTitle {
		background-color: #747474;
}

#editObjclipTitle:focus-visible {
		/* box-shadow: 0 0 5px white; */
/*		box-shadow: 0 0 15px #fff3 inset;
}
*/



.player .controls .volumeControl .volumeBar .volumeLevel {
		/* background-color: #ffcc33; */
		background-color: var(--hf-highlight-color);
}


.videoPlayer .tabPanel .log label {
		transition: var(--hf-standard-transition);
		vertical-align: middle;
}

.videoPlayer .tabPanel .log .checkbox label {
		width: 1.25em;
		height: 1.25em;
		top: -0.25em;
		left: -0.25em;
		border: 1px solid #0003;
		border-radius: 2px;
		box-shadow: var(--hf-button-shadow);
		background: none;
}

.videoPlayer .tabPanel .log .checkbox label::after {
		content: none;
}

/* Inline #8 | https://library.historicfilms.com/public/tapes/61237 */

.videoPlayer .tabPanel .log .checkbox label::before {
		content: '\f00c';
		font-family: "Font Awesome 6 Pro";
		font-weight: 900;
		width: 100%;
		display: inline-flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		color: transparent;
		transition: var(--hf-standard-transition);
}


.videoPlayer.no-video .tabPanel .log .lockWithLog {
		display: inline-block;
}

.videoPlayer .tabPanel .log .lockWithLog {
	display: none;
}

.videoPlayer .tabPanel .log .lockWithLog:hover .lockLog label {
	box-shadow: var(--hf-button-hover-shadow);
}

.videoPlayer .tabPanel .log .lockWithLog:hover label.lockLog {
	color: var(--hf-strong-color);
}

.videoPlayer .tabPanel .log .checkbox input:checked + label::before { color: #ccccccb3; }


.player .playerDiv > * {
		border-radius: 0;
}



.player .playerDiv .largePlayButton {
		background: none;
		transition: opacity 0.2s ease-in-out;
}


.player .playerDiv .largePlayButton::before {
		content: '\f04b';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		font-family: "Font Awesome 6 Pro";
		justify-content: center;
		align-items: center;
		font-size: 120px;
		font-weight: 700;
}
.player.playing .playerDiv .largePlayButton::before {
		content: '\f04c';
	}

 .playerDiv .largePlayButton:hover {
		opacity: 1;
}




table.textLog tr.current td.timecode::before,
table.textLog tr.current td.desc::after {
		content: '\f04b';
		position: absolute;
		justify-content: end;
		align-items: center;
		display: flex;
		top: 0;
		bottom: 0;
		font-size: 18px;
		font-family: "Font Awesome 6 Pro";
		font-weight: 700;
		text-shadow: 0 0 10px rgba(0,0,0,0.5);
		width: 12px;
		overflow: hidden;
		opacity: 0.75;
}


table.textLog tr.current td.timecode::before {
		left: -6px;
}

table.textLog tr.current td.desc::after {
		right: -6px;
		transform: rotate(180deg);
}


table.textLog td.timecode,
table.textLog td.desc {
		position: relative;
}


.videoPlayer .tabPanel .thumbnails span.thumbBtn:hover { 
	box-shadow: var(--hf-thumbnail-shadow);
}


.no-video .player .player-container,
.no-video .player .controls {
		display: none;
}






/* single-col mode */
@media screen and (max-width: 960px) {

	.videoPlayer .playerAreaInner { grid-template-columns: 1fr; }
	.videoPlayer .reelInfo {
	    grid-column: 1;
	    padding: 0 var(--hf-standard-margin);
	}

	.videoPlayer .player {
		display: none;
	    grid-row: 3;
	    z-index: 3;
	    top: 213px;
	    position: absolute;
        border-radius: 0 0 var(--hf-outer-border-radius) var(--hf-outer-border-radius);
        max-width: unset;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
	}
		.videoPlayer.video .player { display: block; }

	.videoPlayer .player + .info {
	    padding-right: 0;
	    grid-row: 2;
	    padding-left: 0;
	    grid-column: 1;
	    transition: var(--hf-standard-transition);
	}
	.videoPlayer .player + .info.log {
	    padding-right: 50px;
	    padding-left: 50px;
	}
	.videoPlayer .player + .info:not(.log) div.textLog .logWrapper {
	    padding-right: 50px;
	    padding-left: 50px;
	}
	.videoPlayer .info .infoInner {
	    position: relative;
	}	
	.videoPlayer .clipTools {
		grid-row: 4;
        margin: var(--hf-standard-margin) var(--hf-standard-margin) 0;
        max-width: unset;
        width: auto;
	}
	.videoPlayer .reelInfo div.description.expandable {
	    height: 48px;
	}
	.videoPlayer .reelInfo div.description .description {
	    max-height: 42px;
	}
	.playerPlaceholder {
		padding-bottom: 120px;
		padding-left: 20px;
		padding-right: 20px;
		aspect-ratio: 4 / 3;
		transition: var(--hf-standard-transition);
	}
	.aspect5625 .playerPlaceholder { aspect-ratio: 16 / 9; }
	.aspect6666 .playerPlaceholder { aspect-ratio: 3 / 2; }
	.aspect7272 .playerPlaceholder { aspect-ratio: 1.375; }
	.videoPlayer .player + .info.log .playerPlaceholder { margin: 0 -50px; }

	.videoPlayer .tabPanel .thumbnails {
		padding: 15px;
	}
	.player .jumpToClip {
		left: 0;
		right: 0;
		z-index: 4;
	}
	.player .jumpToClip button::before {
		color: black;
	}
}

@media screen and (max-width: 640px) {
	.videoPlayer .reelInfo { padding: 0; }
	.videoPlayer .clipTools {
		margin-left: 0;
		margin-right: 0;
	}
	.videoPlayer .player + .info .tabs a {
		padding-left: 15px;
		padding-right: 15px;
	}
	.videoPlayer .tabs a::before { font-size: 24px; }
	.videoPlayer .player + .info .tabs a .long { display: none; }
	.videoPlayer .player + .info .tabs a .short { display: inline; }
	.videoPlayer .tabPanel .log .logMarker {
		white-space: normal;
		font-size: 14px;
		width: 40px;
	}
	.videoPlayer .tabPanel .log .markerContainer a::before, 
	.videoPlayer .tabPanel .log .markerContainer a::after {
		border-bottom-width: 2.8125em;
		border-top-width: 2.8125em;
	}
	.videoPlayer .player + .info.log {
		padding-right: 25px;
		padding-left: 25px;
	}	
	.videoPlayer .player + .info:not(.log) div.textLog .logWrapper {
	    padding-right: 25px;
	    padding-left: 25px;
	}
	.videoPlayer .player + .info.log .playerPlaceholder { margin: 0 -25px; }
}


@media screen and (max-width: 480px) {
	.videoPlayer .player + .info:not(.log) {
		margin-left: calc(var(--hf-standard-margin) * -1);
		margin-right: calc(var(--hf-standard-margin) * -1);
	}
	.videoPlayer.video .player {
		padding-left: 0;
		padding-right: 0;
	}
	.videoPlayer .player {
		/* top: 350px; */
		/* width: 100%; */
		top: 202px;
		width: calc(100% + var(--hf-standard-margin));
		left: calc(var(--hf-standard-margin) / -2); /* for Safari/iOS */
		margin-top: -11px !important;
	}
	.videoPlayer .info:not(.log) .tabs,
	.videoPlayer .info:not(.log) .tabPanel {
		border-radius: 0;
	}
	.videoPlayer .clipTools {
		margin-left: -15px;
		margin-right: -15px;
	}
	.videoPlayer .player + .info.log .playerPlaceholder { margin: 0 -55px; }
}


/*======= newsearch.css =======*/

.searchContainer .mltFilter {
    display: none;
}

.searchContainer .textSearch {
    display: block;
}

.searchContainer.mlt .mltFilter {
    display: block;
}

.searchContainer.mlt .textSearch {
    display: none;
}

.searchContainer .filter .selectedList .facet.selected {
  background-color: var(--hf-accent-color);
  color: var(--hf-button-text-hover-color);
  box-shadow: none;
}
.searchContainer .filter .list .facet.selected { display: none; }

.searchContainer .filters .filter {
    display: block;
}

	.searchContainer .filters .filter.disabled,
	.searchContainer .filters .facet.disabled { opacity: 0.5; }

    .searchContainer .filters .filter .facet-list {
        height:  200px;
        overflow-y: auto;
    }

.masonry {
	flex-wrap: wrap;
	display: flex;
	gap: calc(var(--hf-standard-margin) / 1.5);
	/* align-items: flex-start; /* for Safari, which otherwise stretches these elements, ignoring aspect-ratio */
}
.masonry::after { /* this keeps the last row from stretching to fit */
	content: '';
	flex-grow: 6;
}



.searchContainer .results .result {
    display: inline-block;
    height: 256px;
    vertical-align: top;
    position: relative;
	animation-name: thumbOn;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-delay: 0s;
	animation-fill-mode: forwards;
	opacity: 0;
	cursor: pointer;
}
.searchContainer .results .result:hover,
.searchContainer .results .result:focus-visible {
	box-shadow: var(--hf-thumbnail-shadow);
	transition: var(--hf-standard-transition);
	/* margin: -2px 2px 2px -2px; */
	outline: none;
	/* transform: scale(1.03); */
}
@keyframes thumbOn {
	from { opacity: 0; }
	to { opacity: 1; }
}
/* this is so stupid, but there's no other way to do this in CSS */
/* https://github.com/w3c/csswg-drafts/issues/1026 */
/* (CSS counters are for 'content:' output only) */
:root {
	--hf-thumOnAnimationDelay: 0.025s;
}
.searchContainer .results .result:nth-child(1) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 1); }
.searchContainer .results .result:nth-child(2) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 2); }
.searchContainer .results .result:nth-child(3) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 3); }
.searchContainer .results .result:nth-child(4) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 4); }
.searchContainer .results .result:nth-child(5) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 5); }
.searchContainer .results .result:nth-child(6) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 6); }
.searchContainer .results .result:nth-child(7) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 7); }
.searchContainer .results .result:nth-child(8) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 8); }
.searchContainer .results .result:nth-child(9) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 9); }
.searchContainer .results .result:nth-child(10) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 10); }
.searchContainer .results .result:nth-child(11) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 11); }
.searchContainer .results .result:nth-child(12) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 12); }
.searchContainer .results .result:nth-child(13) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 13); }
.searchContainer .results .result:nth-child(14) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 14); }
.searchContainer .results .result:nth-child(15) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 15); }
.searchContainer .results .result:nth-child(16) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 16); }
.searchContainer .results .result:nth-child(17) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 17); }
.searchContainer .results .result:nth-child(18) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 18); }
.searchContainer .results .result:nth-child(19) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 19); }
.searchContainer .results .result:nth-child(20) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 20); }
.searchContainer .results .result:nth-child(21) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 21); }
.searchContainer .results .result:nth-child(22) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 22); }
.searchContainer .results .result:nth-child(23) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 23); }
.searchContainer .results .result:nth-child(24) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 24); }
.searchContainer .results .result:nth-child(25) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 25); }
.searchContainer .results .result:nth-child(26) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 26); }
.searchContainer .results .result:nth-child(27) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 27); }
.searchContainer .results .result:nth-child(28) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 28); }
.searchContainer .results .result:nth-child(29) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 29); }
.searchContainer .results .result:nth-child(30) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 30); }
.searchContainer .results .result:nth-child(31) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 31); }
.searchContainer .results .result:nth-child(32) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 32); }
.searchContainer .results .result:nth-child(33) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 33); }
.searchContainer .results .result:nth-child(34) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 34); }
.searchContainer .results .result:nth-child(35) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 35); }
.searchContainer .results .result:nth-child(36) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 36); }
.searchContainer .results .result:nth-child(37) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 37); }
.searchContainer .results .result:nth-child(38) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 38); }
.searchContainer .results .result:nth-child(39) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 39); }
.searchContainer .results .result:nth-child(40) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 40); }
.searchContainer .results .result:nth-child(41) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 41); }
.searchContainer .results .result:nth-child(42) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 42); }
.searchContainer .results .result:nth-child(43) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 43); }
.searchContainer .results .result:nth-child(44) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 44); }
.searchContainer .results .result:nth-child(45) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 45); }
.searchContainer .results .result:nth-child(46) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 46); }
.searchContainer .results .result:nth-child(47) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 47); }
.searchContainer .results .result:nth-child(48) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 48); }
.searchContainer .results .result:nth-child(49) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 49); }
.searchContainer .results .result:nth-child(50) { animation-delay: calc(var(--hf-thumOnAnimationDelay) * 50); }


.searchContainer .results .result {
		animation-delay: 0s;
		background-color: var(--hf-input-hover-bg-color);
		/* max-height: 400px;
		max-height: 320px; */
		max-height: 340px;
		/* max-width: 453px; */
		height: auto;
		text-decoration: none;
		/* overflow: hidden; */ /* overflow caused aspect-ratio to be totally ignored in Safari, so */
		                        /* this is compensated for with clip-paths in a few places */
		/* outline: 1px solid red; */
		/*
		flex-basis: 20%;
		flex-basis: max-content;
		flex-basis: content;
		flex-basis: min-content;
		*/
}

	.searchContainer .results .result > a {
		display: block;
		clip-path: inset(0); /* in place of overflow:hidden (which we can't use in Safari), to compensate for the .info bleed */
		                     /* overflow caused aspect-ratio to be totally ignored in Safari */	
		                     /* putting this on the parent .result blocks its drop-shadow */
        max-height: 100%; /* on single-item lists, .result can get too large, and its contents slightly exceed its max-height, spilling out the bottom */
	}

	@media screen and (max-width: 660px) {
		.searchContainer .results.masonry .result,
		.searchContainer .results .masonry .result { 
			max-height: unset; 
			max-width: calc(100vw - (2 * var(--hf-standard-margin))); /* for Safari/iOS */
			width: 100%; 
			/* outline: 1px solid yellow; */
		}
		body.home #pageContent .searchContainer .results.masonry .result {
			max-width: calc(100vw - 20px); /* for Safari/iOS */
		}
	}

	.reelMatches.searchContainer .results .result {
		margin: 0 calc(var(--hf-standard-margin) / 2);
	}
body.home .searchContainer .results .result:hover, 
body.home .searchContainer .results .result:focus-visible {
		/*
		box-shadow: unset;
		transition: var(--hf-standard-transition);
		top: unset;
		left: unset;
		*/
		outline: none;
}

.results .result span.thumb,
#binContents .result .thumb {
	background-size: auto var(--hf-overscan-amount); /* auto on the horizontal, in case the aspect ratio doesn't match the video, as may be the case with custom thumbs */
	background-position: center;
	clip-path: inset(0%); /* default, for transitions */
	transform: scale(100%); /* default, for transitions */
}

.results .result span.thumb,
.results .result span.thumb img,
#binContents .result .thumb,
#binContents .result .thumb img {
	height: 100%;
	width: 100%;
	min-height: 216px;
	transition: var(--hf-standard-transition);
}

.results .result span.thumb img,
#binContents .result .thumb img {
	object-fit: cover;
	transform: scale(var(--hf-overscan-amount));
	clip-path: inset(calc((var(--hf-overscan-amount) - 100%) / 2)); /* for Safari, where we can't use overflow:hidden */
}

.results .result video,
#binContents .result video {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transform: scale(var(--hf-overscan-amount));
	clip-path: inset(calc((var(--hf-overscan-amount) - 100%) / 2)); /* for Safari, where we can't use overflow:hidden */
	transition: var(--hf-standard-transition);
}

.results .result:hover span.thumb img,
#binContents .result:hover .thumb img,
.results .result:focus-visible span.thumb,
#binContents .result:focus-visible .thumb img,
.results .result:hover video,
#binContents .result:hover video,
.results .result:focus-visible video,
#binContents .result:focus-visible video {
	transform: scale(calc(var(--hf-overscan-amount) * var(--hf-thumb-zoom-amount)));
	clip-path: inset(calc( (100% - (100% / (var(--hf-overscan-amount-alt) * var(--hf-thumb-zoom-amount)) ) ) / 2)); /* for Safari, where we can't use overflow:hidden */
}
.results .result:hover span.thumb {
	background-size: auto calc(var(--hf-overscan-amount)*var(--hf-thumb-zoom-amount));
}



.result.aspect7500, .result.aspect7500 .thumb, .result.aspect7500 video,
.result.aspect0, .result.aspect0 .thumb, .result.aspect0 video,
li.clipDetails div.thumb div.img,
li.clipDetails.aspect7500 div.thumb div.img,
.videoPlayer.aspect7500 .accessDetails .thumb {
	aspect-ratio: 4 / 3;
}

.result.aspect7272, .result.aspect7272 .thumb, .result.aspect7272 video,
li.clipDetails.aspect7272 div.thumb div.img,
.videoPlayer.aspect7272 .accessDetails .thumb {
	aspect-ratio: 1.375;
}

.result.aspect6667, .result.aspect6667 .thumb, .result.aspect6667 video,
li.clipDetails.aspect6667 div.thumb div.img,
.videoPlayer.aspect6667 .accessDetails .thumb {
	aspect-ratio: 3 / 2;
}

.result.aspect5625, .result.aspect5625 .thumb, .result.aspect5625 video,
li.clipDetails.aspect5625 div.thumb div.img,
.videoPlayer.aspect5625 .accessDetails .thumb {
	aspect-ratio: 16 / 9;
}

.masonry .result.aspect0,
.masonry .result.aspect7500 { flex-grow: calc(4 / 3); }
.masonry .result.aspect7272 { flex-grow: calc(1.375); }
.masonry .result.aspect6667 { flex-grow: calc(3 / 2); }
.masonry .result.aspect5625 { flex-grow: calc(16 / 9); }








.results .result.log::after,
.results .result.reel::after {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    right: 1em;
    bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: black;
    z-index: 2;
    opacity: 0.5;
}

/*
.results .result.log::after {
    background-color: seagreen;
    content: 'L';
}

.results .result.reel::after {
    background-color: blueviolet;
    content: 'R';
}
*/

.results .result .thumb {
    display: block;
    height: 100%;
    color: transparent;
}

.results .result em {
    background-color: var(--hf-highlight-color);
    color: black;
    border-radius: 5px;
    padding: 1px 3px;
}

    .results .result .info .year::after {
        content: ', ';
    }

    .results .result .info {
        box-sizing: border-box;
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        color: var(--hf-strong-color);
		font-size: 14px;
		line-height: 1.25;
        /*
        margin: 0 1em 0 0;
		padding: 1em 0 1em 1em;
        width: calc(100% - 1em);
        */
        margin: 0;
        padding: 1em;
        width: 100%;
	}

	    /* .results .result .notice + .info { width: calc(100% - 5.25em); margin-right: 5.25em; } */
	    .results .result .notice + .info { padding-right: 5.25em; }

		.results .result .info .tapeId { 
			color: var(--hf-text-color); 
			margin-right: 0.5em;
		}

			/*
			.results .result .info .tapeId + .text:not(:empty)::before {
				content: ': ';
				color: var(--hf-strong-color);
				margin-right: 0.25em;
			}
			*/

	    .results .result:not(.noThumb) .info {
	        white-space: nowrap;
	        text-overflow: ellipsis;
			background: var(--hf-thumbnail-excerpt-bg);

			/* give it 2px of bleed to avoid singing edges during transitions */
			width: calc(100% + 4px);
			margin: -2px;
		}
  
		    body:not(.home) .results .result:not(.noThumb):hover .info,
		    body.home #videoWindow .results .result:not(.noThumb):hover .info {
		        max-height: calc(10em + 13px); /* 8 lines exactly + some padding space */
		        overflow-wrap: break-word;
		        white-space: normal;
		    }


	    .results .result.noThumb .info {
	        padding: 4.5em 3em;
	        height: 100%;
	        display: flex;
	        align-items: center;
		}

			.results .result:not(.noThumb) .excerpt br { display: none; }

			.results .result.noThumb .excerpt {
				max-height: 100%;
				/* max-height: 6.25em; /* 5 lines of text exactly */
				max-height: 6.1875em; /* less a bit so an <em> on the next line doesn't bleed through */
				overflow: hidden;
			}		

	    	.results .result .info .excerptFull { 
	    		display: none; 
	    		/* cursor: text; */
	    	}

		    body:not(.home) .results .result:not(.noThumb):hover .info .excerptFull,
		    body.home #videoWindow .results .result:not(.noThumb):hover .info .excerptFull { display: inline; }

		    body:not(.home) .results .result:not(.noThumb):hover .info .excerpt,
		    body.home #videoWindow .results .result:not(.noThumb):hover .info .excerpt { display: none; }

		    body.home #pageContent .results .result:not(.noThumb) .info { background: none; }
		    /* body.home #pageContent .results .result:not(.noThumb) .info .excerpt { display: none; } */
		    body.home #pageContent .results .result:not(.noThumb):hover .info { background: var(--hf-thumbnail-excerpt-bg); }
		    body.home #pageContent .results .result:not(.noThumb):hover .info .excerpt { display: inline; }



	.results .result a.showReel {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		z-index: 2;
	}


.genre .facet {
    display: inline-block;
    cursor: pointer;
}

.genre .facet .count {
    display:none;
}



.genre .facet {
	border: var(--hf-button-border);
	border-radius: 1em;
	margin: 10px 7px;
	box-shadow: var(--hf-button-shadow);
	text-transform: lowercase;
	transition: var(--hf-standard-transition), border 0s, padding 0s;
	color: var(--hf-button-text-color);
	padding: 6px 10px;
	font-size: var(--hf-button-font-size);
	position: relative;
}

.genre .facet:focus-visible,
.searchContainer .filter.genre .facet.selected:focus-visible {
  box-shadow: var(--hf-button-focus-shadow);
  outline: none;
  border: 3px solid var(--hf-background-color);
  padding: 3px 7px;
}
.genre .facet:hover {
  box-shadow: var(--hf-button-hover-shadow);
  color: var(--hf-button-text-hover-color);
}
.searchContainer .filter.genre .facet.selected:hover {
  box-shadow: none;
  color: var(--hf-button-text-hover-color);
}
.searchContainer .filter.genre div.facet.selected::after {
	font-family: var(--hf-icon-font);
	font-size: 16px;
	font-weight: 900;
	margin-right: 10px;
	content: '\f00d';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: var(--hf-background-color);
	font-size: 36px;
	opacity: 0;
	transition: var(--hf-standard-transition);
}
.searchContainer .filter.genre div.facet.selected:hover::after { opacity: 0.75; }

.genre .facet:active,
.searchContainer .filter.genre .facet.selected:active {
	box-shadow: var(--hf-button-active-shadow) !important;
		left: 1px;
		top: 1px;
}
.searchContainer .filter.genre .selectedList button {
	box-shadow: none;
	font-weight: normal;
	text-decoration: none;
}
.searchContainer .filter.genre .selectedList button:hover {
	box-shadow: none;
	text-decoration: none;
	color: var(--hf-input-hover-color);
}
.searchContainer .filter.genre .selectedList button::before {
	font-family: var(--hf-icon-font);
	font-size: 16px;
	font-weight: 900;
	margin-right: 10px;
	content: '\f00d';
	vertical-align: middle;
}

.resolution.filter {
    display: inline-block;
    position: relative;
    width: 100%;

}

.resolution.filter input[type="range"] {
	-webkit-appearance: slider-vertical;
	width: 20px;
}


.resolution #resolution_values_list  {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    display: block;
    left: 30px;
}

    .resolution #resolution_values_list option {
      height: 25%;
      margin-left: 15px;
    }

    .resolution #resolution_values_list option.selected {
        color: var(--hf-strong-color);
    }

.facet.selected { color: var(--hf-strong-color); }

.filter.date {
	margin-top: 1.5em;
}
.filter.date input {
		width: 4em;
		padding: 10px 0;
		margin: 0 1em 0 0.5em;
		text-align: center;
		font-size: inherit;
}


.results.empty .header,
.results.empty .footer {
    display: none;
}

.result video {
	width: 100%;
	z-index: 2;
	position: absolute;
	top:0;
	left:0;
}

.result .info {
	z-index: 3;
}





/* Inline #24 | https://dev.badmath.com/historicFilms/app/public_2023/search/?q=hippies&reel=3807&log=115857 */

#searchContainer {
  display: grid;
  grid-template-columns: min-content auto;
}

	/* #searchContainer.filtersClosed { grid-template-columns: 50px auto; } */

#searchContainer > * {
  grid-column: 2;
  padding: 0 var(--hf-standard-margin);
}

#searchContainer > .genre {
  padding: 0;
  position: relative;
}

#searchContainer .results { 
	padding: 0 var(--hf-standard-margin); 
	position: relative;
	z-index: 2;
}


#searchContainer .results .header,
#searchContainer .results .footer {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

	#searchContainer .results .pagination { flex-grow: 1; }

	#searchContainer .results .footer { justify-content: end; }

#searchContainer .results .header .pagination { margin-top: 0; }
#searchContainer .results .footer .pagination { margin-bottom: 0; }





:root {
	--hf-filter-sidebar-width: 285px;
	/* --hf-filter-tab-width: 180px; */
	--hf-filter-tab-width: 215px;
}

/*

.headerContainer.fixed + #pageContent #searchContainer .filter.genre .scroller-initiailzed .slick-arrow {
		height: 70px;
		width: 45px;
		opacity: 0;
}
.scroller-initiailzed .slick-next.slick-arrow {
		margin-left: -45px;
}

.scroller-initiailzed .slick-next.slick-arrow {
		border-radius: 35px 0 0 35px;
}

.scroller-initiailzed .slick-prev.slick-arrow {
		margin-right: -45px;
}

.scroller-initiailzed .slick-prev.slick-arrow {
		border-radius: 0 35px 35px 0;
}



button.slick-arrow:nth-child(1) {
		border-radius: 50%;
		width: 60px;
		height: 60px;
}

*/







#searchContainer .filters.sidebar {
	grid-column: 1;
	grid-row-start: 1;
	grid-row-end: 6;
	box-shadow: var(--hf-overlay-edge-shadow);
	border-radius: 0 var(--hf-border-radius) var(--hf-border-radius) 0;
	z-index: 3;
	background-color: var(--hf-panel-bg-color);
	padding: 0;
	position: relative;
	width: var(--hf-filter-sidebar-width);
	transition: var(--hf-standard-transition);
	align-self: start;

	top: -59px;
}

	#searchContainer.filtersClosed .filters.sidebar {
		margin-left: calc(var(--hf-filter-sidebar-width) * -1);
		box-shadow: none;
	}


#searchContainer.filtersClosed .filters.sidebar h2 {
	margin-left: var(--hf-filter-sidebar-width);
	width: var(--hf-filter-tab-width);
	border-radius: 0 var(--hf-border-radius) var(--hf-border-radius) 0;
}

#searchContainer.filtersClosed .filters.sidebar .toggle {
		margin-left: var(--hf-filter-tab-width);
		left: 0;
		transition: none;
}



	#searchContainer .filters.sidebar h2 {
		padding: 15px var(--hf-pane-padding);
		background-color: var(--hf-accent-color);
		color: var(--hf-button-text-hover-color);
		text-transform: uppercase;
		margin: 0;
		font-weight: var(--hf-strong-weight);
		line-height: 1em;
		transition: var(--hf-standard-transition);
		position: relative;
		width: 100%;
		box-sizing: border-box;
		border-radius: 0 var(--hf-border-radius) 0 0;
	}
	#searchContainer .filters.sidebar.hasFilters h2 { background-color: var(--hf-highlight-color-darker); }

	#searchContainer .filters.sidebar h2::before {
		content: '\f1de';
		font-family: var(--hf-icon-font);
		font-size: 18px;
		margin-right: 0.75em;
		font-weight: 900;
	}
	#searchContainer .filters.sidebar h2 button {
		position: absolute;
		min-height: 0;
		top: 50%;
		transform: translateY(-50%);
		margin-left: 2.5em;
		z-index: 2;
		display: none;

		background: none;
		border: none;
		box-shadow: none;
		text-transform: lowercase;
		color: var(--hf-button-text-hover-color);
		font-weight: 300;
		text-decoration: underline;
	}	
	#searchContainer .filters.sidebar.hasFilters h2 button { display: inline-flex; }
	.filtersClosed #searchContainer .filters.sidebar h2 button { margin-left: 0; }
	#searchContainer .filters.sidebar h2 button:hover {
		text-decoration: none;
	}
	#searchContainer .filters.sidebar h2 button:active {
		/*
		top: calc(50% + 1px);
		margin-left: calc(2em + 1px);
		left: unset;
		*/
		top: 50%;
		left: unset;
		box-shadow: none !important;
	}

	#searchContainer .filters.sidebar .toggle {
		z-index: 2;
	}

#searchContainer .filters.sidebar input[type="checkbox"], 
#searchContainer .filters.sidebar input[type="radio"] {
	border-color: var(--hf-panel-bg-color);
}


	#searchContainer .filters.sidebar input[type="checkbox"]:checked ~ span, 
	#searchContainer .filters.sidebar input[type="radio"]:checked ~ span {
		color: var(--hf-strong-color);
	}

	#searchContainer .filters.sidebar h3 {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
		margin-left: var(--hf-pane-padding);
		border-bottom: 1px solid var(--hf-faint-text-color);
		padding: 10px 0;
	}

	#searchContainer .filters.sidebar > div {
		margin: 1.5em 0 2em;
	}

	#searchContainer .filters.sidebar > div > div {
		margin-left: var(--hf-pane-padding);
	}





/* Inline #36 | https://dev.badmath.com/historicFilms/app/public_2023/?reel=64&in=232.00&out=444.00 */

#videoWindow .filter.genre {
		margin: 0 calc(var(--hf-standard-margin) * -1) var(--hf-standard-margin);
}
#videoWindow .searchContainer {
	margin-top: calc(var(--hf-standard-margin) * 2);
}
#videoWindow .reelMatches.searchContainer h2 { margin-bottom: 0; }
#videoWindow .reelMatches.searchContainer .results {
		margin: 0 calc(var(--hf-standard-margin) * -1) var(--hf-standard-margin);
}
#videoWindow .reelMatches.searchContainer .scroller-initiailzed .scroller {
    padding: var(--hf-standard-margin) calc(var(--hf-standard-margin) - 15px); /* 15px is margin on .result */
}

.sidebar .toggle {
	position: absolute;
	top: 0;
	height: calc(1em + 30px);
	width: 100%;
	box-sizing: border-box;
	cursor: pointer;
	transition: var(--hf-standard-transition);
	color: transparent !important;
	overflow: hidden;
	font-size: 18px;
	padding: 15px 20px;
}

		.sidebar .toggle::after {
			position: absolute;
			font-family: var(--hf-icon-font);
			font-size: 24px;
			font-weight: 900;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			left: 0;
			justify-content: end;
			right: 1em;
			content: '\f0d9';
			color: var(--hf-button-text-hover-color);
			transition: var(--hf-standard-transition);
		}

		@keyframes pullOutHover {
			from { transform: translateX(-0.125em); }
			to { transform: translateX(0.125em); }
		}

		.sidebar .toggle:hover::after { animation: pullOutHover 0.35s infinite alternate; }

		.filtersClosed .sidebar .toggle::after { content: '\f0da'; }


.stretchableMain {
  /* margin: 0 12px; */
}



.filter.genre .scroller-initiailzed {
	height: 100px;
}

.filter.genre .scroller-initiailzed .scroller {
    padding: var(--hf-standard-margin) calc(var(--hf-standard-margin) - 7px); /* 7px is margin on .facet */
}

.scroller-initiailzed .slick-prev.slick-arrow {
	border-radius: 0 50px 50px 0;
}

.scroller-initiailzed .slick-next.slick-arrow {
	border-radius: 50px 0 0 50px;
}

.scroller-initiailzed .slick-arrow {
	margin: 0;
	border-radius: 50%;
	height: 100px;
	width: 50px;
	padding: 0;
	justify-content: center;
	box-shadow: 0 0 9px rgba(0,0,0,0.2);
	background-color: var(--hf-panel-bg-color);
	overflow: hidden;
	border: none;
	color: transparent;
}
.scroller-initiailzed .slick-arrow.hidden {
	opacity: 0;
	pointer-events: none;
}

.scroller-initiailzed .slick-arrow::before {
	font-family: var(--hf-icon-font);
	font-size: 25px;
	font-weight: 900;
	position: absolute;
	color: var(--hf-button-text-color);
}

.scroller-initiailzed .slick-prev.slick-arrow::before {
	content: '\f0d9';
	left: 15px;
}

.scroller-initiailzed .slick-next.slick-arrow::before {
	content: '\f0da';
	right: 15px;
}


.slick-list.draggable {
	/* padding: 0 0 0 calc(var(--hf-standard-margin) - 7px); */
	padding: 0 0 0 var(--hf-standard-margin);
}

.scroller-initiailzed .slick-arrow.slick-disabled {
	opacity: 0;
	pointer-events: none;
}

.scroller-initiailzed .slick-prev.slick-arrow {
	margin-right: -50px;
}

.scroller-initiailzed .slick-next.slick-arrow {
	margin-left: -50px;
}

.scroller-initiailzed .slick-arrow {
	z-index: 2;
}

@media screen and (max-width: 1150px) {
		.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar h2,		
		.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar .toggle { transition: none; }
		body.filtersClosed .headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar h2 {
				position: fixed;
				top: 91px;
		}
		body.filtersClosed .headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar .toggle {
				position: fixed;
				top: 91px;
				left: calc(var(--hf-filter-sidebar-width) * -1);
				width: var(--hf-filter-sidebar-width);
		}
		body.filtersClosed .headerContainer.fixed #headerTop .searchBar {
				margin-left: calc(var(--hf-filter-tab-width) + 15px);
		}
		body.filtersClosed #layoutContainer > .headerContainer.fixed {
				background-color: transparent;
		}

		body.filtersClosed .headerContainer.fixed + #pageContent::after {
				content: '';
				position: fixed;
				top: 0;
				height: 151px;
				left: 0;
				right: 0;
				background-color: var(--hf-background-color);
				z-index: 2;
		}

}


@media screen and (max-width: 960px) {
	.headerContainer.fixed + #pageContent #searchContainer .filters.sidebar { top: 0; }
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar h2 {
		width: 4.25em;
		overflow: hidden;
		color: transparent;
	}
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar h2 .btnClearFilters { display: none; }
	.headerContainer.fixed + #pageContent #searchContainer .filters.sidebar h2::before {
		color: var(--hf-button-text-hover-color);
	}
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar .toggle {
		width: 8.125em !important;
	}
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar .toggle::after { right: 0.75em; }

	.headerContainer.fixed + #pageContent #searchContainer .filter.genre .scroller-initiailzed {
		height: 70px;
	}



	/* this is to keep the filter button from sticking out from behind the search box during transitions */
	body:not(.home) .headerContainer.fixed #headerTop .searchBar {
		background-color: var(--hf-background-color);
		margin-right: 0 !important;
		padding-right: 10px;
	}
	body.filtersClosed .headerContainer.fixed #headerTop .searchBar {
		margin-left: 90px;
		background-color: var(--hf-background-color);
	}
	

	/* smaller scroller buttonsz */
	.scroller-initiailzed .slick-arrow { height: 70px; }
	 .scroller-initiailzed .slick-arrow { width: 35px; }
	.scroller-initiailzed .slick-next.slick-arrow { margin-left: -35px; }
	.scroller-initiailzed .slick-prev.slick-arrow { margin-right: -35px; }
	.scroller-initiailzed .slick-prev.slick-arrow::before { left: 10px; }
	.scroller-initiailzed .slick-next.slick-arrow::before { right: 10px; }

	#videoWindow .mlt.searchContainer { margin-top: var(--hf-standard-margin); }

}



@media screen and (max-width: 640px) {
	#searchContainer:not(.filtersClosed) {
		grid-template-columns: 100%;
	}

	#searchContainer:not(.filtersClosed) > * {
		grid-column: 1;
	}

	#searchContainer:not(.filtersClosed) .filters.sidebar h2 { border-radius: 0; }

	#searchContainer:not(.filtersClosed) .filters.sidebar .toggle::after { content: '\f00d'; }
	#searchContainer.filtersClosed .sidebar .toggle::after { display: none; }
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed .filters.sidebar h2 { width: 3.25em; }

	.headerContainer.fixed + #pageContent #searchContainer .filters.sidebar { transition: width 0.2s ease-in-out, margin-left 0.2s ease-in-out }
	.headerContainer.fixed + #pageContent #searchContainer:not(.filtersClosed) .filters.sidebar {
		left: 0;
		width: 100%;
		border-radius: 0;
		position: fixed;
		top: 179px;
		max-height: calc(100vh - 179px);
		top: 149px;
		max-height: calc(100vh - 149px);
		overflow-y: auto;
		overflow-x: hidden;
	}

	body.filtersClosed .headerContainer.fixed #headerTop .searchBar {
			margin-left: 75px;
	}
	/*
	.headerContainer.fixed + #pageContent #searchContainer.filtersClosed > .genre {
		padding-left: 3.75em;
	}
	*/
}



.selectedList {
		padding: 0 calc(var(--hf-standard-margin) - 7px);
}
.selectedList:not(:empty) {
	margin-top: -10px;
	margin-bottom: calc(var(--hf-standard-margin) / 2);
}

.selectedList button {
		border: 0;
		min-height: 0;
}


.filter .count {
	font-size: 0.75em;
	display: block;
}

	.filter .count::before { content: '('; }
	.filter .count::after { content: ')'; }

.filter.boolean { 
	margin-top: 1.5em; 
	margin-bottom: 1.5em; 
}

	.filter.boolean.facet-list { 
		margin-top: 0; 
		margin-bottom: 0; 
	}

		.filter.boolean.facet-list .facet { 
			margin-top: 1.5em; 
			margin-bottom: 1.5em; 
		}

.filter label,
.filter .label {
	cursor: pointer;
	transition: var(--hf-standard-transition);
}
.filter .facet.disabled label,
.filter .facet.disabled .label,
.filter.disabled label,
.filter.disabled .label {
	cursor: default;
}

.filter label:hover,
.filter .label:hover {
	color: var(--hf-strong-color);
}
.filter .facet.disabled label:hover,
.filter .facet.disabled .label:hover,
.filter.disabled label:hover,
.filter.disabled .label:hover {
	color: inherit;
}

.filter.boolean label {
	padding-left: var(--hf-sidebar-input-width);
	position: relative;
	display: block;
}

.filter.boolean input[type="checkbox"] {
	position: absolute;
	left: calc((var(--hf-sidebar-input-width) / 2) - 0.75em);
	margin: 0;
}


.pageinfo {
	font-size: 18px;
	font-style: italic;
	white-space: nowrap;
}


.results .pageinfo {
	margin-bottom: 1em;
}

.pageinfo .range { display: none; }

.pageinfo .total {
	display: flex;
	align-items: center;
	gap: 0.25em;
}
/* .pageinfo .total::after { content: ':'; } */

.mltInfo {
	display: flex;
	align-items: center;
}

	.mltInfo a {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		padding: 0.5em 0.25em;
		border-radius: var(--hf-border-radius);
		margin-left: 0.25em;
		font-style: normal;
	}

		.mltInfo a:hover { background-color: var(--hf-panel-bg-color); }

		.mltInfo a img {
			margin: 0 5px 0 0;
			height: 2.25em;
		}

		.mltInfo a span {
			display: inline-block;
			white-space: normal;
			max-height: 2.25em;
			overflow: hidden;
		}



	/* slider */
	.ui-widget.ui-slider {
    	position: relative;
		border-radius: 0.5em;
		background-color: transparent;
		border: none;
		box-shadow: var(--hf-input-field-shadow), #0003 1px -1px 4px inset;
	}

		.ui-widget.ui-slider.ui-slider-vertical {
			height: calc(100% - 2em);
			width: 1em;
			justify-self: center;
			margin: 1em 0.75em 1em 0;
		}

		.ui-widget.ui-slider.ui-slider-horizontal {
		    height: 1em;
		    width: calc(100% - 2em);
		}

		.ui-widget.ui-slider .ui-slider-range {
		    position: absolute;
		    z-index: 1;
		    display: block;
		    border: 0;
			background-color: var(--hf-accent-color);
			border-radius: 0 0 0.5em 0.5em;
			font-size: 1em;
			box-shadow: #0005 1px -1px 4px inset;
			opacity: 0.75;
			transition: var(--hf-standard-transition);
		}

			.ui-widget.ui-slider.ui-slider-horizontal .ui-slider-range {
			    top: 0;
			    height: 100%;
			}
			.ui-widget.ui-slider.ui-slider-horizontal .ui-slider-range-min { left: 0; }
			.ui-widget.ui-slider.ui-slider-horizontal .ui-slider-range-max { right: 0; }


			.ui-widget.ui-slider.ui-slider-vertical .ui-slider-range {
			    left: 0;
			    width: 100%;
			}
			.ui-widget.ui-slider.ui-slider-vertical .ui-slider-range-min { bottom: 0; }
			.ui-widget.ui-slider.ui-slider-vertical .ui-slider-range-max { top: 0; }


		.ui-widget.ui-slider .ui-slider-handle {
		    position: absolute;
		    z-index: 2;
		    cursor: pointer;
			border-radius: 50%;
			background-color: var(--hf-accent-color);
			border: none;
			width: 1.5em;
			height: 1.5em;
			box-shadow: var(--hf-knob-shadow);
			transition: var(--hf-standard-transition);

		    -ms-touch-action: none;
		    touch-action: none;
		}

		
			.ui-widget.ui-slider.ui-slider-vertical .ui-slider-handle {
				margin-bottom: -0.75em;
				left: -0.25em;
			}
					
			.ui-widget.ui-slider.ui-slider-horizontal .ui-slider-handle {
			    top: -0.25em;
			    margin-left: -0.75em;
			}

			.ui-widget.ui-slider .ui-slider-handle:focus-visible {
				outline: none;
				/* box-shadow: var(--hf-knob-shadow), var(--hf-button-focus-shadow); */
				box-shadow: var(--hf-knob-shadow), var(--hf-button-hover-shadow);
			}

			.ui-widget.ui-slider .ui-slider-handle:hover {
				box-shadow: var(--hf-knob-shadow), var(--hf-button-hover-shadow);
			}


.filter.range select { display: none; }

.searchContainer .filter.range {
	display: grid;
	grid-template-columns: var(--hf-sidebar-input-width) auto;
}

	.searchContainer .filter.range .labels {
		display: grid;
		align-content: space-between;
		margin-bottom: calc(0.75em * -1.125); /* height of a ".count" line */
	}

		.searchContainer .filter.range .labels .label { 
			margin: 0.5em 0;
			position: relative; 
		}
		
			.searchContainer .filter.range .labels .label.selected { color: var(--hf-strong-color); }

			/* interval markers */
			.searchContainer .filter.range .labels .label:not(:first-child):not(:last-child)::before {
				content: '';
				position: absolute;
				width: 0.75em;
				height: 1px;
				background-color: var(--hf-faint-text-color);
				top: calc(50% - (0.75em * 1.125 / 2)); /* (0.75em * 1.125 / 2) = half the height of the ".count" line */
				left: calc(-0.75em - (var(--hf-sidebar-input-width) - (1.75em)) / 2); /* -0.75em offset for the slider's right margin, minus half the available space (1.75em is the width of the slider) */
			}

#mainArea .result .saveClip,
#videoWindow .result .saveClip {
	position: absolute;
	bottom: 0.75em;
	right: 0.75em;
	margin: 0;
	box-shadow: none;
	z-index: 4;
}
#mainArea .result .saveClip::before,
#videoWindow .result .saveClip::before {
	content: '\f217';
}
#mainArea .result .saveClip:active,
#videoWindow .result .saveClip:active {
	left: unset;
	top: unset;
	margin-right: -1px;
	margin-bottom: -1px;
}

.result .showMlt {
	position: absolute;
	bottom:1em;
	left:1em;
	z-index: 4;
	text-decoration: none;
	color: var(--hf-text-color);
	transition: var(--hf-standard-transition);
}
.result .showMlt:hover { 
	color: var(--hf-strong-color); 
	text-decoration: underline;
}


.result:not(.noThumb):not(li)::before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	right: -1px; /* to compensate for a 1px artifact in Mozilla on high-density pixel displays */
	background: var(--hf-thumbnail-excerpt-bg);
	height: 6em;
	transform: scaleY(-100%);
	z-index: 3;
	opacity: 0.8;
	pointer-events: none;
}


@media screen and (pointer: fine) {
	#mainArea .result .saveClip,
	#videoWindow .result .saveClip,
	.result .showMlt,
	.searchContainer .results .result > a.showMlt,
	body.home #pageContent .results .result:not(.noThumb) .info .excerpt { display: none; }
	body.home #pageContent .results .result:not(.noThumb):hover .info .excerpt { display: inline; }
	.result:hover .showMlt,
	.searchContainer .results .result:hover > a.showMlt { display: block; }
	#mainArea .result:hover .saveClip,
	#videoWindow .result:hover .saveClip { display: flex; }
	.result:not(.noThumb):not(li)::before { opacity: 0; }
	.searchContainer:not(.binContents) .result:not(li):hover::before { opacity: 0.8; }
}


.result .notice {
	position: absolute;
	top: 0.75rem;
	right: 0.75rem;
	background: var(--hf-notice-background);
	z-index: 4;
	border-radius: var(--hf-border-radius);
	color: var(--hf-background-color);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.125em;
	box-sizing: border-box;

	/* padding: 1rem 0.5rem; */
	/* max-width: 3.25rem; */
	max-width: 2.75rem;
	padding: 0.75rem 0.25rem;
	transition: all 0.2s ease-in-out 0.1s;
	opacity: 0.5;
	min-height: calc(1.125em + 1.5rem);
	display: flex;
	align-items: center;
}
	.result .notice > span {
		transition: all 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out, max-height 0.2s ease-in-out;
		position: relative;
		display: inline-block;
		overflow: hidden;

		max-height: 0;
		left: 100%;
		opacity: 0;
	}

		/*
		.result .notice:hover, 
		li.result .notice { 
		*/
		.result .notice:hover {
			transition: all 0.2s ease-in-out 0.1s, opacity 0s;
			opacity: 1; 
			max-width: 85%; 
			/* padding: 1rem 3.5rem 1rem 0.75rem; */
			padding: 0.5rem 3.5rem 0.5rem 0.75rem;
			transition: all 0.2s ease-in-out;
			cursor: default;
		}

			/* li.result .notice { pointer-events: none; } */

			.result .notice:hover > span {
				max-height: 6em;
				left: 0;
				opacity: 1;
				transition: all 0.2s ease-in-out, opacity 0.2s ease-in-out 0.1s, max-height 0.2s ease-in-out 0.1s;
			}

	.result .notice::after {
		position: absolute;
		/*
		top: 0.5rem;
		bottom: 0.5rem;
		*/
		/*
		top: 1rem;
		right: 0.5rem;		
		width: 2rem;
		font-size: 24px;
		*/
		display: flex;
		font-family: var(--hf-icon-font);
		font-weight: 900;
		content: "\f4e2";
		align-items: center;

		top: 0.75rem;
		right: 0.25rem;
		width: 2.25rem;
		font-size: 20px;
		justify-content: center;
	}

	.result .notice a {
		color: var(--hf-highlight-color-darker);
		text-decoration: underline;
	}

	.result .notice a:hover {
		color: var(--hf-accent-color-darker);;
	}

/* there's a pair of each of these, because on the bin page, the  */
/* restriction class is on a child of .result, not .result itself */
.result.specialtyCollection .notice::after,
.result .specialtyCollection .notice::after { 
	content: "\e235"; 
	color: var(--hf-highlight-color-darker);
}
.result.noVideo .notice::after,
.result .noVideo .notice::after { content: "\f4e2"; }
.result.restricted .notice::after,
.result .restricted .notice::after { 
	content: "\f023"; 
	color: var(--hf-accent-color);
}
.result.restrictedAudio .notice::after,
.result .restrictedAudio .notice::after { 
	content: "\f023"; 
	color: var(--hf-accent-color);
}
.result.temporailyUnavailable .notice::after,
.result .temporailyUnavailable .notice::after { 
	content: "\f4e2"; 
	color: var(--hf-accent-color);
}

html.dialogOpen {
	overflow: hidden;
}


/* bin sidebar */

#headerTop .menu.binMenu {
    position: fixed;
	transition: var(--hf-standard-transition), top 0s;
    top: 86px;
    margin-right: 0;
    bottom: 0;
    width: var(--hf-bin-sidebar-width);
    box-shadow: var(--hf-overlay-edge-shadow);
    background-color: var(--hf-panel-bg-color);
}

	.headerContainer.fixed #headerTop .menu.binMenu { top: var(--hf-home-page-banner-height); }




.scroller-container {
	transition: var(--hf-standard-transition);}

/* html.binSidebarOpen #videoWindow.popup { padding-right: var(--hf-bin-sidebar-width) !important; } */
/* html.binSidebarOpen .videoPlayer .headerContainer.fixed + #navigationControls { right: var(--hf-bin-sidebar-width); } */
html.binSidebarOpen .headerContainer:not(.fixed) #headerTop .searchBar { 
	margin-right: calc(var(--hf-bin-sidebar-width) + var(--hf-standard-margin)); 
	transition: right 0.2s ease-in-out, width 0.2s ease-in-out, left 0.2s ease-in-out, margin-right 0s;
}
html.binSidebarOpen #pageContent { margin-right: var(--hf-bin-sidebar-width); }
html.binSidebarOpen .scroller-container { margin-right: calc(var(--hf-bin-sidebar-width) * -1); }
html.binSidebarOpen .myLinks .menuGroup a.showMenu.showBins { 
	border-radius: var(--hf-border-radius) 0 0 0;
    min-width: var(--hf-bin-sidebar-width); /* min-width and max-width with the same value instead of just using width, so that we can     */
    max-width: var(--hf-bin-sidebar-width); /* animate this from an auto width by applying a wide min- and max- range to its default state */
	box-shadow: var(--hf-overlay-edge-shadow);
}

html.binSidebarOpen .myLinks a.showBins .count::before { content: ''; }
#headerTop .menu .binHeader .count:not(:empty)::after,
html.binSidebarOpen .myLinks a.showBins .count:not(:empty)::after { 
	content: 'clips'; 
	font-size: 0.75em;
	display: block;
}
html.binSidebarOpen #headerTop .menu {
    right: 0;
}

html.binSidebarOpen body.search #layoutContainer > .headerContainer:not(.fixed) #headerTop .searchBar { 
	width: calc(100% - var(--hf-bin-sidebar-width) - var(--hf-filter-sidebar-width) - var(--hf-standard-margin) - var(--hf-standard-margin));
}
html.binSidebarOpen body.search.filtersClosed #layoutContainer > .headerContainer:not(.fixed) #headerTop .searchBar { 
	width: calc(100% - var(--hf-bin-sidebar-width) - var(--hf-filter-tab-width) - var(--hf-standard-margin) - var(--hf-standard-margin));
}

html.binSidebarOpen #videoWindow .headerContainer:not(.fixed) #headerTop .searchBar {
	width: calc(100% - var(--hf-next-prev-width) - var(--hf-bin-sidebar-width) - var(--hf-standard-margin));
}
html.binSidebarOpen #footer {
	margin-right: var(--hf-bin-sidebar-width);
}
html.binSidebarOpen #videoWindow #footer {
	margin-right: 0;
}
/*
html.binSidebarOpen #headerTop {
	grid-template-columns: 1fr 258px 2fr;
}
*/

html.binSidebarOpen .home #videoWindow .headerContainer:not(.fixed) #headerTop .homeSearchBar .searchBar { width: calc(100% - var(--hf-next-prev-width) - var(--hf-standard-margin)); }


/* cleanup */


/* public2UI.css | https://dev.badmath.com/historicFilms/app/public_2023/template/public2UI.css */

.ui-widget.ui-slider .ui-slider-range {
  /* opacity: 0.75; */
  opacity: 0.6;
  background-color: var(--hf-accent-color-darker);
  opacity: 1;
}

html.binSidebarOpen .myLinks a.showBins .count::after {
  display: block;
  font-size: 0.75em;
}

html.binSidebarOpen .myLinks .menuGroup a.showMenu.showBins,
html.binSidebarOpen .myLinks .menuGroup a.showMenu.showBins > a {
  display: flex;
  align-items: center;
  width: 100%;

  transition: none;
}

#headerTop .menu.binMenu {
  /* top: 86px; */
  top: 87px;
}


.closeContainer {
  /* display: block; */
  /* position: absolute; */
  /* right: 0; */
  /* top: 0; */
  /* height: 2.75em; */
  /* width: 2.75em; */
  width: 44px;
  align-items: center;
  justify-content: center;
}

/* Inline #12 | https://dev.badmath.com/historicFilms/app/public_2023/search/?q=%7B16474%7D */

.myLinks .menuGroup a.showMenu.showBins .count {
	text-align: center;
	justify-content: end;
	height: 33px;
	margin-left: 1em;
}

html.binSidebarOpen .myLinks a.showBins .name {
	flex-grow: 1;
	max-width: 100%;
}


#headerTop .menu .binHeader.ui-state-active {
	background-color: transparent;
	border-bottom-color: rgba(0,0,0,0.5);
	padding-right: 80px;
}

#headerTop .menu .binHeader.ui-state-active .count {
  display: none;
}

#headerTop .menu .binHeader.ui-state-active .name {
	line-height: 1.5em;
	margin: 0.25em 0;
}

#headerTop .menu .binHeader.ui-state-active::before {
  content: '\f106';
  line-height: 1.5em;
}

#headerTop .menu .binList > .menuItem.newBin {
  position: absolute;
  top: 0;
  right: 0;
}

#headerTop .menu .binList > .menuItem.newBin .button {
  box-shadow: none;
  color: var(--hf-strong-color);
}

#headerTop .menu .binList > .menuItem.newBin .button:hover {
  color: var(--hf-text-color);
}

#headerTop .menu .binList > .menuItem.newBin .button::before {
  content: '\2b';
  font-family: var(--hf-icon-font);
  display: flex;
  color: var(--hf-inner-panel-bg-color);
  background-color: var(--hf-strong-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 16px;
}

#headerTop .menu .binList > .menuItem.newBin .button:hover::before {
  background-color: var(--hf-text-color);
}

#headerTop .menu .binList > .menuItem.newBin .button::before {
  transition: var(--hf-standard-transition);
  margin-bottom: 5px;
}
#headerTop .menu .binList .menuItem.newBin .button::after {
	content: '';
	position: absolute;
	border-radius: var(--hf-border-radius);
	background-color: var(--hf-accent-color-darker);
	width: 100%;
	height: calc(100% + 6px);
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	z-index: -1;
	pointer-events: none;
	opacity: 0;
	transition: var(--hf-standard-transition);
}


/* #headerTop .menu .binList > .menuItem:last-child .button { */
#headerTop .menu .binList .menuItem.newBin .button {
  flex-direction: column;
}


#headerTop .menu .binList .menuItem.newBin {
  padding-top: 10px;
		top: 5px;
}

#headerTop .menu .binList .menuItem.newBin.ui-droppable-active .button::before {
	color: var(--hf-accent-color-darker);
}
#headerTop .menu .binList .menuItem.newBin.ui-droppable-active .button::after {
	opacity: 1;
}
#headerTop .menu .binList .menuItem.newBin.ui-droppable-active .button { 
	z-index: 1; 
}


#headerTop .menu .binList .menuItem.newBin.ui-droppable-active.dropHover .button { 
	z-index: 1; 
	color: black; 
}
#headerTop .menu .binList .menuItem.newBin.ui-droppable-active.dropHover .button::before { 
	color: var(--hf-highlight-color);
	background-color: black; 
}
#headerTop .menu .binList .menuItem.newBin.ui-droppable-active.dropHover .button::after {
	background-color: var(--hf-highlight-color);
	opacity: 1;
}


#headerTop .menu .binList .menuItem.newBin .button {
  margin-right: 0;
  border: none;
}

.binList .listBox {
  padding: 0;
  margin: 0;
}

#headerTop .menu li.clip .handle::before {
  content: '\f58e';
  font-family: var(--hf-icon-font);
  font-size: 16px;
  font-weight: 900;
}

#headerTop .menu li.clip .handle:hover {
  color: inherit;
}

.title {
}

#headerTop .menu li.clip .closeContainer {
  grid-row: 1 / 3;
  display: flex;
  position: relative;
  right: unset;
  top: unset;
  height: unset;
  width: 44px;
}


#headerTop .menu li.clip .title .comment {
  font-weight: 400;
  font-style: italic;
}

#headerTop .menu li.clip .closeContainer .close {
  position: relative;
  color: var(--hf-inner-panel-bg-color);
  background-color: var(--hf-faint-text-color);
}

#headerTop .menu li.clip .closeContainer .close::after {
  font-size: 12px;
}

#headerTop .menu li.clip .closeContainer .close {
  font-size: 12px;
}

#headerTop .menu li.clip .closeContainer .close:hover {
  background-color: var(--hf-strong-color);
  box-shadow: none;
}

#headerTop .menu .binHeader.ui-state-active::before {
  content: '\f106';
  cursor: pointer;
}

#headerTop .menu .binContainer.collapsed .binHeader.ui-state-active::before {
  content: '\f107';
}

#headerTop .menu .binHeader.ui-state-active {
  color: var(--hf-strong-color);
}

#headerTop .menu .binHeader:hover {
  color: var(--hf-strong-color);
}

#headerTop .menu .binHeader::before {
	top: 1.25em;
}

#headerTop .menu .binHeader .note:not(:empty) {
  margin-top: 0.75em;
}

#headerTop .menu .binHeader.ui-state-active .note {
  grid-column: 1;
  margin-top: 0;
}
#layoutContainer .banner {
	position: absolute;
	background-color: var(--hf-highlight-color-darker);
	pointer-events: all;
	color: var(--hf-strong-color);
	height: var(--hf-home-page-banner-height);
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 4em;
	/* z-index: 15; */ /* conflicts with sign-in dialog */
	transition: var(--hf-standard-transition);
	opacity: 1;
}

	@media screen and (max-width: 640px) {
		:root { --hf-home-page-banner-height: 85px; }
		#layoutContainer .banner { padding: 0 3.5em 0 1em; }
		#layoutContainer .banner div { font-size: 0.8em; }
	}

	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) { top: var(--hf-home-page-banner-height); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) { top: calc(var(--hf-home-page-banner-height) * 2); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) { top: calc(var(--hf-home-page-banner-height) * 3); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) { top: calc(var(--hf-home-page-banner-height) * 4); }

	#layoutContainer .banner.hidden { opacity: 0; }

	#layoutContainer .banner:not(.hidden) ~ .headerContainer { top: var(--hf-home-page-banner-height); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) ~ .headerContainer { top: calc(var(--hf-home-page-banner-height) * 2); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) ~ .headerContainer { top: calc(var(--hf-home-page-banner-height) * 3); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) ~ .headerContainer { top: calc(var(--hf-home-page-banner-height) * 4); }

	/* #layoutContainer .banner:not(.hidden) ~ .headerContainer .menu.binMenu, */
	#layoutContainer .banner:not(.hidden) ~ #pageContent { margin-top: var(--hf-home-page-banner-height); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) ~ #pageContent { margin-top: calc(var(--hf-home-page-banner-height) * 2); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) ~ #pageContent { margin-top: calc(var(--hf-home-page-banner-height) * 3); }
	#layoutContainer .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) + .banner:not(.hidden) ~ #pageContent { margin-top: calc(var(--hf-home-page-banner-height) * 4); }

	#layoutContainer .banner::before {
		content: '\f129';
		font-family: var(--hf-icon-font);
		font-weight: 900;
		font-size: 16px;
		background-color: var(--hf-background-color);
		color: var(--hf-strong-color);
		border-radius: 50%;
		width: 1.75em;
		height: 1.75em;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.75em;
		flex-shrink: 0;
	}

	#layoutContainer .banner .close {
		position: absolute;
		right: 0.5em;
		top: 50%;
		transform: translateY(-50%);
	}
	#layoutContainer .banner .close:hover,
	#layoutContainer .banner .close:focus-visible {
		box-shadow: none;
		color: var(--hf-background-color);
	}
	#layoutContainer .banner .close:active {
		top: unset;
		left: unset;
		margin-left: 1px;
		margin-top: 1px;
	}

 	#layoutContainer .banner a:hover { color: var(--hf-text-color); }

#signInForm.overlay {
	display: block;
}

.binContainer.collapsed .binContent {
	display:none;
}


.results .result.firstTouch {
	border: 2px solid green;
}

.results .result.secondTouch {
	border: 2px solid blue;
}




/* About Us page */


#mainArea.aboutUs {
	max-width: unset;
}

	#mainArea.aboutUs .textContent { padding: 0; }

		#mainArea.aboutUs .textContent > * {
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
		}

		#mainArea.aboutUs .textContent h1,
		#mainArea.aboutUs .textContent h2 { text-align: center; }

		#mainArea.aboutUs .textContent h1 { text-transform: uppercase; margin: 2em auto 0.5em; }
		#mainArea.aboutUs .textContent h2 { margin: 0 auto 3em; }

		#mainArea.aboutUs .textContent .promoStrip {
			margin: 3em -30px 0;
			max-width: unset;
			background-image: url('../img/promoStrip.jpg');
			background-size: 3082px 160px;
			background-position: center;
			height: 160px;
		}

			@media screen and (max-width: 632px) {
				#mainArea.aboutUs .textContent .promoStrip {
					margin-left: -10px;
					margin-right: -10px;
				}
			}

		#mainArea.aboutUs .logos {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: var(--hf-standard-margin);
			margin-top: 3em;
		}

			@media screen and (max-width: 768px) {
				#mainArea.aboutUs .logos {
					gap: 0 var(--hf-standard-margin);
				}
			}

			#mainArea.aboutUs .logos img {
				width: 160px;
				height: 160px;
			}
