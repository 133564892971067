/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=2ab38527-b719-40b0-a687-fac3df811a09");

@font-face{
font-family:"Calibri W01_n4";
src:url("d4acc8cd-67f2-4139-80be-d669bb2564d7.eot?#iefix") format("eot")
}
@font-face{
font-family:"Calibri W01";
src:url("d4acc8cd-67f2-4139-80be-d669bb2564d7.eot?#iefix");
src:url("d4acc8cd-67f2-4139-80be-d669bb2564d7.eot?#iefix") format("eot"),url("367341fc-4cc6-4a79-89ad-d6c61561611e.woff2") format("woff2"),url("1ff86dd4-d3b2-4325-89bc-c6eb493cedba.woff") format("woff"),url("aa6b4b64-5ce0-48fd-887a-bece037cd9c9.ttf") format("truetype"),url("59b6060c-d311-413e-b351-d79aaf982f7a.svg#59b6060c-d311-413e-b351-d79aaf982f7a") format("svg");
font-weight: 400;
font-style: normal;
}
@font-face{
font-family:"Calibri W01_i4";
src:url("f5fb4de8-efeb-47a8-94a7-50b825a6966a.eot?#iefix") format("eot")
}
@font-face{
font-family:"Calibri W01";
src:url("f5fb4de8-efeb-47a8-94a7-50b825a6966a.eot?#iefix");
src:url("f5fb4de8-efeb-47a8-94a7-50b825a6966a.eot?#iefix") format("eot"),url("e1cc7218-de20-4af1-a27c-6f325a4b0f0f.woff2") format("woff2"),url("cac25ebb-269d-41e9-8ee5-78589746706e.woff") format("woff"),url("6a4b5dab-ebbd-4741-80c7-3b30cd46e05d.ttf") format("truetype"),url("318af76f-0012-4791-a785-d241f2613955.svg#318af76f-0012-4791-a785-d241f2613955") format("svg");
font-weight: 400;
font-style: italic;
}
@font-face{
font-family:"Calibri W01_n7";
src:url("26989e0d-2435-46c9-ab53-ae63e1816b99.eot?#iefix") format("eot")
}
@font-face{
font-family:"Calibri W01";
src:url("26989e0d-2435-46c9-ab53-ae63e1816b99.eot?#iefix");
src:url("26989e0d-2435-46c9-ab53-ae63e1816b99.eot?#iefix") format("eot"),url("3ded7064-503f-4fa0-853b-9f7a2cf55f20.woff2") format("woff2"),url("4e7b2126-77d3-4e53-810d-7ccfde46450a.woff") format("woff"),url("dcb27c78-fcd6-43bb-ab8b-9152bcfeb336.ttf") format("truetype"),url("ab531dcb-6916-45d8-9326-ab3e33d75ef3.svg#ab531dcb-6916-45d8-9326-ab3e33d75ef3") format("svg");
font-weight: 700;
font-style: normal;
}
@font-face{
font-family:"Calibri W01_i7";
src:url("c0f723f3-c373-4abc-9043-0fb0b460b8dc.eot?#iefix") format("eot")
}
@font-face{
font-family:"Calibri W01";
src:url("c0f723f3-c373-4abc-9043-0fb0b460b8dc.eot?#iefix");
src:url("c0f723f3-c373-4abc-9043-0fb0b460b8dc.eot?#iefix") format("eot"),url("fe969ae7-f9ce-40b2-93cc-9cc7138809a2.woff2") format("woff2"),url("e43fbe67-bdec-48c3-b305-e4f021d8e755.woff") format("woff"),url("7cf1f907-81f8-4b27-8ae5-986118860da1.ttf") format("truetype"),url("d5b3dbd1-291d-4354-bdcd-216ef956c562.svg#d5b3dbd1-291d-4354-bdcd-216ef956c562") format("svg");
font-weight: 700;
font-style: italic;
}
@font-face{
font-family:"Consolas W01 Mono_n4";
src:url("188e0718-2dc8-42dc-a1f9-adb65fc35380.eot?#iefix") format("eot")
}
@font-face{
font-family:"Consolas W01 Mono";
src:url("188e0718-2dc8-42dc-a1f9-adb65fc35380.eot?#iefix");
src:url("188e0718-2dc8-42dc-a1f9-adb65fc35380.eot?#iefix") format("eot"),url("0033abc7-771b-4217-92d3-871e311dfbc3.woff2") format("woff2"),url("357228dd-78e5-42df-b336-423697004385.woff") format("woff"),url("2ebb6c15-5d97-4894-83af-5eb6eed3ff1a.ttf") format("truetype"),url("926c05c5-6e36-4ca8-bc94-0817930ee447.svg#926c05c5-6e36-4ca8-bc94-0817930ee447") format("svg");
font-weight: 400;
font-style: normal;
}
@font-face{
font-family:"Consolas W01 Mono_i4";
src:url("04a9c5d7-acc2-47c3-8703-6cce9d27ea11.eot?#iefix") format("eot")
}
@font-face{
font-family:"Consolas W01 Mono";
src:url("04a9c5d7-acc2-47c3-8703-6cce9d27ea11.eot?#iefix");
src:url("04a9c5d7-acc2-47c3-8703-6cce9d27ea11.eot?#iefix") format("eot"),url("ba42b7df-6c38-406a-ab91-5740d8a2d097.woff2") format("woff2"),url("34093438-0e53-41cd-beb1-b85a58416f19.woff") format("woff"),url("a5cdc89d-60c3-41cd-a3b5-fa017b5f83d2.ttf") format("truetype"),url("33e55ade-f887-4ffc-948b-8ec79942f576.svg#33e55ade-f887-4ffc-948b-8ec79942f576") format("svg");
font-weight: 400;
font-style: italic;
}
@font-face{
font-family:"Consolas W01 Mono_n7";
src:url("09cf85cd-ddb8-460a-b8b6-084574a729e1.eot?#iefix") format("eot")
}
@font-face{
font-family:"Consolas W01 Mono";
src:url("09cf85cd-ddb8-460a-b8b6-084574a729e1.eot?#iefix");
src:url("09cf85cd-ddb8-460a-b8b6-084574a729e1.eot?#iefix") format("eot"),url("84b16eee-7842-4d13-bfa2-b7f7d9de7699.woff2") format("woff2"),url("0f04e6f3-790c-46b2-b364-a4506369c0dd.woff") format("woff"),url("ebcf82f7-690d-4e9c-ad41-eb776142fb56.ttf") format("truetype"),url("2ef22afa-b3fa-4058-8cea-f549db526cc8.svg#2ef22afa-b3fa-4058-8cea-f549db526cc8") format("svg");
font-weight: 700;
font-style: normal;
}
@font-face{
font-family:"Consolas W01 Mono_i7";
src:url("5c505682-a2a0-4ff8-82d2-6a57159b9b13.eot?#iefix") format("eot")
}
@font-face{
font-family:"Consolas W01 Mono";
src:url("5c505682-a2a0-4ff8-82d2-6a57159b9b13.eot?#iefix");
src:url("5c505682-a2a0-4ff8-82d2-6a57159b9b13.eot?#iefix") format("eot"),url("f97b34c8-bdd7-430e-aea4-72b0babfe86c.woff2") format("woff2"),url("43cc3a16-f353-4cca-9a20-db7cfb6c78a2.woff") format("woff"),url("861c3086-e87b-4d33-871e-bfdc191e57fd.ttf") format("truetype"),url("61101440-c6fa-4f5c-8dc0-9ebb9c3cebe0.svg#61101440-c6fa-4f5c-8dc0-9ebb9c3cebe0") format("svg");
font-weight: 700;
font-style: italic;
}
@font-face{
font-family:"Georgia W01_n4";
src:url("75be06e7-f47c-4da4-9560-6da7d93e6396.eot?#iefix") format("eot")
}
@font-face{
font-family:"Georgia W01";
src:url("75be06e7-f47c-4da4-9560-6da7d93e6396.eot?#iefix");
src:url("75be06e7-f47c-4da4-9560-6da7d93e6396.eot?#iefix") format("eot"),url("3056c93b-ddd6-4a07-b6ac-0b3947b2ee38.woff2") format("woff2"),url("39a18a82-9fba-4a18-9b26-872bc05243b8.woff") format("woff"),url("8e57c617-cc4f-47b5-8efb-392f187458ab.ttf") format("truetype"),url("381bc0d5-81a7-4124-ae56-5bc752d472b2.svg#381bc0d5-81a7-4124-ae56-5bc752d472b2") format("svg");
font-weight: 400;
font-style: normal;
}
@font-face{
font-family:"Georgia W01_i4";
src:url("2cb5a0e9-e849-4157-bc27-c40666689ba2.eot?#iefix") format("eot")
}
@font-face{
font-family:"Georgia W01";
src:url("2cb5a0e9-e849-4157-bc27-c40666689ba2.eot?#iefix");
src:url("2cb5a0e9-e849-4157-bc27-c40666689ba2.eot?#iefix") format("eot"),url("2af7771f-8ddd-4afe-9a23-1307629889dc.woff2") format("woff2"),url("c93679cc-a6a0-44eb-8596-adb4d5cb22de.woff") format("woff"),url("b7b3b2b4-01fb-43a6-8331-4bcf91428fb3.ttf") format("truetype"),url("39f8467c-4358-4a4b-a43f-8b26bd049b35.svg#39f8467c-4358-4a4b-a43f-8b26bd049b35") format("svg");
font-weight: 400;
font-style: italic;
}
@font-face{
font-family:"Georgia W01_n7";
src:url("2338c483-564b-4c57-9234-83177c8a3d98.eot?#iefix") format("eot")
}
@font-face{
font-family:"Georgia W01";
src:url("2338c483-564b-4c57-9234-83177c8a3d98.eot?#iefix");
src:url("2338c483-564b-4c57-9234-83177c8a3d98.eot?#iefix") format("eot"),url("886cc2e0-6d9f-4b2e-a486-0148b53b006e.woff2") format("woff2"),url("d2f9060f-3485-482f-b5a0-b91faa67bab3.woff") format("woff"),url("ba156a5c-30d4-40b0-a2c3-f47833f4777d.ttf") format("truetype"),url("abacc501-a85f-4796-b521-492b016ddbf9.svg#abacc501-a85f-4796-b521-492b016ddbf9") format("svg");
font-weight: 700;
font-style: normal;
}
@font-face{
font-family:"Georgia W01_i7";
src:url("23bc0f18-2c73-491d-a06b-f41a2da880aa.eot?#iefix") format("eot")
}
@font-face{
font-family:"Georgia W01";
src:url("23bc0f18-2c73-491d-a06b-f41a2da880aa.eot?#iefix");
src:url("23bc0f18-2c73-491d-a06b-f41a2da880aa.eot?#iefix") format("eot"),url("1d25176f-8332-42f4-bdab-52218ee930f7.woff2") format("woff2"),url("3ef28fe1-5d06-4661-b98f-e231840184f7.woff") format("woff"),url("5425b8eb-5ac7-44cc-8471-4b04a90a9030.ttf") format("truetype"),url("eef3df57-bffc-49e1-97c7-fdf801de2b52.svg#eef3df57-bffc-49e1-97c7-fdf801de2b52") format("svg");
font-weight: 700;
font-style: italic;
}
