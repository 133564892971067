.scroller {
    white-space:nowrap;
    display: inline-block;
    transition: var(--hf-standard-transition);
}

.scroller.notransition {
    transition: none;
}

.scroller-container {
    overflow: hidden;
    width: 100%;
}

/*
.genre .list {

    position: relative;
}
.slick-arrow.slick-prev {
    position: absolute;
   
}

.slick-arrow.slick-next {
 
    right:0;
}

.slick-arrow.slick-prev {
 
    left:0;
}
*/

.scroller-initiailzed {
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
}
